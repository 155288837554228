import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { FaSearch, FaFileAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Select from "react-select";

import axiosInstance from "../../../../middleware/axiosInstance";

const Fees = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    branchId: "",
    classId: "",
    academicYearId: "",
  });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(75); 
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get("/branches");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId },
      });
      setClasses(response.data);
    } catch (error) {
      setError("Failed to fetch classes.");
    }
  };

  const fetchSections = async () => {
    if (formData.branchId && formData.classId) {
      try {
        const response = await axiosInstance.get("/sections", {
          params: {
            branchId: formData.branchId,
            classId: formData.classId,
          },
        });
        const sortedSections = response.data.sort((a, b) =>
          a.sectionName.localeCompare(b.sectionName)
        );
        setSections(sortedSections);
      } catch (error) {
        setError("Failed to fetch sections.");
      }
    } else {
      setSections([]);
    }
  };

  useEffect(() => {
    if (formData.branchId && formData.classId) {
      fetchSections(formData.branchId, formData.classId);
    } else {
      setSections([]); 
    }
  }, [formData.branchId, formData.classId]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        const sortedAcademicYears = response.data.sort((a, b) => {
           const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);
  
           return startYearA - startYearB;
        });
  
        setAcademicYears(sortedAcademicYears);


        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 3) {
 
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

        const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear
        );

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      } finally {
        setLoading(false);
      }
    };

    getAcademicYears();
  }, []);

  const fetchStudents = async () => {
    try {
      let response;
      if (
        formData.branchId ||
        formData.academicYearId ||
        formData.classId ||
        formData.sectionId
      ) {
        response = await axiosInstance.get("/students/paginated", {
          params: {
            branchId: formData.branchId,
            classId: formData.classId || null,
            sectionId: formData.sectionId || null,
            academicYearId: formData.academicYearId || null,
            searchTerm: searchTerm.trim(),
            page: currentPage,
            limit: studentsPerPage,
          },
        });
      }

      const sortedStudents = response.data.students.sort(
        (a, b) => a.roll - b.roll
      );

      setStudents(sortedStudents);
      setTotalStudents(response.data.totalStudents);
      setNoDataFound(sortedStudents.length === 0);
    } catch (error) {
      setError("No Data Found");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchStudents = debounce(fetchStudents, 500);

  useEffect(() => {
    debouncedFetchStudents();
  }, [
    formData.branchId,
    formData.classId,
    formData.sectionId,
    formData.academicYearId,
    searchTerm,
    currentPage,
  ]);

  useEffect(() => {
    if (formData.academicYearId) {
      setBranches([]);  
      setClasses([]);  
      setSections([]);  
      setFormData((prev) => ({
        ...prev,
        branchId: "", 
        classId: "",  
        sectionId: "",  
      }));
      fetchBranches();  
    }
  }, [formData.academicYearId]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "academicYearId") {
      setFormData({
        academicYearId: value,  
        branchId: "", 
        classId: "", 
        sectionId: "",  
      });
      setBranches([]);  
      setClasses([]);  
      setSections([]);  
      setNoDataFound(false);  
      if (value) {
         fetchBranches(value);
      }
    } else if (name === "branchId") {
      setClasses([]);  
      setSections([]);  
      setSearchTerm("");
      setNoDataFound(false);  
      if (value) {
        fetchClasses(value); 
      }
      setFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
    } else if (name === "classId") {
      setSections([]);  
      setSearchTerm("");
      setNoDataFound(false);
      if (value) {
        fetchSections(value);  
      }
      setFormData((prev) => ({ ...prev, sectionId: "" }));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };


  const handlePayment = async (id) => {
    try {

      navigate(`/receivable/create/${id}`);
    } catch (error) {
      console.error("Error during payment:", error);
    } finally {
      setLoading(false);  
    }
  };
 

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const renderTable = () => (
    <div>
      <div className="px-0 pb-3">
        <CardTitle tag="h4" className="mt-3 mb-1">
          Student List
        </CardTitle>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive className=" mb-0">
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>Roll No.</th>
              <th>Student Name</th>
              <th>Class</th>
              <th>Branch</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.length
              ? students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.roll}</td>
                    <td>
                      {student.firstName} {student.lastName}
                    </td>
                    <td>
                      {student.class_id?.className}{" "}
                      {student.section_id
                        ? ` (${student.section_id.sectionName})`
                        : ""}
                    </td>
                    <td>{student.branch_id?.branchName}</td>
                    <td>{student.academicyear_id?.year}</td>
                    <td>
                      <div className="d-flex">
                        <Button
                          className="btn-no-radius mx-2 responsive-button"
                          onClick={() => handlePayment(student.fromHistory
                            ? student.student_id
                            : student._id)}
                          color="primary"
                        >
                          Fees
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              : noDataFound && (
                  <tr>
                    <td rowSpan="4" colSpan="6" className="text-center">
                      <FaFileAlt className="mt-3" size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">
                        No Students Found
                      </h4>
                    </td>
                  </tr>
                )}
          </tbody>
        </Table>
        <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
      </div>
      {students.length > 0 && (
        <div className="d-flex justify-content-end align-items-center px-3 py-2">
          <Button
            style={{ border: "none" }}
            color="primary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            &lt;
          </Button>
          <span className="mx-2">{currentPage}</span>
          <Button
            style={{ border: "none", borderRadius: "0px" }}
            color="primary"
            disabled={
              currentPage === Math.ceil(totalStudents / studentsPerPage)
            }
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(totalStudents / studentsPerPage))
              )
            }
          >
            &gt;
          </Button>
        </div>
      )}
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center center align-items-center p-4"
          style={{ minHeight: "90vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
            }}
          >
            <div className="d-flex justify-content-between align-items-center px-3 pt-1">
              <CardTitle className="mt-2" tag="h4">
                Receivable
              </CardTitle>
            </div>
            <hr className="mt-1" />
            <div className="px-3 pt-0">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="academicYear">
                        <span style={{ fontWeight: "bold" }}>
                          Academic Year
                        </span>
                      </Label>
                      <Select
                        id="academicYear"
                        options={academicYears.map((year) => ({
                          value: year._id,
                          label: year.year,
                        }))}
                        onChange={(selected) => {
                          handleChange({
                            target: {
                              name: "academicYearId",
                              value: selected ? selected.value : "", 
                            },
                          });
                        }}
                        value={
                          academicYears.find(
                            (year) => year._id === formData.academicYearId
                          )
                            ? {
                                value: formData.academicYearId,
                                label: academicYears.find(
                                  (year) => year._id === formData.academicYearId
                                ).year,
                              }
                            : null
                        }
                        placeholder="Select Academic Year"
                        isSearchable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branchId">
                    <span style={{ fontWeight: "bold" }}>Branch</span>
                  </Label>
                  <Select
                    id="branchId"
                    options={branches.map((branch) => ({
                      value: branch._id,
                      label: branch.branchName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "branchId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      branches.find(
                        (branch) => branch._id === formData.branchId
                      )
                        ? {
                            value: formData.branchId,
                            label: branches.find(
                              (branch) => branch._id === formData.branchId
                            ).branchName,
                          }
                        : null
                    }
                    placeholder="Select a Branch"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="classId">
                    <span style={{ fontWeight: "bold" }}>Class</span>
                  </Label>
                  <Select
                    id="classId"
                    options={classes.map((classItem) => ({
                      value: classItem._id,
                      label: classItem.className,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "classId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      classes.find(
                        (classItem) => classItem._id === formData.classId
                      )
                        ? {
                            value: formData.classId,
                            label: classes.find(
                              (classItem) => classItem._id === formData.classId
                            ).className,
                          }
                        : null
                    }
                    placeholder="Select a Class"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="section">
                    <span style={{ fontWeight: "bold" }}>Section</span>
                  </Label>
                  <Select
                    id="section"
                    options={sections.map((section) => ({
                      value: section._id,
                      label: section.sectionName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "sectionId",
                          value: selected ? selected.value : "", 
                        },
                      });
                    }}
                    value={
                      sections.find(
                        (section) => section._id === formData.sectionId
                      )
                        ? {
                            value: formData.sectionId,
                            label: sections.find(
                              (section) => section._id === formData.sectionId
                            ).sectionName,
                          }
                        : null
                    }
                    placeholder={
                      sections.length === 0 ? "No Section" : "Select a Section"
                    }
                    isDisabled={sections.length === 0}
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup style={{ position: "relative" }}>
                      <Label for="searchInput">
                        <strong>Search By Keyword</strong>
                      </Label>
                      <Input
                        type="text"
                        id="searchInput"
                        placeholder="Search by Student Details"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ paddingLeft: "2.5rem" }}
                      />
                      <FaSearch
                        style={{
                          position: "absolute",
                          top: "75%",
                          left: "10px",
                          transform: "translateY(-50%)",
                          color: "#495057",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>

          <Card
            style={{
              boxShadow: "none",
              marginTop: "0px",
              paddingTop: "0px",
              minHeight: "400px",
            }}
            className="px-3"
          >
            {renderTable()}
          </Card>
        </>
      )}
    </>
  );
};

export default Fees;
