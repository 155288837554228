import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Col,
  Row,
  FormGroup,
  Input,
  Button,
  Form,
  Label
} from "reactstrap";
import Select from "react-select";
import axiosInstance from "../../../middleware/axiosInstance";
import { useNavigate } from "react-router-dom";
import { formatDate } from "date-fns";

export default function CertificateSetting() {
  const [branches, setBranches] = useState([]);
  const navigate = useNavigate();
    const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState({
    branchId: "",
    colorID: "#FB0F01",
    colorbgID: "#ffffff",
    branchColorID: "#FB0F01",
    enableSignID: false,
    IDLayout:'landscape',
    academicYearID: false,
    enableOrgLeavingCertificate: false,
    enableSignLeavingCertificate: false,
    enableDOBBonafide: false,
    enableCasteBonafide: false,
    enableCategoryBonafide: false,
    enableOrgBonafide: false,
    enableSignBonafide: false,
    enableOrgAdmissionConfirmation: false,
    enableSignAdmissionConfirmation: false,
    enableOrgCharacterCertificate: false,
    enableSignCharacterCertificate: false,
    enableOrgAttendanceCertificate: false,
    enableSignAttendanceCertificate: false,
    reasonOfLeavingLC: "",
    overAllBehaviourLC: "",
    remarkLC: "",
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target; 
    setFormData((prevState) => ({
      ...prevState,
      [id]: checked, 
    }));
  };

  const handleInputChange = (field, value) => {
    if (field === 'reasonOfLeavingLC' || field === 'overAllBehaviourLC' || field === 'remarkLC') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
  
      if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'Each word must be less than 50 characters.',
        }));
        return;
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'Must be less than or equal to 40 words.',
        }));
        return; 
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' })); 
      }
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  const fetchCertificateConfig = async (branchId) => {
    try {
      const response = await axiosInstance.get("/getCertificateConfig", {
        params: { branchId }, 
      });
      const data = response.data.data;
      setFormData({
        ...formData,
        branchId: data.branch_id,
        enableSignID: data.config.enableSignID || false,
        academicYearID: data.config.academicYearID || false,
        colorID:data.config.colorID || "#FB0F01",
        colorbgID: data.config.colorbgID || "#ffffff",
        branchColorID: data.config.branchColorID || "#FB0F01",
        IDLayout:data.config.IDLayout || 'landscape',
        enableOrgLeavingCertificate:
          data.config.enableOrgLeavingCertificate || false,
        enableSignLeavingCertificate:
          data.config.enableSignLeavingCertificate || false,
        enableDOBBonafide: data.config.enableDOBBonafide || false,
        enableCasteBonafide: data.config.enableCasteBonafide || false,
        enableCategoryBonafide: data.config.enableCategoryBonafide || false,
        enableOrgBonafide: data.config.enableOrgBonafide || false,
        enableSignBonafide: data.config.enableSignBonafide || false,
        enableOrgAdmissionConfirmation:
          data.config.enableOrgAdmissionConfirmation || false,
        enableSignAdmissionConfirmation:
          data.config.enableSignAdmissionConfirmation || false,
        enableOrgCharacterCertificate:
          data.config.
          enableOrgCharacterCertificate || false,
        enableSignCharacterCertificate:
          data.config.enableSignCharacterCertificate || false,
        enableOrgAttendanceCertificate:
          data.config.
          enableOrgAttendanceCertificate || false,
        enableSignAttendanceCertificate:
          data.config.enableSignAttendanceCertificate || false,
        reasonOfLeavingLC: data.config.reasonOfLeavingLC || "",
        overAllBehaviourLC: data.config.overAllBehaviourLC || "",
        remarkLC:data.config.remarkLC || "" ,
      });
    } catch (error) {
      return;
  }
  };

  useEffect(() => {
    if (formData.branchId) {
      fetchCertificateConfig(formData.branchId);
    }
  }, [formData.branchId]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      await axiosInstance.post(`/certificateConfig`, formData);
      setFormData({
        branchId: formData.branchId, 
        enableSignID: false,
        colorID: "",
        colorbgID: "",
        branchColorID: "",
        IDLayout:'',
        academicYearID: false,
        enableOrgLeavingCertificate: false,
        enableSignLeavingCertificate: false,
        enableDOBBonafide: false,
        enableCasteBonafide: false,
        enableCategoryBonafide: false,
        enableOrgBonafide: false,
        enableSignBonafide: false,
        enableOrgAdmissionConfirmation: false,
        enableSignAdmissionConfirmation: false,
        enableOrgCharacterCertificate: false,
        enableSignCharacterCertificate: false,
        enableOrgAttendanceCertificate: false,
        enableSignAttendanceCertificate: false,
        reasonOfLeavingLC: "",
        overAllBehaviourLC: "",
        remarkLC:"",
      });
  
      if (formData.branchId) {
        fetchCertificateConfig(formData.branchId);
      }
  
    } catch (error) {
      console.error("Error saving configuration:", error);
      alert("Error saving configuration. Please try again.");
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <>
      <div>
        <Form onSubmit={handleSubmit}>
          <Card
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
            }}
          >
            <div
              className="d-sm-flex justify-content-between align-items-center py-sm-0 py-1   position-relative"
              style={{ height: "" }}
            >
              <CardTitle tag="h4" className="pt-2 ps-3">
                Certificate Setting
              </CardTitle>
              <Col
              sm='6' lg='5' xl='4'
                className={`py-0  mt-2   filter-options d-flex justify-content-end  gap-2 `}
                style={{
                  paddingLeft: "15px",
                  marginBottom: "-5px",
                }}
              >
                <FormGroup className=" w-100 ">
                  <Select
                    id="branchId"
                    options={branches.map((branch) => ({
                      value: branch._id,
                      label: branch.branchName,
                    }))}
                    onChange={(selected) => {
                      setFormData({
                        ...formData,
                        branchId: selected ? selected.value : "",
                        enableSignID: false,
                        colorID:'',
                        colorbgID: "",
                        branchColorID: "",
                        academicYearID: false,
                        enableOrgLeavingCertificate: false,
                        enableSignLeavingCertificate: false,
                        enableDOBBonafide: false,
                        enableCasteBonafide: false,
                        enableCategoryBonafide: false,
                        enableOrgBonafide: false,
                        enableSignBonafide: false,
                        enableOrgAdmissionConfirmation: false,
                        enableSignAdmissionConfirmation: false,
                        enableOrgCharacterCertificate: false,
                        enableSignCharacterCertificate: false,
                        enableOrgAttendanceCertificate: false,
                        enableSignAttendanceCertificate: false,
                        reasonOfLeavingLC: "",
                        overAllBehaviourLC: "",
                        remarkLC:"",
                      });
                    }}
                    value={
                      branches.find(
                        (branch) => branch._id === formData.branchId
                      )
                        ? {
                            value: formData.branchId,
                            label: branches.find(
                              (branch) => branch._id === formData.branchId
                            ).branchName,
                          }
                        : null
                    }
                    placeholder="Select Branch"
                    isSearchable={true}
                    styles={customStyles}
                    required
                  />
                </FormGroup>
              </Col>
            </div>
          </Card>
          <Card
            className="p-4 "
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
             
            }}
          >
            <Row className="py-2 ">
              <Col md="12">
                <h5 className="pb-2">ID Card:-</h5>
              </Col>
             
              <Col sm="6" md="6" lg="6" xl="4">
                <FormGroup>
                  <Label htmlFor="colorID">
                    <span style={{ fontWeight: "bold" }}>Choose Border Color</span>
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="color"
                      id="colorID"
                      value={formData.colorID || "#FB0F01"} 
                      onChange={(e) => handleInputChange("colorID", e.target.value)} 
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    <span>{formData.colorID}</span>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <FormGroup>
                  <label htmlFor="colorbgID">
                    <span style={{ fontWeight: "bold" }}>Choose Background Color</span>
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="color"
                      id="colorbgID"
                      value={formData.colorbgID || "#ffffff"}
                      onChange={(e) => handleInputChange("colorbgID", e.target.value)}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    <span>{formData.colorbgID}</span>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <FormGroup>
                  <label htmlFor="branchColorID">
                    <span style={{ fontWeight: "bold" }}>Choose Branch Color</span>
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="color"
                      id="branchColorID"
                      value={formData.branchColorID || "#FB0F01"}
                      onChange={(e) => handleInputChange("branchColorID", e.target.value)}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    <span>{formData.branchColorID}</span>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className=" mt-3 mt-sm-1 mt-lg-3">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableSignID"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignID}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableSignID" className="me-2">
                    Enable Principal Sign
                  </Label>
                </div>
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="academicYearID"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.academicYearID}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableSignID" className="me-2">
                    Enable Academic Year
                  </Label>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className=" mt-3 ">
              <label className="">
                  <strong>Type of Card Layout</strong>
                </label>
               <label  className=" d-flex gap-2">
                                  <Input
                                    type="radio"
                                    name="IDLayout"
                                    value="landscape"
                                    checked={formData.IDLayout === "landscape"} 
                                    onChange={(event) =>
                                      handleInputChange("IDLayout", event.target.value)
                                    } 
                                  />
                                  Landscape 
                                </label>

               <label  className=" d-flex gap-2">
                                  <Input
                                    type="radio"
                                    name="IDLayout"
                                    value="portrate"
                                    checked={formData.IDLayout === "portrate"} 
                                    onChange={(event) =>
                                      handleInputChange("IDLayout", event.target.value)
                                    } 
                                  />
                                  Portrate 
                                </label>
              </Col>

            </Row>
            <hr className="m-0 p-0"></hr>
            <Row className="py-2 pt-3">
              <Col md="12">
                <h5 className="pb-2">Leaving Certificate:-</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className=" ">
                <div className="align-items-start ps-0 pb-2">
                  <label className="me-2 ps-1 pb-1">
                    <strong>Reason Of Leaving</strong>
                  </label>
                  <Input
                    type="text"
                    id="reasonOfLeavingLC"
                    placeholder="Enter Reason Here..."
                    value={formData.reasonOfLeavingLC} 
                    onChange={(event) =>
                      handleInputChange("reasonOfLeavingLC", event.target.value)
                    } 
                  />
                     {errors.reasonOfLeavingLC && (
                      <div
                      style={{ color: "red", fontSize: "0.9rem"}}
                      >
                        {errors.reasonOfLeavingLC}
                      </div>
                    )}
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className="align-items-start ps-0 pb-1">
                  <label className="me-2 ps-1 pb-1">
                    <strong>All Over Behaviour</strong>
                  </label>
                  <Input
                    type="text"
                    id="overAllBehaviourLC"
                    placeholder="Enter Behaviour Here..."
                    value={formData.overAllBehaviourLC}
                    onChange={(event) =>
                      handleInputChange(
                        "overAllBehaviourLC",
                        event.target.value
                      )
                    } 
                  />
                   {errors.overAllBehaviourLC && (
                  <div
                  style={{ color: "red", fontSize: "0.9rem"}}
                  >
                    {errors.overAllBehaviourLC}
                  </div>
                )}
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className="align-items-start ps-0 pb-1">
                  <label className="me-2 ps-1 pb-1">
                    <strong>Remark</strong>
                  </label>
                  <Input
                    type="text"
                    id="remarkLC"
                    placeholder="Enter Remark Here..."
                    value={formData.remarkLC}
                    onChange={(event) =>
                      handleInputChange(
                        "remarkLC",
                        event.target.value
                      )
                    } 
                  />
                   {errors.remarkLC && (
                  <div
                  style={{ color: "red", fontSize: "0.9rem"}}
                  >
                    {errors.remarkLC}
                  </div>
                )}
                </div>
              </Col>

              <Col sm="6" md="6" lg="6" xl="4" className="">
               <div className=" align-items-start ps-0 mt-xl-3 pt-2">
                  <Input
                    type="checkbox"
                    id="enableSignLeavingCertificate"
                    className="me-1  "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableSignLeavingCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <Label  htmlFor="enableSign" className="me-2 ">
                    Enable Principal Sign
                  </Label>
                </div>
                <div className=" d-flex align-items-start ps-0 mt-1 pt-1 ">
                  <Input
                    type="checkbox"
                    id="enableOrgLeavingCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableOrgLeavingCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableOrg" className="me-2">
                   Enable Organization Name
                  </Label>
                </div>

              </Col>
            </Row>
            <hr className=" m-0 p-0"></hr>
            <Row className="py-2 pt-3">
              <Col md="12">
                <h5 className="pb-2">Bonafide Certificate:-</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className="mb-1">
                <div className="d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableDOBBonafide"
                    className="me-1"
                    style={{transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableDOBBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableDOB" className="me-2">
                    Enable Date Of Birth
                  </Label>
                </div>
              </Col>

              <Col sm="6" md="6" lg="6" xl="4" className="mb-1">
                <div className="d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableCasteBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableCasteBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableCaste" className="me-2">
                    Enable Caste
                  </Label>
                </div>
              </Col>

              <Col sm="6" md="6" lg="6" xl="4" className="mb-1">
                <div className="d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableCategoryBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableCategoryBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableCategory" className="me-2">
                    Enable Category
                  </Label>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className="mb-1">
                <div className="d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableSignBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableCategory" className="me-2">
                    Enable Principal Sign
                  </Label>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4" className="mb-1">
                <div className="d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableCaste" className="me-2">
                    Enable Organization Name
                  </Label>
                </div>
              </Col>

              
            </Row>
            <hr className=" m-0 p-0"></hr>

            <Row className="py-2 pt-3">
              <Col md="12">
                <h5 className="pb-2">Admission Confirmation:-</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className=" d-flex align-items-start  ms-1">
                  <Input
                    type="checkbox"
                    id="enableSignAdmissionConfirmation"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignAdmissionConfirmation}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableSignAC" className="me-2">
                  Enable Principal Sign
                  </Label>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgAdmissionConfirmation"
                    className="me-1 "
                    style={{transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableOrgAdmissionConfirmation}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableOrgAC" className="me-2">
                    Enable Organization Name
                  </Label>
                </div>
              </Col>

            </Row>
            <hr className=" m-0 p-0"></hr>

            <Row className="py-2 pt-3">
              <Col md="12">
                <h5 className="pb-2">Character Certificate:-</h5>
              </Col>
             
              <Col sm="6" md="6" lg="6" xl="4">
                <div className=" d-flex align-items-start  ms-1">
                  <Input
                    type="checkbox"
                    id="enableSignCharacterCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignCharacterCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enablePrincipal" className="me-2">
                    Enable Principal Sign
                  </Label>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgCharacterCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgCharacterCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableOrg" className="me-2">
                    Enable Organization Name
                  </Label>
                </div>
              </Col>
            </Row>
            <hr className=" m-0 p-0"></hr>

            <Row className="py-2 pt-3">
              <Col md="12">
                <h5 className="pb-2">Attendance Certificate:-</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgAttendanceCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgAttendanceCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <Label htmlFor="enableOrg" className="me-2">
                    Enable Organization Name
                  </Label>
                </div>
              </Col>
            </Row>
            <div className="text-center d-flex justify-content-center mt-5 pt-3">
              <button className="btn btn-primary" type="submit">
                Save
              </button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    </>
  );
}
