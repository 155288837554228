import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  Table,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { FaRegEdit, FaFileAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Swal from "sweetalert2";
import Select from "react-select";

const ExamTypes = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ branchId: "" });
  const [branches, setBranches] = useState([]);
  const [examTypes, setExamTypes] = useState([]);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch all branches on page load
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
        setError("Failed to fetch branches.");
      }
    };

    fetchBranches();
  }, []);

  useEffect(() => {
    const fetchExamTypes = async () => {
      try {
        let response;

        if (formData.branchId) {
          response = await axiosInstance.get("/exam/examTypes", {
            params: {
              branchId: formData.branchId,
            },
          });
        } else {
          response = await axiosInstance.get("/exam/examTypes");
        }

        setExamTypes(response.data);
        setNoDataFound(response.data.length === 0);
      } catch (error) {
        setError("Failed to fetch exam types.");
      } finally {
        setLoading(false);
      }
    };

    fetchExamTypes();
  }, [formData.branchId]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = (id) => {
    navigate(`/edit/examtype/${id}`);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/exam/delete/examType/${id}`);
        setExamTypes((prevExamTypes) =>
          prevExamTypes.filter((val) => val._id !== id)
        );
      } catch (error) {
        console.error("Error deleting exam types:", error);
      }
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#FDD7C2"
        : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const renderTable = () => (
    <div>
      <div className="px-0 pb-3">
        <CardTitle tag="h4" className="mt-3 mb-1">
          Exam Type List
        </CardTitle>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive className="mb-0">
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>Exam Type</th>
              <th>Branch</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {examTypes.length
              ? examTypes.map((val) => (
                  <tr key={val._id}>
                    <td>{val.examType}</td>
                    <td>{val.branch_id.branchName}</td>
                    <td>
                      <FaRegEdit
                        title="Edit"
                        onClick={() => handleEdit(val._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                      <AiOutlineDelete
                        title="Delete"
                        onClick={() => handleDelete(val._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                    </td>
                  </tr>
                ))
              : noDataFound && (
                  <tr>
                    <td rowSpan="4" colSpan="6" className="text-center">
                      <FaFileAlt className="mt-3" size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">
                        No Exam Types Found
                      </h4>
                    </td>
                  </tr>
                )}
          </tbody>
          <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
        </Table>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center center align-items-center p-4"
          style={{ minHeight: "90vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
            }}
          >
            <div className="d-flex justify-content-between align-items-center px-3 pt-1">
              <CardTitle className="mt-2" tag="h4">
                Exam Types
              </CardTitle>
              <NavLink to="/create/examtype" className="pb-0 mb-0">
                <Button className="btn-no-radius" color="primary">
                  Add Exam Type
                </Button>
              </NavLink>
            </div>
            <hr className="mt-1" />
            <div className="px-3 pt-0">
              <Form>
                <Row>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="branch">
                        <span style={{ fontWeight: "bold" }}>Branch</span>
                      </Label>
                      <Select
                        id="branch"
                        options={branches.map((branch) => ({
                          value: branch._id,
                          label: branch.branchName,
                        }))}
                        onChange={(selected) => {
                          handleChange({
                            target: {
                              name: "branchId",
                              value: selected ? selected.value : "",
                            },
                          });
                        }}
                        value={
                          branches.find(
                            (branch) => branch._id === formData.branchId
                          )
                            ? {
                                value: formData.branchId,
                                label: branches.find(
                                  (branch) => branch._id === formData.branchId
                                ).branchName,
                              }
                            : null
                        }
                        placeholder="Select a Branch"
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>

          <Card
            style={{
              boxShadow: "none",
              marginTop: "0px",
              paddingTop: "0px",
              minHeight: "400px",
            }}
            className="px-3 "
          >
            {renderTable()}
          </Card>
        </>
      )}
    </>
  );
};

export default ExamTypes;
