import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners' 
import { PiEnvelopeThin } from 'react-icons/pi'
import Swal from 'sweetalert2';
import './ForgetPasword.css';
import { NavLink } from 'react-router-dom';
import tulipLogoo from '../../../assets/images/logos/tulipschoolerp-logo.webp'


const ForgotPassword = () => {
  const [user, setUser] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({})
  const [loading , setLoading] = useState(false); 

  const navigate = useNavigate();
  const handleChange = e => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setTermsChecked(checked)
    } else {
      setUser({ ...user, [name]: value })
    }

    if (name === 'password') {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }

      typingTimeoutRef.current = setTimeout(() => {
        if (value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          setPasswordMessageVisible(true)
        } else {
          setPasswordMessageVisible(false)
        }
      }, 1000) 
    }

    if (name === 'repass') {
      setPasswordMismatch(user.password !== value)
    }
  }

  const validateFields = () => {
    let formErrors = {}
    if (!user.email) {
      formErrors.email = 'Email field is required'
    }

    return formErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true);
    const formErrors = validateFields()
    setErrors(formErrors)

    if (Object.keys(formErrors).length > 0) {
      toast.error('Please fill in all required fields', { autoClose: 3000 })
      setLoading(false);
      return
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/forgot-password`,
        user,
        { withCredentials: true }
      )
      setLoading(false);

      await Swal.fire({
        text: response.data.message, 
        icon: 'success', 
        confirmButtonText: 'OK',
      });
      navigate('/login')
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message, { autoClose: 3000 })
      } else {
        toast.error('Network Issue', { autoClose: 3000 })
      }
    }
  }

  return (
   
    <div className="fpmain text-center">
    <div className='w-100 mb-5 pb-4'>
      <img src={tulipLogoo} alt="Tulip Logo" className='tuliplogo'/>
    </div>
      <div className='fpbox bg-white  m-auto text-center py-5 pb-4 mt-5'>
        <h4 className='mb-1'>RESET PASSWORD</h4>
        <form id='register-form'
                onSubmit={handleSubmit}>
          <div>
            <p className='  p-0 m-0 mb-3'>Please enter your email</p>
          </div>
                  <div className="input-group pb-0 mb-0">
                  <PiEnvelopeThin  className="input-icon" />
                      <input type='email'
                      name='email'
                      id='email'
                      placeholder='Work Email'
                      value={user.email}
                      onChange={handleChange}
                     />
                  </div>
                  {errors.email && (
                    <p className='error-text required-message m-0 text-start mt-1 text-danger'>
                      {errors.email}
                    </p>
                  )}
                 
                  <button type="submit"  className="login-btn mt-4"> {loading ? <ClipLoader size={24} color={"#fff"} /> : 'Reset Password'}</button>
              </form>
              <p className="footer-text mt-4 fw-bold">Back To Login? <NavLink  to="/auth/login" className='fw-bold text-black'>Login here</NavLink></p>

        
      </div>
        <div className='py-md-3 pb-5 position-absolute bottom-0 text-center w-100'>
           <p className='text-white  mt-4 pt-5 pt-md-3 fs-5 mb-md-0 mb-4'>Copyright © 2024 - Termscript Data Solutions India Pvt Ltd.</p>
        </div>
        <ToastContainer />
      </div>
  )
}

export default ForgotPassword
