import React, { useEffect, useRef, useState } from 'react'
import {useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  selectIsAuthenticated,
  selectError
} from '../../../Redux/AuthRelated/AuthSlice'
import {   toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'  
import { CiLock } from 'react-icons/ci'
import Swal from 'sweetalert2';
import './ForgetPasword.css';
import tulipLogoo from '../../../assets/images/logos/tulipschoolerp-logo.webp'


const PasswordReset = () => {
  const [user, setUser] = useState({
    repass: '',
    password: ''
  })
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const error = useSelector(selectError)
  const [passwordMessageVisible, setPasswordMessageVisible] = useState(false)
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const typingTimeoutRef = useRef(null)
  const [token, setToken] = useState('')
  const [loading , setLoading] = useState(false); 
  const [canCheckTerms, setCanCheckTerms] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 577);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admindashboard')
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
     const {  password, repass } = user;
    const isFormValid =
      password &&
      repass &&
      !passwordMessageVisible &&
      !passwordMismatch;

    setCanCheckTerms(isFormValid);
  }, [user, passwordMessageVisible, passwordMismatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get('token')
    setToken(urlToken || '')
  }, [])

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setTermsChecked(checked)
    } else {
      setUser({ ...user, [name]: value })
    }

    if (name === 'password') {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }

      typingTimeoutRef.current = setTimeout(() => {
        if (value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          setPasswordMessageVisible(true)
        } else {
          setPasswordMessageVisible(false)
        }
      }, 1000) 
    }

    if (name === 'repass') {
      setPasswordMismatch(user.password !== value)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 577);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true);

    if (user.password !== user.repass) {
      toast.error('Passwords Do Not Match.', { autoClose: 3000 })
      setLoading(false);
      return
    }

    if (user.password.length < 8) {
      toast.error("Password Must Be At Least 8 Characters Long.", { autoClose: 3000 });
      setLoading(false);
      return;
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(user.password)) {
      toast.error("Password Must Contain At Least One Special Character.", { autoClose: 3000 });
      setLoading(false);
      return;
  }
  
     try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/reset-password`,
        { token, ...user },

        { withCredentials: true }
      )
      setLoading(false);
      if(response.data?.message)
      {
        await Swal.fire({
          title: response.data.title,
          text: response.data.message, 
          icon: 'success',
          confirmButtonText: 'OK',
        });
        navigate('/login');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message, { autoClose: 3000 })
      await Swal.fire({
        title: err.response.data.title,
        text: err.response.data.message, 
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      navigate('/login');
    }
  }

  
   useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 577);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <div className="fpmain text-center">
    <div className='w-100'>
      <img src={tulipLogoo} alt="Tulip Logo" className='tuliplogo'/>
    </div>
      <div className='fpbox bg-white  m-auto text-center py-5 mt-5'>
        <h4 className='mb-4'>SET NEW PASSWORD</h4>
        <form id='register-form'
                onSubmit={handleSubmit}>
                  <div className='errorhh'>
                  <div className="input-group m-0">
                  <CiLock className="input-icon" />
                      <input type="password" 
                    name='password'
                      id='password'
                      placeholder='Password'
                      value={user.password}
                      onChange={handleChange}
                      autoComplete="off"
                     />
                  </div>
                  {!isSmallScreen && passwordMessageVisible && (
                    <p className="password-message m-0 text-start text-danger">
                      Password must be 8+ characters with 1 special character.
                    </p>
                  )}
                </div> 
                {isSmallScreen && passwordMessageVisible && (
                  <p className="password-message m-0 p-0 text-start text-danger">
                    Password must be 8+ characters with 1 special character.
                  </p>
                )}
                     <div className='errorhh mb-2'>
                  <div className="input-group">
                  <CiLock className="input-icon" />
                      <input  type='password'
                      name='repass'
                      id='repass'
                      placeholder='Confirm Password'
                      value={user.repass}
                      onChange={handleChange}
                      autoComplete="off"
                     />
                  </div>
                  {passwordMismatch && (
                      <p className="password-message m-0 text-start  text-danger">
                        Passwords do not match.
                      </p>
                    )}
                    </div>
                  
                  <button type="submit" className="login-btn mt-4">{loading ? <ClipLoader size={24} color={"#fff"} /> : 'Set New Password'}</button>
              </form>
        
      </div>
        <div className='py-md-3 pb-5 position-absolute bottom-0 text-center w-100'>
          <p className='text-white  mt-4 pt-5 pt-md-3 fs-5 mb-md-0 mb-4 '>Copyright © 2024 - Termscript Data Solutions India Pvt Ltd.</p>
        </div>
      </div>
  )
}

export default PasswordReset
