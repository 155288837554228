import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, Col, Row, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosInstance from '../../../../middleware/axiosInstance';


const UpdateTransaction = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        studentId: '',
        studentName: '',
        academicYear: '',
        className: '',
        branchName: '',
        sectionName: '',
        finalAmount: '',
        paidAmount: '',
        receivedAmount: '',
        dueDate: '',
        paymentMethod: '',
        fine:'',
        paymentDate:'',
    });
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState([]);

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            // try {
            //     const token = localStorage.getItem('token');
            //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transaction/${id}`, {
            //         headers: { Authorization: `Bearer ${token}` },
            //     });
    
            //     const fetchedFee = response.data.data;
            //     console.log(fetchedFee);

            //     setFormData({
            //         studentId: fetchedFee.student_id._id,
            //         studentName: `${fetchedFee.student_id.firstName} ${fetchedFee.student_id.lastName}`,
            //         finalAmount: fetchedFee.finalAmount || '0',
            //         paidAmount: fetchedFee.paidAmount || '0',
            //         receivedAmount: fetchedFee.receivedAmount || '0',
            //         branchName: fetchedFee.branch_id.branchName,
            //         academicYear: fetchedFee.academicyear_id.year,
            //         className: fetchedFee.class_id.className,
            //         sectionName: fetchedFee.section_id ? fetchedFee.section_id.sectionName : '',
            //         dueDate: fetchedFee.dueDate ? new Date(fetchedFee.dueDate).toISOString().split('T')[0] : '',
            //         classFees: fetchedFee.classFees || '',
            //         paymentMethod: fetchedFee.paymentMethod,
            //         paymentDate: fetchedFee.paymentDate ? new Date(fetchedFee.paymentDate).toISOString().split('T')[0] : '',
            //         fine: fetchedFee.fine
            //     });
    
            // } catch (error) {
            //     console.error('Error fetching fee details:', error);
            //     Swal.fire('Error!', `Failed to fetch fee details: ${error.response?.data.message || error.message}`, 'error');
            // }

            try {
                // Make the API request using axiosInstance
                const response = await axiosInstance.get(`/transaction/${id}`);
        
                // Extract and log the fetched fee data
                const fetchedFee = response.data.data;
                // console.log(fetchedFee);
        
                // Set form data with the fetched details
                setFormData({
                    studentId: fetchedFee.student_id._id,
                    studentName: `${fetchedFee.student_id.firstName} ${fetchedFee.student_id.lastName}`,
                    finalAmount: fetchedFee.finalAmount || '0',
                    paidAmount: fetchedFee.paidAmount || '0',
                    receivedAmount: fetchedFee.receivedAmount || '0',
                    branchName: fetchedFee.branch_id.branchName,
                    academicYear: fetchedFee.academicyear_id.year,
                    className: fetchedFee.class_id.className,
                    sectionName: fetchedFee.section_id ? fetchedFee.section_id.sectionName : '',
                    dueDate: fetchedFee.dueDate ? new Date(fetchedFee.dueDate).toISOString().split('T')[0] : '',
                    classFees: fetchedFee.classFees || '',
                    paymentMethod: fetchedFee.paymentMethod,
                    paymentDate: fetchedFee.paymentDate ? new Date(fetchedFee.paymentDate).toISOString().split('T')[0] : '',
                    fine: fetchedFee.fine
                });
        
            } catch (error) {
                // Use intereter for error handling
                console.error('Error fetching transaction details:', error);
                // intereter.error(`Failed to fetch transaction details: ${error.response?.data.message || error.message}`);
            }
        };

        const fetchPaymentMethod = async () => {
            // try {
            //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/paymentmethod`);
            //     setPaymentMethod(response.data);
            // } catch (error) {
            //     console.error('Error fetching fees type:', error);
            // }
            try {
                const response = await axiosInstance.get('/paymentmethod');
                setPaymentMethod(response.data);
        
            } catch (error) {
                console.error('Error fetching payment method:', error);
                // intereter.error(`Failed to fetch payment method: ${error.response?.data.message || error.message}`);
            }
        };

        fetchTransactionDetails();
        fetchPaymentMethod ();
    }, [id]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleUpdateTransaction = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { paidAmount, receivedAmount, finalAmount } = formData;
        const newPaidAmount = (parseFloat(paidAmount) + parseFloat(receivedAmount || '0')).toFixed(2);
        const balance = (parseFloat(finalAmount) - parseFloat(newPaidAmount)).toFixed(2);

        // try {
        //     const token = localStorage.getItem('token');
        //     await axios.put(`${process.env.REACT_APP_BASE_URL}/update/transaction/${id}`, {
        //         ...formData,
        //         paidAmount: newPaidAmount,
        //         balance,
        //     }, {
        //         headers: { Authorization: `Bearer ${token}` },
        //     });

        //     Swal.fire({
        //         title: 'Success!',
        //         text: 'Transaction updated successfully',
        //         icon: 'success',
        //         confirmButtonText: 'OK'
        //     }).then(() => {
        //         navigate('/fees');
        //     });
        // } catch (err) {
        //     console.error('Error updating transaction:', err.response ? err.response.data : err.message);
        //     Swal.fire({
        //         title: 'Error!',
        //         text: 'There was an error updating the transaction',
        //         icon: 'error',
        //         confirmButtonText: 'OK'
        //     });
        // } finally {
        //     setLoading(false);
        // }
        try {
            // Make the API request using axiosInstance
            await axiosInstance.put(`/update/transaction/${id}`, {
                ...formData,
                paidAmount: newPaidAmount,
                balance,
            });
                navigate('/fees');
    
        } catch (err) {
            // Handle errors using intereter
            console.error('Error updating transaction:', err.response ? err.response.data : err.message);
            // intereter.error(`There was an error updating the transaction: ${err.response?.data.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/fees');
    };

    return (
        <div>
            <Card>
                <CardBody>
                    <CardTitle tag="h3" className="mb-5 text-center">Update Transaction</CardTitle>
                    {loading && <Spinner color="primary" />}
                    <Form onSubmit={handleUpdateTransaction}>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="studentName"><span style={{ fontWeight: "bold" }}>Student Name</span></Label>
                                    <Input
                                        type="text"
                                        name="studentName"
                                        id="studentName"
                                        value={formData.studentName}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="academicYear"><span style={{ fontWeight: "bold" }}>Academic Year</span></Label>
                                    <Input
                                        type="text"
                                        name="academicYear"
                                        id="academicYear"
                                        value={formData.academicYear}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="branchName"><span style={{ fontWeight: "bold" }}>Branch</span></Label>
                                    <Input
                                        type="text"
                                        name="branchName"
                                        id="branchName"
                                        value={formData.branchName}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="className"><span style={{ fontWeight: "bold" }}>Class</span></Label>
                                    <Input
                                        type="text"
                                        name="className"
                                        id="className"
                                        value={formData.className}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="sectionName"><span style={{ fontWeight: "bold" }}>Section</span></Label>
                                    <Input
                                        type="text"
                                        name="sectionName"
                                        id="sectionName"
                                        value={formData.sectionName}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="finalAmount"><span style={{ fontWeight: "bold" }}>Final Amount</span></Label>
                                    <Input
                                        type="number"
                                        name="finalAmount"
                                        id="finalAmount"
                                        value={formData.finalAmount}
                                        onChange={handleChange}
                                        step="0.01"
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="paidAmount"><span style={{ fontWeight: "bold" }}>Paid Amount</span></Label>
                                    <Input
                                        type="number"
                                        name="paidAmount"
                                        id="paidAmount"
                                        value={formData.paidAmount}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="receivedAmount"><span style={{ fontWeight: "bold" }}>Received Amount</span></Label>
                                    <Input
                                        type="number"
                                        name="receivedAmount"
                                        id="receivedAmount"
                                        value={formData.receivedAmount}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="fine"><span style={{ fontWeight: "bold" }}>Fine</span></Label>
                                    <Input
                                        type="number"
                                        name="fine"
                                        id="fine"
                                        value={formData.fine}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="paymentDate"><span style={{ fontWeight: "bold" }}>Payment Date</span></Label>
                                    <Input
                                        type="date"
                                        name="paymentDate"
                                        id="paymentDate"
                                        value={formData.paymentDate}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                           
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="paymentMethod"><span style={{ fontWeight: "bold" }}>Payment Method</span></Label>
                                    <Input
                                        type="select"
                                        name="paymentMethod"
                                        id="paymentMethod"
                                        value={formData.paymentMethod}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>Select</option>
                                        {paymentMethod.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="dueDate"><span style={{ fontWeight: "bold" }}>Next Due Date</span></Label>
                                    <Input
                                        type="date"
                                        name="dueDate"
                                        id="dueDate"
                                        value={formData.dueDate}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="text-center">
                            <Button className='btn-no-radius mx-2' color="primary" type="submit" disabled={loading}>
                                {loading ? <Spinner size="sm" /> : 'Save'}
                            </Button>
                            {' '}
                            <Button className='btn-no-radius' color="secondary" onClick={handleCancel} disabled={loading}>
                                Cancel
                            </Button>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

export default UpdateTransaction;
