import React, { useEffect, useState, useRef } from 'react';
import {  FormGroup, Input,  Label,  } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import { registerUser, selectIsAuthenticated,  selectError } from '../../Redux/AuthRelated/AuthSlice';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { GoOrganization } from "react-icons/go";
import { CiUser, CiLock } from "react-icons/ci";
import { PiEnvelopeThin } from "react-icons/pi";
import Swal from 'sweetalert2';
import tulipLogoo from '../../assets/images/logos/tulipschoolerp-logo.webp'


const Register = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [loading , setLoading] = useState(false); 
  const error = useSelector(selectError);
  const typingTimeoutRef = useRef(null);
  const [user, setUser] = useState({
    organization: "",
    email: "",
    name: "",
    password: "",
    repass: "",
  });

  const [termsChecked, setTermsChecked] = useState(false);
  const [passwordMessageVisible, setPasswordMessageVisible] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [canCheckTerms, setCanCheckTerms] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admindashboard');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const { organization, email, name, password, repass } = user;
    const isFormValid =
      organization &&
      email &&
      name &&
      password &&
      repass &&
      !passwordMessageVisible &&
      !passwordMismatch;

    setCanCheckTerms(isFormValid);
  }, [user, passwordMessageVisible, passwordMismatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setTermsChecked(checked);
    } else {
      setUser({ ...user, [name]: value });
    }

    if (name === 'password') {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      typingTimeoutRef.current = setTimeout(() => {
        if (value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          setPasswordMessageVisible(true);
        } else {
          setPasswordMessageVisible(false);
        }
      }, 1000); 
    }

    if (name === 'repass') {
      setPasswordMismatch(user.password !== value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!termsChecked) {
        toast.error("Please Accept Our Terms & Conditions Before Submitting.", { autoClose: 3000 });
        setLoading(false);
        return;
    }

    if (user.password !== user.repass) {
        toast.error("Passwords Do Not Match.", { autoClose: 3000 });
        setLoading(false);
        return;
    }

    if (user.password.length < 8) {
        toast.error("Password Must Be At Least 8 Characters Long.", { autoClose: 3000 });
        setLoading(false);
        return;
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(user.password)) {
        toast.error("Password Must Contain At Least One Special Character.", { autoClose: 3000 });
        setLoading(false);
        return;
    }

try {
  const resultAction = await dispatch(registerUser(user)); 
  if (registerUser.fulfilled.match(resultAction)) {
    toast.success(resultAction.payload.title, { autoClose: 3000 }); 
    setLoading(false);
    await Swal.fire({
      title: resultAction.payload.title,
      text: resultAction.payload.message, 
      icon: 'success',
      confirmButtonText: 'OK',
    });
    navigate('/login');
  } else {
    toast.error(resultAction.payload, { autoClose: 3000 }); 
    setLoading(false);
  }
} catch (err) {
  toast.error("Registration failed!", { autoClose: 3000 });
  setLoading(false);
}

};


const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 577);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 577);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="regmain text-center">
    <div className='w-100'>
      <img src={tulipLogoo} alt="Tulip Logo" className='tuliplogo'/>
    </div>
      <div className='regbox bg-white   text-center'>
        <h4 className='mb-1'>CREATE AN ACCOUNT</h4>
        <p className='mb-3'>Please enter your details to Create Account</p>
        <form  id="register-form" onSubmit={handleSubmit}>
                <div className="input-group mb-4">
                <GoOrganization className="input-icono "/>
                      <input type="text"  name="organization" id="organization" placeholder="Organization" value={user.organization} onChange={handleChange}
                     />
                  </div>
                  <div className="input-group mb-4">
                  <PiEnvelopeThin className="input-icon" />
                      <input type="email"  name="email" id="email" placeholder="Work Email" value={user.email} onChange={handleChange}
                     />
                  </div>
                  <div className="input-group mb-4">
                  <CiUser className="input-icon" />
                      <input type="text" name="name" id="name" placeholder="Full Name" value={user.name} onChange={handleChange}
                     />
                  </div>
                 
                  <div className='errorh'>
                  <div className="input-group m-0">
                  <CiLock className="input-icon" />
                      <input type="password" 
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={user.password}
                    onChange={handleChange}
                    autoComplete="off"
                     />
                   
                  </div>
                  {!isSmallScreen && passwordMessageVisible && (
                    <p className="password-message m-0 text-start text-danger">
                      Password must be 8+ characters with 1 special character.
                    </p>
                  )}
                </div>

                {isSmallScreen && passwordMessageVisible && (
                  <p className="password-message m-0 p-0 text-start text-danger">
                    Password must be 8+ characters with 1 special character.
                  </p>
                )}
                  <div className='errorh mb-5'>
                  <div className="input-group ">
                  <CiLock className="input-icon" />
                      <input type="password" 
                    name="repass" id="repass" placeholder="Repeat your password" value={user.repass} onChange={handleChange}
                    autoComplete="off"
                     />
                   
                  </div>
                  {passwordMismatch && (
                    <p className="password-message m-0 text-start  text-danger">
                      Passwords do not match.
                    </p>
                  )}
                  </div>
                  <FormGroup check className='mt-3 ps-0 text-start d-flex'>
                  <Input type="checkbox" className='me-2 ms-0 p-0 border border-3' name="terms" id="terms" onChange={handleChange} disabled={!canCheckTerms} checked={termsChecked} />
                  <Label for="terms" >
                    Please Accept Our <a style={{ color: "blue" , textDecoration:'none' }} href="https://tulipschoolerp.com/terms-conditions/" target='_blank'>Terms & Conditions</a>
                  </Label>
                </FormGroup>
                  
                  <button type="submit" name="signup" id="signup"  disabled={loading} className="login-btn mt-4">{loading ? <ClipLoader size={20} color={"#fff"} /> : 'CREATE ACCOUNT'}</button>
              </form>
              <p className="footer-text mt-4 fw-bold">Already have an account? <NavLink  to="/auth/login" className='fw-bold text-black'>Login here</NavLink></p>
        
      </div>
      <div className='py-md-2 py-0 position-absolute bottom-0 text-center w-100'>
          <p className='text-white  mt-4 pt-5 pt-md-3 fs-5  '>Copyright © 2024 - Termscript Data Solutions India Pvt Ltd.</p>
        </div> 
    <ToastContainer />
  </div>
  );
};

export default Register;