import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../../middleware/axiosInstance";
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Button,
  Spinner,
  Input,
} from "reactstrap";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { FiArrowLeft } from "react-icons/fi";

const StudentDocuments = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);

  const [loadingIdCard, setLoadingIdCard] = useState(false);
  const [loadingBonafide, setLoadingBonafide] = useState(false);
  const [loadingLeaving, setLoadingLeaving] = useState(false);
  const [loadingAdmission, setLoadingAdmission] = useState(false);
  const [loadingCharacter, setLoadingCharacter] = useState(false);
  const [loadingAttendance, setLoadingAttendance] = useState(false);

  const [lastdateIdCard, setlastdateIdCard] = useState(false);
  const [lastdateBonafide, setlastdateBonafide] = useState(false);
  const [lastdateLeaving, setlastdateLeaving] = useState(false);
  const [lastdateAdmission, setlastdateAdmission] = useState(false);
  const [lastdateCharacter, setlastdateCharacter] = useState(false);
  const [lastdateAttendance, setlastdateAttendance] = useState(false);
  const [studentBranchID, setstudentBranchID] = useState("");
  const [error, setError] = useState(""); 
  

  const [errors, setErrors] = useState({ StartDate: "", EndDate: "" });

  const handleInputChange2 = (field, value) => {
    const updatedFormData = { ...formData, [field]: value };
    const updatedErrors = { ...errors };
  
    if (field === "StartDate" && updatedFormData.EndDate && value >= updatedFormData.EndDate) {
      updatedErrors.StartDate = "'From Date' must be less than 'To Date'.";
      updatedFormData.StartDate = ""; 
    } else {
      updatedErrors.StartDate = "";
    }
  
    if (field === "EndDate" && updatedFormData.StartDate && value <= updatedFormData.StartDate) {
      updatedErrors.EndDate = "'To Date' must be greater than 'From Date'.";
      updatedFormData.EndDate = ""; 
    } else {
      updatedErrors.EndDate = "";
    }
  
    setErrors(updatedErrors);
    setFormData(updatedFormData);
  };


  const [formData, setFormData] = useState({
    enableSignID: false,
    colorID: "",
    colorbgID: "",
    branchColorID: "",
    IDLayout:" landscape",
    academicYearID: "",
    enableOrgLeavingCertificate: false,
    enableSignLeavingCertificate: false,
    enableDOBBonafide: false,
    enableCasteBonafide: false,
    enableCategoryBonafide: false,
    enableOrgBonafide: false,
    enableSignBonafide: false,
    enableOrgAdmissionConfirmation: false,
    enableSignAdmissionConfirmation: false,
    enableOrgCharacterCertificate: false,
    enableSignCharacterCertificate: false,
    enableOrgAttendanceCertificate: false,
    enableSignAttendanceCertificate: false,
    StartDate:"",
    EndDate:"",

    Attendance:"",
    reasonOfLeavingLC: "Course Completed",
    overAllBehaviourLC: "Excellent",
    remarkLC: "No Dues",
    leavingType: "original",
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleInputChange = (field, value) => {
    if (field === 'reasonOfLeavingLC' || field === 'overAllBehaviourLC' || field === "remarkLC") {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
  
      if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'Each word must be less than 50 characters.',
        }));
        return; 
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'Must be less than or equal to 40 words.',
        }));
        return; 
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' })); 
      }
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  

  const handleAttendanceChange = (event) => {
    const value = event.target.value;
  
    if (/^\d{0,5}(\.\d{0,2})?$/.test(value)) { 
      const numericValue = parseFloat(value);
  
      if (numericValue < 0 || numericValue > 100) {
        setError("Percentage must be between 0 and 100.");
      } else {
        setError(""); 
        handleInputChange("Attendance", value);
      }
    } else {
      setError("Please enter a valid percentage ."); 
    }
  };
  
  const [todayDate, setTodayDate] = useState({ LeavingDate: "" });

  useEffect(() => {
    const today = formatDate(new Date());
    setTodayDate({
      LeavingDate: today,
    });
  }, []); 

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchCertificateConfig = async (studentBranchID) => {
    try {
      const response = await axiosInstance.get("/getCertificateConfig", {
        params: { branchId: studentBranchID }, 
      });
      const data = response.data.data;
      if(data){
        setFormData({
          ...formData,
          branchId: data.branch_id,
          enableSignID: data.config.enableSignID || false,
          colorID:data.config.colorID || '',
          colorbgID:data.config.colorbgID || '',
          branchColorID:data.config.branchColorID || '',
          IDLayout:data.config.IDLayout || '',
          academicYearID:data.config.academicYearID || '',
          enableDOBBonafide: data.config.enableDOBBonafide || false,
          enableCasteBonafide: data.config.enableCasteBonafide || false,
          enableCategoryBonafide: data.config.enableCategoryBonafide || false,
          enableOrgBonafide: data.config.enableOrgBonafide || false,
          enableSignBonafide: data.config.enableSignBonafide || false,
          enableOrgLeavingCertificate:
            data.config.enableOrgLeavingCertificate || false,
          enableSignLeavingCertificate:
            data.config.enableSignLeavingCertificate || false,
          reasonOfLeavingLC: data.config.reasonOfLeavingLC || "",
          overAllBehaviourLC: data.config.overAllBehaviourLC || "",
          remarkLC: data.config.remarkLC || "",
          enableOrgAdmissionConfirmation:
            data.config.enableOrgAdmissionConfirmation || false,
          enableSignAdmissionConfirmation:
            data.config.enableSignAdmissionConfirmation || false,
          enableOrgCharacterCertificate:
            data.config.enableOrgCharacterCertificate || false,
          enableSignCharacterCertificate:
            data.config.enableSignCharacterCertificate || false,
          enableOrgAttendanceCertificate:
            data.config.enableOrgAttendanceCertificate || false,
          enableSignAttendanceCertificate:
            data.config.enableSignAttendanceCertificate || false,
        });
      }
    } catch (error) {
      // console.error("Error fetching certificate config:", error);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        setStudentData(response.data);
        setlastdateAdmission(
          response.data?.documents?.generatedAdmissionConfirmation
            ?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedAdmissionConfirmation?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : " Not generated yet"
        );
        setlastdateLeaving(
          response.data?.documents?.generatedLeavingCertificate
            ?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedLeavingCertificate?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : " Not generated yet"
        );

        setlastdateBonafide(
          response.data?.documents?.generatedBonafideCertificate
            ?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedBonafideCertificate?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : "Not generated yet "
        );
        setlastdateIdCard(
          response.data?.documents?.generatedStudentIDCard?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedStudentIDCard?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : " Not generated yet"
        );
        setlastdateCharacter(
          response.data?.documents?.generatedCharacterCertificate
            ?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedCharacterCertificate?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : " Not generated yet"
        );
        setlastdateAttendance(
          response.data?.documents?.generatedAttendanceCertificate
            ?.lastGeneratedDate
            ? format(
                new Date(
                  response.data?.documents?.generatedAttendanceCertificate?.lastGeneratedDate
                ),
                "MMMM dd, yyyy (hh:mm a)"
              )
            : " Not generated yet"
        );
        const branchId = response.data.branch_id._id;
        setstudentBranchID(branchId);
        fetchCertificateConfig(branchId);
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    };
    fetchStudentData();
  }, [id]);

  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  const handleDownloadIdCard = async () => {
    setLoadingIdCard(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generateidcard/${id}`,
        {
          enableSignID: formData.enableSignID,
          colorID: formData.colorID,
          colorbgID: formData.colorbgID,
          branchColorID: formData.branchColorID,
          IDLayout:formData.IDLayout,
          academicYearID:formData.academicYearID,
        }
      );
      const pdfUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateIdCard(formattedDate || "");
      if (pdfUrl) {
        window.open(pdfUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating ID card:", err);
    } finally {
      setLoadingIdCard(false);
    }
  };

  const handleDownloadBonafide = async () => {
    setLoadingBonafide(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generatebonafide/${id}`,
        {
          enableDOBBonafide: formData.enableDOBBonafide,
          enableCasteBonafide: formData.enableCasteBonafide,
          enableCategoryBonafide: formData.enableCategoryBonafide,
          enableOrgBonafide: formData.enableOrgBonafide,
          enableSignBonafide: formData.enableSignBonafide,
        }
      );
      const bonafideUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateBonafide(formattedDate || "");
      if (bonafideUrl) {
        window.open(bonafideUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating Bonafide Certificate:", err);
    } finally {
      setLoadingBonafide(false);
    }
  };

  const handleDownloadLC = async () => {
    setLoadingLeaving(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generateleaving/${id}`,
        {
          reasonOfLeavingLC: formData.reasonOfLeavingLC,
          overAllBehaviourLC: formData.overAllBehaviourLC,
          remarkLC: formData.remarkLC,
          LeavingDate: todayDate.LeavingDate,
          leavingType: formData.leavingType,
          enableOrgLeavingCertificate: formData.enableOrgLeavingCertificate,
          enableSignLeavingCertificate: formData.enableSignLeavingCertificate,
        }
      );
      const leavingUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateLeaving(formattedDate || "");

      if (leavingUrl) {
        window.open(leavingUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating Leaving Certificate:", err);
    } finally {
      setLoadingLeaving(false);
    }
  };

  const handleDownloadAdmissionForm = async () => {
    setLoadingAdmission(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generateadmissionform/${id}`,
        {
          enableOrgAdmissionConfirmation:
            formData.enableOrgAdmissionConfirmation,
          enableSignAdmissionConfirmation:
            formData.enableSignAdmissionConfirmation,
        }
      );
      const admissionFormUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateAdmission(formattedDate || "");

      if (admissionFormUrl) {
        window.open(admissionFormUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating Admission Confirmation:", err);
    } finally {
      setLoadingAdmission(false);
    }
  };

  const handleDownloadCC = async () => {
    setLoadingCharacter(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generateCharacter/${id}`,
        {
          enableOrgCharacterCertificate: formData.enableOrgCharacterCertificate,
          enableSignCharacterCertificate:
            formData.enableSignCharacterCertificate,
        }
      );
      const CharacterUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateCharacter(formattedDate || "");

      if (CharacterUrl) {
        window.open(CharacterUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating Character Certificate:", err);
    } finally {
      setLoadingCharacter(false);
    }
  };
  const handleDownloadAtC = async () => {
    setLoadingAttendance(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/generateAttendance/${id}`,
        {
          enableOrgAttendanceCertificate: formData.enableOrgAttendanceCertificate,
          enableSignAttendanceCertificate:
            formData.enableSignAttendanceCertificate,
            EndDate: formData.EndDate,
            StartDate: formData.StartDate,
            Attendance: formData.Attendance,
        }
      );
      const AttendanceUrl = response.data.responsedata.url;
      const formattedDate = format(
        new Date(response.data.responsedata.lastGeneratedDate),
        "MMMM dd, yyyy (hh:mm a)"
      );
      setlastdateAttendance(formattedDate || "");

      if (AttendanceUrl) {
        window.open(AttendanceUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating Attendance Certificate:", err);
    } finally {
      setLoadingAttendance(false);
    }
  };

  if (!studentData) {
    return <p>Loading...</p>;
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <style>
        {`
          .responsive-border {
            border-right: 1px dotted gray;
          }
          @media (max-width: 767px) {
            .responsive-border {
              border-right: none;
            }
          }
        `}
      </style>
      <button onClick={handleBackClick} className="floating-back-button">
        <FiArrowLeft size={24} />
      </button>
      <div
        style={{
          backgroundColor: "white",
          padding: "0px",
          borderRadius: "0px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Row className="m-0 p-0">
          <Col md="4" style={{ display: "flex", justifyContent: "center" }}>
            {studentData.image ? (
              <img
                src={studentData.image || "default-image-url"}
                alt="Student"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                  borderRadius: "0px",
                  marginTop: "10px",
                }}
              />
            ) : (
              <div
                className="square mt-2 mt-md-0"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#fb0f01",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  fontSize: "60px",
                  fontWeight: "bold",
                }}
              >
                {getInitial(studentData.firstName)}
              </div>
            )}
          </Col>

          <Col md="8" className="p-3">
            <CardTitle tag="h4" className="mb-3">
              {studentData.firstName} {studentData.lastName}
            </CardTitle>
            <CardText>
              <strong>Register Number:</strong> {studentData.registerNo}
            </CardText>
            <CardText>
              {" "}
              <strong>Branch: </strong>
              {studentData.branch_id
                ? `${studentData.branch_id.branchName}`
                : "N/A"}
            </CardText>
            <CardText>
              <strong>Class:</strong> {studentData.class_id?.className}
              {studentData.section_id
                ? ` (${studentData.section_id.sectionName})`
                : ""}
            </CardText>
            <CardText>
              <strong>Roll Number:</strong> {studentData.roll}
            </CardText>
          </Col>
        </Row>
      </div>

      <div
        style={{
          backgroundColor: "",
          padding: "20px",
          marginTop: "10px",
          marginBottom: "10px",
          textAlign: "center",
        }}
        className="bg-light"
      >
        <h4>Generate Student Documents</h4>

        <hr className=" mt-4 m-0 p-0"></hr>
        <Row className="py-2">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{
                backgroundColor: loadingIdCard ? "black" : "",
                width: "100%",
              }}
              onClick={handleDownloadIdCard}
              disabled={loadingIdCard}
            >
              {loadingIdCard ? (
                <>
                  <Spinner size="sm" /> Generating ID Card...
                </>
              ) : (
                "ID Card"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:{" "}
              <span style={{ color: "#FB0F01" }}> {lastdateIdCard}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
            <Row className=" p-0 m-0 py-2 text-start">
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 ">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableSignID"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignID}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableSignID" className="">
                    <strong>Enable Principal Sign</strong>
                  </label>
                </div>
              </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
              <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="academicYearID"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.academicYearID}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="academicYearID" className="me-2">
                    <strong>Enable Academic year</strong>
                  </label>
                </div>
              </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 mt-xl-0 mt-3">

                <label className="ps-1">
                  <strong>Type of Card Layout</strong>
                </label>
                <div className="pt-1 d-flex flex-column ps-1">
                  <label  className=" d-flex gap-2">
                                                  <Input
                                                    type="radio"
                                                    name="IDLayout"
                                                    value="landscape"
                                                    checked={formData.IDLayout === "landscape"} 
                                                    onChange={(event) =>
                                                      handleInputChange("IDLayout", event.target.value)
                                                    } 
                                                  />
                                                  Landscape 
                                                </label>

                 <label  className=" d-flex gap-2">
                                                   <Input
                                                     type="radio"
                                                     name="IDLayout"
                                                     value="portrate"
                                                     checked={formData.IDLayout === "portrate"}
                                                     onChange={(event) =>
                                                       handleInputChange("IDLayout", event.target.value)
                                                     } 
                                                   />
                                                   Portrate 
                                                 </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
        <Row className="py-2 text-center">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={handleDownloadLC}
              disabled={loadingLeaving}
            >
              {loadingLeaving ? (
                <>
                  <Spinner size="sm" /> Generating Leaving Certificate...
                </>
              ) : (
                "Leaving Certificate"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:{" "}
              <span style={{ color: "#FB0F01" }}> {lastdateLeaving}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
          <Row className=" p-0 m-0 py-2 text-start">
          <Col sm="6" md="12" lg="12" xl="8" className="mx-0 px-0 ">
          <Row className=" p-0 m-0 py-2 text-start">
          <Col sm="12" md="12" lg="12" xl="6" className="mx-0 px-0">
  <div className="ps-1 mt-2">
    <label className="me-2 ps-1">
      <strong>Reason Of Leaving</strong>
    </label>
    <Input
      type="text"
      id="reasonInput"
      placeholder="Enter Reason Here..."
      value={formData.reasonOfLeavingLC}
      onChange={(event) =>
        handleInputChange("reasonOfLeavingLC", event.target.value)
      }
    />
    {errors.reasonOfLeavingLC && (
      <div
      style={{ color: "red", fontSize: "0.9rem"}}
      >
        {errors.reasonOfLeavingLC}
      </div>
    )}
  </div>
</Col>

<Col sm="12" md="12" lg="12" xl="6" className="mx-0 px-0">
  <div className="ps-xl-2 ms-1 mt-2">
    <label className="me-2 ps-1">
      <strong>All Over Behaviour</strong>
    </label>
    <Input
      type="text"
      id="behaviourInput"
      placeholder="Enter Behaviour Here..."
      value={formData.overAllBehaviourLC}
      onChange={(event) =>
        handleInputChange("overAllBehaviourLC", event.target.value)
      }
    />
    {errors.overAllBehaviourLC && (
      <div
      style={{ color: "red", fontSize: "0.9rem"}}
      >
        {errors.overAllBehaviourLC}
      </div>
    )}
  </div>
</Col>
<Col sm="12" md="12" lg="12" xl="6" className="mx-0 px-0">
  <div className="ps-1 mt-2">
    <label className="me-2 ps-1">
      <strong>Remark</strong>
    </label>
    <Input
      type="text"
      id="remarkLC"
      placeholder="Enter Remark Here..."
      value={formData.remarkLC}
      onChange={(event) =>
        handleInputChange("remarkLC", event.target.value)
      }
    />
    {errors.remarkLC && (
      <div
      style={{ color: "red", fontSize: "0.9rem"}}
      >
        {errors.remarkLC}
      </div>
    )}
  </div>
</Col>


<Col sm="12" md="12" lg="12" xl="6" className="mx-0 px-0">
  <div className="ps-xl-2 ms-1 mt-2">
    <label className="me-2 ps-1">
                        <span style={{ fontWeight: "bold" }}>Leaving Date</span>
                      </label>
                      <Input
                        type="date"
                        name="LeavingDate"
                        id="LeavingDate"
                        value={todayDate.LeavingDate} 
                        onChange={(event) => {
                          setTodayDate({ LeavingDate: event.target.value });
                          handleInputChange("LeavingDate", event.target.value); 
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="12" lg="12" xl="6" className="mx-0 px-0 mt-3 pt-1">
                  <div className=" ps-xl-2 ms-1 w-100 mt-2">
                    <div className=" d-flex align-items-start w-100">
                      <Input
                        type="checkbox"
                        id="enableOrgLeavingCertificate"
                        className="me-1 "
                        style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                        checked={formData.enableOrgLeavingCertificate}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="enableOrgLeavingCertificate" className="me-1">
                        <strong>Enable Organization Name</strong>
                      </label>
                    </div>
                    <div className=" d-flex align-items-start w-100">
                      <Input
                        type="checkbox"
                        id="enableSignLeavingCertificate"
                        className="me-1"
                        style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                        checked={formData.enableSignLeavingCertificate}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="enableSignLeavingCertificate" className="me-2">
                        <strong>Enable Principal Sign</strong>
                      </label>
                    </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                sm="6"
                md="12"
                lg="12"
                xl="4"
                className="py-2 text-start mt-2 ps-1 "
              >
                <label className="">
                  <strong>Type of Leaving</strong>
                </label>
                <div className="pt-2 d-flex flex-column ps-1">
                  <label  className=" d-flex gap-2">
                    <Input
                      type="radio"
                      name="leavingType"
                      value="original"
                      checked={formData.leavingType === "original"} 
                      onChange={(event) =>
                        handleInputChange("leavingType", event.target.value)
                      } 
                    />
                    Original
                  </label>

                  <label  className=" d-flex gap-2">
                    <Input
                      type="radio"
                      name="leavingType"
                      value="Duplicate"
                      checked={formData.leavingType === "Duplicate"} 
                      onChange={(event) =>
                        handleInputChange("leavingType", event.target.value)
                      } 
                    />
                    Duplicate
                  </label>
                  <label  className=" d-flex gap-2">
                    <Input
                      type="radio"
                      name="leavingType"
                      value="Triplicate"
                      checked={formData.leavingType === "Triplicate"}
                      onChange={(event) =>
                        handleInputChange("leavingType", event.target.value)
                      } 
                    />
                    Triplicate
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
        <Row className="py-2">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={handleDownloadBonafide}
              disabled={loadingBonafide}
            >
              {loadingBonafide ? (
                <>
                  <Spinner size="sm" /> Generating Bonafide...
                </>
              ) : (
                "Bonafide"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:
              <span style={{ color: "#FB0F01" }}> {lastdateBonafide}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
            <Row className=" p-0 m-0 py-2 text-start">
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 ">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableDOBBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableDOBBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableDOBBonafide" className="me-2">
                    <strong>Enable Date Of Birth</strong>
                  </label>
                </div>
              </Col>

              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
              <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="enableCasteBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableCasteBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableCasteBonafide" className="me-2">
                    <strong>Enable Caste</strong>
                  </label>
                </div>
              </Col>

              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
              <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="enableCategoryBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableCategoryBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableCategoryBonafide" className="me-2">
                    <strong>Enable Category</strong>
                  </label>
                </div>
              </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
              <div className=" d-flex align-items-start ps-1">
              <Input
                    type="checkbox"
                    id="enableOrgBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableOrgBonafide" className="me-2">
                    <strong>Enable Organization Name</strong>
                  </label>
                </div>
              </Col>

              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
              <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="enableSignBonafide"
                    className="me-1"
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableSignBonafide}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableSignBonafide" className="">
                    <strong>Enable Principal Sign</strong>
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
        <Row className="py-2">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={handleDownloadAdmissionForm}
              disabled={loadingAdmission}
            >
              {loadingAdmission ? (
                <>
                  <Spinner size="sm" /> Generating Admission Confirmation...
                </>
              ) : (
                "Admission Confirmation"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:
              <span style={{ color: "#FB0F01" }}> {lastdateAdmission}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
            <Row className=" p-0 m-0 py-2 text-start">
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 ">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgAdmissionConfirmation"
                    className="me-1 "
                    style={{transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgAdmissionConfirmation}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableOrgAdmissionConfirmation" className="me-2">
                    <strong>Enable Organization Name</strong>
                  </label>
                </div>
              </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
                <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="enableSignAdmissionConfirmation"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableSignAdmissionConfirmation}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableSignAdmissionConfirmation" className="">
                    <strong>Enable Principal Sign</strong>
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
        <Row className="py-2">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={handleDownloadCC}
              disabled={loadingCharacter}
            >
              {loadingCharacter ? (
                <>
                  <Spinner size="sm" /> Generating Character Certificate...
                </>
              ) : (
                "Character Certificate"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:{" "}
              <span style={{ color: "#FB0F01" }}> {lastdateCharacter}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
            <Row className=" p-0 m-0 py-2 text-start">
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 ">
                <div className=" d-flex align-items-start ps-1">
                  <Input
                    type="checkbox"
                    id="enableOrgCharacterCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgCharacterCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableOrgCharacterCertificate" className="me-2">
                    <strong>Enable Organization Name</strong>
                  </label>
                </div>
              </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0">
                <div className=" d-flex align-items-start ps-xl-2 ms-1">
                  <Input
                    type="checkbox"
                    id="enableSignCharacterCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px"}}
                    checked={formData.enableSignCharacterCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableSignCharacterCertificate" className="">
                    <strong>Enable Principal Sign</strong>
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
        <Row className="py-2">
          <Col
            md={6}
            lg={5}
            xl={4}
            className="text-center  py-3 responsive-border"
          >
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={handleDownloadAtC}
              disabled={loadingAttendance}
            >
              {loadingAttendance ? (
                <>
                  <Spinner size="sm" /> Generating Attendance Certificate...
                </>
              ) : (
                "Attendance Certificate"
              )}
            </Button>
            <span
              style={{
                fontSize: "14px",
                color: "black",
                display: "block",
                marginTop: "5px",
              }}
            >
              Last Generated Date:{" "}
              <span style={{ color: "#FB0F01" }}> {lastdateAttendance}</span>
            </span>
          </Col>
          <Col md={6} lg={7} xl={8} className="py-2">
            <Row className=" p-0 m-0 py-2 text-start">
            <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 mb-2">
  <div className="ps-1">
    <label htmlFor="StartDate" className="me-2 ps-1">
      <span style={{ fontWeight: "bold" }}>From Date</span>
    </label>
    <Input
      type="date"
      name="StartDate"
      id="StartDate"
      value={formData.StartDate}
      onChange={(event) => handleInputChange2("StartDate", event.target.value)}
    />
    {errors.StartDate && <span style={{ color: "red", fontSize: "0.9rem"}}>{errors.StartDate}</span>}
  </div>
</Col>
<Col sm="6" md="12" lg="12" xl="4" className="mx-0 ps-sm-2 ps-md-0 ps-xl-2 px-0 mb-2">
  <div className="ps-1">
    <label className="me-2 ps-1">
      <span style={{ fontWeight: "bold" }}>To Date</span>
    </label>
    <Input
      type="date"
      name="EndDate"
      id="EndDate"
      value={formData.EndDate}
      onChange={(event) => handleInputChange2("EndDate", event.target.value)}
    />
    {errors.EndDate && <span style={{ color: "red", fontSize: "0.9rem"}}>{errors.EndDate}</span>}
  </div>
</Col>

                        <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 mb-2">
                        <div className=" align-items-center ps-xl-2 ms-1">
                        <label className="me-2 ps-1">
                          <strong>Attendance</strong>
                        </label>
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <Input
                              type="text"
                              id="Attendance"
                              placeholder="Enter Percentage Here..."
                              value={formData.Attendance}
                              onChange={handleAttendanceChange}
                            />
                            <span className="input-group-text">%</span>
                          </div>
                          {error && (
                            <span style={{ color: "red", fontSize: "0.9rem"}}>
                              {error}
                            </span>
                          )}
                        </div>
                      </div>
                  </Col>
              <Col sm="6" md="12" lg="12" xl="4" className="mx-0 px-0 ps-sm-2 ps-md-0 mt-2  mt-sm-4 mt-md-2">
                <div className=" d-flex align-items-start ps-1  ">
                  <Input
                    type="checkbox"
                    id="enableOrgAttendanceCertificate"
                    className="me-1 "
                    style={{ transform: "scale(0.9)", marginTop: "3.3px" }}
                    checked={formData.enableOrgAttendanceCertificate}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="enableOrgAttendanceCertificate" className="me-2">
                    <strong>Enable Organization Name</strong>
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className=" m-0 p-0"></hr>
      </div>
    </div>
  );
};

export default StudentDocuments;
