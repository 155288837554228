import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BsFillCheckCircleFill,
  BsArrowRepeat,
 } from 'react-icons/bs'
import { ClipLoader } from 'react-spinners'
import Swal from 'sweetalert2'
import tulipLogoo from '../../assets/images/logos/tulip_logo.png'
import { Container, Row, Col, Button} from 'reactstrap'
import login_bg_image from '../../assets/login/login_bg_image.webp'
import mail from '../../assets/mail.png'
export default function VerifyEmailPage () {
  const [token, setToken] = useState('')
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()  

  const handleActionButton = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/verifyemail`,
        {
          token
        }
      )
      setLoading(false)
      setStatus(response.data.status)
      setMessage(response.data.message)

       if (response.data.status === 'Verified') {
        await Swal.fire({
          title: response.data.title,
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK'
        })
        navigate('/login')
      }
    } catch (error) {
      setLoading(false)
      setError(true)
      setMessage(
        error.response?.data.message || 'An error occurred. Please try again.'
      )
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get('token')
    setToken(urlToken || '')
  }, [])

  const renderIcon = () => {
    if (status === 'Verified') {
      return (
        <BsFillCheckCircleFill
          style={{ fontSize: '4.5rem', color: '#28a745' }}  
        />
      )
    }
    if (status === 'AlreadyVerified') {
      return (
        <BsFillCheckCircleFill
          style={{ fontSize: '4.5rem', color: '#28a745' }}  
        />
      )
    }
    if (status === 'Resend') {
      return (
        <BsArrowRepeat
          style={{ fontSize: '4.5rem', color: '#fb0f01' }}  
        />
      )
    }
     return (
      
      <img src={mail} style={{ width: '100px' }} />
    )
  }

  return (
    
    <div
      className='text-center vh-100'
      style={{
        background: `url(${login_bg_image}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%'
      }}
    >
       <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1
        }}
      ></div>

       <Container
        fluid
        className='d-flex  flex-column align-items-center justify-content-center'
        style={{
          position: 'relative',
          zIndex: 2,
          height: '100%'
        }}
      >
        <div className=''>
          <img
            src={tulipLogoo}
            alt='Tulip Logo'
            style={{
              width: '200px',
              marginTop: '12px',
              position: 'relative',
              bottom: '22px',
              filter: 'brightness(0) invert(1)'  

             }}
          />
        </div>

        <Row className='justify-content-center'>
          <Col md={8} lg={6}>
            <div
              className='bg-white shadow-lg p-4 p-md-5 text-center'
              style={{ borderRadius: '0px' }}
            >
              <div className='mb-4'>{renderIcon()}</div>
              <h3 className='h4 mb-4'>
                {error ? (
                  <p>
                    {message || 'An error occurred. Please check your email.'}
                  </p>
                ) : (
                  <p>{message || 'Please Verify Your Account.'}</p>
                )}
              </h3>
              {status === 'AlreadyVerified' ? (
                <p>Congratulations! Your account is already verified.</p>
              ) : (
                <p>We're happy you're here. Let's get your account verified!</p>
              )}

              <div className='mt-4'>
                {!status && (
                  <Button
                    color='primary'
                    onClick={handleActionButton}
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader size={24} color={'#fff'} />
                    ) : (
                      'Click To Verify Your Account'
                    )}
                  </Button>
                )}

                {status === 'Verified' && (
                  <Button
                    color='primary'
                    onClick={() => navigate('/dashboard')}
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader size={24} color={'#fff'} />
                    ) : (
                      'Go to dashboard'
                    )}
                  </Button>
                )}

                {status === 'AlreadyVerified' && (
                  <Button
                    color='primary'
                    onClick={() => navigate('/login')}
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader size={24} color={'#fff'} />
                    ) : (
                      'Go to login'
                    )}
                  </Button>
                )}
              </div>

              <p className='mt-4 small '>
                If you face any issues during the account verification process,
                please email us at{' '}
                <a
                  href='mailto:contact@tulipschoolerp.com'
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  contact@tulipschoolerp.com
                </a>{' '}
                or call us at{' '}
                <a
                  href='tel:+919307646349'
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  +91-9307646349
                </a>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
