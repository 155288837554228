import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col,
  Spinner
} from "reactstrap";

import { FaSearch, FaLock, FaFileAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Select from 'react-select';

import axiosInstance from "../../../../middleware/axiosInstance";

const Fees = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    academicYearId: '',
    branchId: "",
    classId: "",
    sectionId: "",
    examNameId: "",
    subjectId: ""
  });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(75); 
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [examNames, setExamNames] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [examTypes, setExamTypes] = useState([]);
  const [marksData, setMarksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGradeSubject, setIsGradeSubject] = useState(false);
  const [totalsubMarks, setTotalSubMarks] = useState(false);
  const [tableloading, setTableloading] = useState()

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        const sortedAcademicYears = response.data.sort((a, b) => {
          // Extract starting years from "year" (e.g., "2024-25" => 2024)
          const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);
  
          // Sort based on the starting year
          return startYearA - startYearB;
        });
  
        setAcademicYears(sortedAcademicYears);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();  
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 4 || (currentMonth === 4 && currentDay >= 1)) {
          // June 1 to December 31, or May 1 to May 31
          
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

         const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear  
        );

        

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,  
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }finally {
        setLoading(false);  
      }
    };

    getAcademicYears();
  }, []);


  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
      finally {
        setLoading(false);
      }
    };
    fetchBranches();
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/classes/branch", {
            params: { branchId: formData.branchId },
          });
          setClasses(response.data);
          setFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
        } catch (error) {
          setError("Failed to fetch classes.");
        }
        finally {
          setLoading(false);
        }
      } else {
        setClasses([]);
        setSections([]);
        setFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
      }
    };

    fetchClasses();
  }, [formData.branchId]);

  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
            },
          });
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setSections(sortedSections);
        } catch (error) {
          setError("Failed to fetch sections.");
        }
        finally {
          setLoading(false);
        }
      } else {
        setSections([]);
      }
    };
    fetchSections();
  }, [formData.branchId, formData.classId]);

  const fetchStudents = async () => {
    try {
      setTableloading(true)

      const payload = {
        branchId: formData.branchId,
        classId: formData.classId,
        sectionId: formData.sectionId,
        academicyearId: formData.academicYearId,
        examNameId: formData.examNameId,
        subjectId: formData.subjectId,
      };

      const response = await axiosInstance.get("/exam/studentsBySubject", {
        params: {
          ...payload,
        },
      })
      setTotalStudents(response.data.totalStudents);

      if (response.status === 200 && response.data?.data) {
        const initializeLockState = (data) => {
          return data.map((student) => {
            const hasAbsentMarks = student.marks?.some((mark) => mark.isAbsent);
            return {
              ...student,
              isLocked:
                !!student.obtainedmark ||
                (student.marks && student.marks.length > 0) ||
                !!student.assignedGrade ||
                student.isAbsent ||
                hasAbsentMarks ||
                !!student.remark
            };
          });
        };

        const initializedData = initializeLockState(response.data.data);
        setMarksData(initializedData); 
      } else {
        console.error("Failed to fetch students:", response.data?.message);
      }

    } catch (error) {
      setError("No Data Found");
      setNoDataFound(true);
    }
    finally {
      setTableloading(false)
      setLoading(false);
    }

  };

  useEffect(() => {
    const fetchExamNames = async () => {
      setExamNames([]);
      if (
        formData.branchId &&
        formData.classId &&
        formData.academicYearId 
      ) {
        try {
          const params = {
            branchId: formData.branchId,
            classId: formData.classId,
            academicYearId: formData.academicYearId,
          };

          if (formData.sectionId) {
            params.sectionId = formData.sectionId;
          }

          if (formData.examTypeId) {
            params.examTypeId = formData.examTypeId;
          }

          const response = await axiosInstance.get("/exam/exams", {
            params: params,
          });

          const exams = response.data;
          if (exams.length > 0) {
            setExamNames(
              exams.map((exam) => ({
                value: exam._id,
                label: exam.examName
              }))
            );
          } else {
            setExamNames([]);
          }
        } catch (error) {
          console.error("Error fetching exam names:", error);
          setExamNames([]); 
        }
      } else {
        setExamNames([]);
      }
    };

    fetchExamNames();
  }, [
    formData.branchId,
    formData.classId,
    formData.sectionId,
    formData.academicYearId,
    formData.examTypeId,
  ]);

  useEffect(() => {
    const fetchSubjects = async () => {


      if (formData.examNameId) {
        try {
          const response = await axiosInstance.get('exam/getall/examSubjects', {
            params: {
              branchId: formData.branchId,
              classId: formData.classId || null,
              sectionId: formData.sectionId || null,
              academicYearId: formData.academicYearId || null,
              examNameId: formData.examNameId
            },
          });

          setSubjects(
            response.data.map(subject =>
              ({
              totalmark: subject.subDetails && subject.subDetails.totalMarks ? subject.subDetails.totalMarks : '',
              isGrade: subject.isGrade,
              value: subject._id,
              label: subject.subject_id.subject,
              ExamType: subject.ExamType,
            }))
          );

          setFormData((prev) => ({ ...prev, subjectId: "" }));

        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      } else {
      }
    };
    fetchSubjects();
  }, [formData.examNameId]);


  useEffect(() => {
    if (formData.subjectId) {
      setMarksData([])
      fetchStudents()
    }

  }, [formData.subjectId]);



  const handleIsAbsentChange = (studentId) => {
    setMarksData((prevData) =>
      prevData.map((student) => {
        if (student.student_id._id === studentId) {
          const updatedStudent = { ...student, isAbsent: !student.isAbsent };

          if (updatedStudent.isAbsent) {
            updatedStudent.marks = updatedStudent.marks.map((mark) => ({
              ...mark,
              mark: 0,
            }));
            updatedStudent.obtainedmark = 0;
            updatedStudent.assignedGrade = "";
          }

          return updatedStudent;
        }
        return student;
      })
    );
  };

  const handleExamTypeAbsentChange = (studentId, examTypeId) => {
    setMarksData((prevData) =>
      prevData.map((student) => {
        if (student.student_id._id === studentId) {
          const updatedMarks = student.marks.some((mark) => mark.examtype_id === examTypeId)
            ? student.marks.map((mark) => {
              if (mark.examtype_id === examTypeId) {
                return {
                  ...mark,
                  isAbsent: !mark.isAbsent, 
                  mark: !mark.isAbsent ? 0 : mark.mark, 
                };
              }
              return mark;
            })
            : [
              ...student.marks,
              {
                examtype_id: examTypeId,
                isAbsent: true, 
                mark: 0, 
              },
            ];

          return { ...student, marks: updatedMarks };
        }
        return student;
      })
    );
  };


  const handleMarksChange = (studentId, exam, value) => {


    setMarksData((prevData) =>
      prevData.map((student) => {
        if (student.student_id._id === studentId) {
          const updatedMarks = student.marks.some((mark) => mark.examtype_id === exam.examType_id)
            ? student.marks.map((mark) =>
              mark.examtype_id === exam.examType_id ? { ...mark, mark: value } : mark 
            )
            : [...student.marks, { examtype_id: exam.examType_id, mark: value }];

          return { ...student, marks: updatedMarks };
        }
        return student;
      })
    );
  };


  const handleObtainedMarkChange = (studentId, value) => {
    setMarksData((prevData) =>
      prevData.map((student) =>
        student.student_id._id === studentId ? { ...student, obtainedmark: value } : student
      )
    );
  };

  const handleGradeChange = (studentId, grade) => {
    setMarksData((prevMarksData) =>
      prevMarksData.map((student) =>
        student.student_id._id === studentId
          ? { ...student, assignedGrade: grade }
          : student
      )
    );
  };

  const handleNotesChange = (studentId, value) => {

    setMarksData((prevData) =>
      prevData.map((student) =>
        student.student_id._id === studentId ? { ...student, remark: value } : student
      )
    );
  };

  const handleLockToggle = (studentId) => {
    setMarksData((prevData) =>
      prevData.map((student) =>
        student.student_id._id === studentId
          ? { ...student, isLocked: !student.isLocked }
          : student
      )
    );
  };


  const handleCreateMark = async () => {
    setLoading(true);
    try {
      const payload = {
        branchId: formData.branchId,
        classId: formData.classId,
        sectionId: formData.sectionId,
        academicyearId: formData.academicYearId,
        examId: formData.examNameId,
        subjectId: formData.subjectId,
        students: marksData,
      };



      const response = await axiosInstance.post("exam/create/mark", payload);


    } catch (error) {
      console.error("Error creating marks:", error);
      setError("Failed to create marks. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2'
        : '#FFFFFF',
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };



  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* {loading ? (
        <div className="d-flex justify-content-center center align-items-center p-4" style={{ minHeight: '90vh' }}>
          <Spinner color="primary" />
        </div>
      ) : ( */}
        <>
          <Card style={{ boxShadow: "none", marginBottom: "10px", borderRadius: '0' }}>
            <div

              className="d-flex justify-content-between align-items-center px-3 pt-1"
            >
              <CardTitle className='mt-2' tag="h4">Assign Marks</CardTitle>

            </div>
            <hr className='mt-1' />
            <div className="px-3 pt-0">
              <Form onSubmit={handleSubmit}>
                <Row >
                  <Col xs={12} sm={6} md={4} xl={3} className="mb-3">
                    <FormGroup>
                      <Label for="academicYear">
                        <strong>Academic Year</strong>
                      </Label>
                      <Select
                        id="academicYear"
                        options={academicYears.map(year => ({ value: year._id, label: year.year }))}
                        onChange={(selectedOption) => {
                          setMarksData([]);
                          setFormData(prev => ({ ...prev, examNameId: "", subjectId: "", academicYearId: selectedOption?.value || '' }));
                        }}
                        value={academicYears.find(year => year._id === formData.academicYearId) ? { value: formData.academicYearId, label: academicYears.find(year => year._id === formData.academicYearId).year } : null}
                        placeholder="Academic Year"
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="branchId">
                        <strong>Branch</strong>
                      </Label>
                      <Select
                        options={branches.map(branch => ({ value: branch._id, label: branch.branchName }))}
                        value={formData.branchId ? { value: formData.branchId, label: branches.find(b => b._id === formData.branchId)?.branchName } : null}
                        onChange={(selectedOption) => {
                          setFormData(prev => ({ ...prev, examNameId: "", subjectId: "", branchId: selectedOption?.value || '' }));
                          setMarksData([]);
                          setClasses([]);
                          setSections([]);
                          setSubjects([])
                        }}
                        placeholder="Select a Branch"
                        isClearable
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="classId">
                        <strong>Class</strong>
                      </Label>
                      <Select
                        options={classes.map(classItem => ({ value: classItem._id, label: classItem.className }))}
                        value={formData.classId ? { value: formData.classId, label: classes.find(c => c._id === formData.classId)?.className } : null}
                        onChange={(selectedOption) => {
                          setMarksData([]);
                          setFormData(prev => ({ ...prev, examNameId: "", subjectId: "", classId: selectedOption?.value || '', sectionId: '' }));
                        }}
                        placeholder="Select a Class"
                        isClearable
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="sectionId">
                        <strong>Section</strong>
                      </Label>
                      <Select
                        options={sections.map(section => ({ value: section._id, label: section.sectionName }))}
                        value={formData.sectionId ? { value: formData.sectionId, label: sections.find(s => s._id === formData.sectionId)?.sectionName } : null}
                        onChange={(selectedOption) => {
                          setMarksData([]);
                          setFormData(prev => ({ ...prev, sectionId: selectedOption?.value || '', examNameId: '', subjectId: '' }));
                        }}
                        placeholder={sections.length === 0 ? 'No Section' : 'Section'}
                        isDisabled={sections.length === 0}
                        isClearable
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label for="examNameId">
                        <strong>Exam Name</strong>
                      </Label>
                      <Select
                        className="custom-select-input"
                        name="examNameId"
                        options={examNames}
                        value={
                          examNames.find(
                            (exam) => exam.value === formData.examNameId
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          setMarksData([]);
                          setFormData(prev => ({ ...prev, examNameId: selectedOption?.value || '' }));
                          setSubjects([])
                        }}
                        isClearable
                        placeholder="Select an Exam Name"
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className="mb-3">
                    <FormGroup>
                      <Label for="subjectId">
                        <strong>Subject</strong>
                      </Label>
                      <Select
                        id="subjectId"
                        options={subjects}
                        onChange={(selectedOption) => {

                          setFormData(prev => ({ ...prev, subjectId: selectedOption?.value || '' }));

                          setIsGradeSubject(selectedOption?.isGrade || false);
                          setTotalSubMarks(selectedOption?.totalmark || '');
                          setExamTypes(
                            selectedOption?.ExamType.map((exam) => ({
                              examType_id: exam.examtype_id._id,
                              type: exam.examtype_id.examType,
                              maxMarks: exam.totalMarks,
                            }))
                          )
                          setMarksData([]);
                        }}
                        value={
                          subjects.find(subject => subject.value === formData.subjectId) ||
                          null
                        }
                        placeholder="Select Subject"
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>

          {!tableloading ? (
          <div>
          {Array.isArray(marksData) && marksData.length > 0 ? <>
            <Card
              style={{
                boxShadow: "none",
                marginTop: "0px",
                paddingTop: "0px",
                minHeight: "400px",
              }}
              className="px-3 pb-3"
            >
              <div className="px-0 pb-3">
                <CardTitle tag="h4" className="mt-3 mb-1">
                  Student List
                </CardTitle>
              </div>
              <div style={{ overflowX: "auto" }}>
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table bordered striped responsive>
                  <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
                    <tr>
                      <th>Roll No.</th>
                      <th>Student Name</th>
                      <th>Academic Year</th>
                      {isGradeSubject ? (<>
                        <th>Is Absent</th>
                        {totalsubMarks > 0 && (
                          <th>
                            {totalsubMarks
                              ? `Obtained Mark (${totalsubMarks})`
                              : "Obtained Mark"
                            }
                          </th>
                        )}
                        <th>Assign Grade</th>
                      </>
                      ) : (
                        examTypes && examTypes.length > 0 ? (
                          examTypes.map((exam, index) => (<>
                            <th>Is Absent</th>
                            <th key={`${exam.type}-${exam.id || index}`}>
                              {`${exam.type.charAt(0).toUpperCase() + exam.type.slice(1)} (${exam.maxMarks})`}
                            </th>
                          </>
                          ))
                        ) : (
                          <>
                            <th>Is Absent</th>
                            <th>
                              {totalsubMarks
                                ? `Obtained Mark (${totalsubMarks})`
                                : "Obtained Mark"
                              }
                            </th>
                          </>
                        )
                      )}
                      <th>Remarks</th>
                      <th>Lock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marksData.length > 0 ? (
                      marksData.map((student) => (
                        <tr key={student.student_id._id}>
                          <td>{student.student_id.roll}</td>
                          <td>
                            {student.student_id.firstName} {student.student_id.lastName}
                          </td>
                          <td>{student.academicyear_id?.year}</td>
                          {isGradeSubject ? (
                            <>
                              <td>
                                <input
                                  type="checkbox"
                                  className="ms-3 mt-2"
                                  checked={student.isAbsent || false}
                                  disabled={student.isLocked}
                                  onChange={() => handleIsAbsentChange(student.student_id._id)}
                                />
                              </td>
                              {totalsubMarks > 0 && (
                                <td>
                                  <input
                                    type="text"
                                    className="w-100 form-control py-1"
                                    placeholder="Obtained Mark"
                                    value={student.isAbsent ? 0 : student.obtainedmark || ""}
                                    disabled={student.isAbsent || student.isLocked} 
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (inputValue === "" || (totalsubMarks && /^\d+$/.test(inputValue) && inputValue <= totalsubMarks)) {
                                        if (!student.isAbsent) {
                                          handleObtainedMarkChange(student.student_id._id, inputValue);
                                        }
                                      }
                                    }}
                                  />
                                </td>
                              )}
                              <td>
                                <input
                                  type="text"
                                  className="w-100 form-control py-1"
                                  placeholder="Assign Grade"
                                  disabled={student.isAbsent || student.isLocked} 
                                  value={student.assignedGrade || ""}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 10) {
                                      !student.isAbsent && handleGradeChange(student.student_id._id, inputValue);
                                    }
                                  }}
                                />
                              </td>
                            </>
                          ) : examTypes && examTypes.length > 0 ? (
                            examTypes.map((exam, index) => {
                              const studentMark = student.marks.find(
                                (mark) => mark.examtype_id === exam.examType_id
                              );
                              return (<>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="ms-3 mt-2"
                                    checked={studentMark?.isAbsent || false}
                                    disabled={student.isLocked}
                                    onChange={() =>
                                      handleExamTypeAbsentChange(
                                        student.student_id._id,
                                        exam.examType_id
                                      )
                                    }
                                  />
                                </td>
                                <td key={`${exam.type}-${exam.id || index}`}>
                                  <input
                                    type="text"
                                    className="w-100 form-control py-1"
                                    placeholder={`${exam.type} Marks`}
                                    disabled={studentMark?.isAbsent || false || student.isLocked}
                                    value={studentMark ? studentMark.mark : ""}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (inputValue === "" || (/^\d+$/.test(inputValue) && inputValue <= exam.maxMarks)) {
                                        handleMarksChange(student.student_id._id, exam, inputValue);
                                      }
                                    }}
                                  />
                                </td>
                              </>
                              );
                            })
                          ) : (<>
                            <td>
                              <input
                                type="checkbox"
                                className="ms-3 mt-2"
                                checked={student.isAbsent || false}
                                disabled={student.isLocked}
                                onChange={() => handleIsAbsentChange(student.student_id._id)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="w-100 form-control py-1"
                                placeholder="Obtained Mark"
                                value={student.isAbsent ? 0 : student.obtainedmark || ""}
                                disabled={student.isAbsent || student.isLocked} 
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue === "" || (totalsubMarks && /^\d+$/.test(inputValue) && inputValue <= totalsubMarks)) {
                                    if (!student.isAbsent) {
                                      handleObtainedMarkChange(student.student_id._id, inputValue);
                                    }
                                  }
                                }}
                              />
                            </td>
                          </>
                          )}
                          <td>
                            <input
                              type="text"
                              className="w-100 form-control py-1"
                              placeholder="Remarks"
                              value={student.remark || ""}
                              disabled={student.isLocked}
                              onChange={(e) => handleNotesChange(student.student_id._id, e.target.value)}
                            />
                          </td>
                          <td>
                            <FaLock
                              className="ms-2 mt-2"
                              onClick={() => handleLockToggle(student.student_id._id)}
                              style={{ cursor: "pointer", color: student.isLocked ? "#fb0f01" : "grey" }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      noDataFound && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <FaFileAlt className="mt-3" size={70} color="#888" />
                            <h4 className="alert-heading mb-3 mt-3">No Students Found</h4>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>

                </Table>
</div>
                <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }
  
          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }
  
          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
              </div>
              <div className=" text-center pt-3">
              <Button
                    className='btn-no-radius ms-2 responsive-button'
                    color='primary'
                    onClick={handleCreateMark}
                  >
                    {loading ? (
                      <>
                   <Spinner size="sm" style={{ marginRight: "5px" }} />
                   Saving...
                  </>
                   ) : (
                    "Save"
                    )}
                  </Button>
              </div>
            </Card>
          </> :( <></>)
          }
          </div>
        ) : (
          <div
            className='d-flex justify-content-center align-items-center p-4'
            style={{ minHeight: '32vh' }}
          >
            <Spinner color='primary' />
          </div>
        )}
        </>
    
    </>
  );
};

export default Fees;
