import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  FormGroup,
  Button,
  Row,
  Col,
  CardText,
  Spinner,
  Table,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";

import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../../middleware/axiosInstance";
import Select from "react-select";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { FiArrowLeft } from "react-icons/fi";

const StudentDetails = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [formData, setFormData] = useState({ academicYearId: "" });
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultloading, setResultLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [studentHistory, setStudentHistory] = useState([]);
  const [promotionDetail, setPromotionDetail] = useState([]);
  const [resultDetail, setResultDetail] = useState([]);
  const [feesDetail, setFeesDetail] = useState([]);
  const [feesloading, setFeesLoading] = useState(false);
  const [editHistory, setEditHistory] = useState([]);
  const [editHistoryLoading, setEditHistoryLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [openAccordion, setOpenAccordion] = useState("1");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? "" : id);
  };

  const [openIndex, setOpenIndex] = useState("0");

  const toggleAccordion2 = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM dd, yyyy");
  };
  const { id } = useParams();


  const fetchStudentData = async () => {
    try {
      const response = await axiosInstance.get(`/singlestudent/${id}`);
      setStudentData(response.data);
      const academicYearId = response.data.academicyear_id._id;
      fetchAcademicYears(academicYearId);
      fetchStudentInfoByAcademicYear(response?.data?.academicyear_id?._id);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchStudentData();
    }
  }, [id]);

  const fetchAcademicYears = async (academicYearId) => {
    try {
      const response = await axiosInstance.get("/academicyears");
      const sortedAcademicYears = response.data.sort((a, b) => {
        const startYearA = parseInt(a.year.split("-")[0]);
        const startYearB = parseInt(b.year.split("-")[0]);

        return startYearA - startYearB;
      });

      setAcademicYears(sortedAcademicYears);

      const matchedYear = sortedAcademicYears.find(
        (ay) => ay._id === academicYearId
      );

      if (matchedYear) {
        setFormData((prevState) => ({
          ...prevState,
          academicYearId: matchedYear._id,
        }));
        fetchStudentFeesHistory(matchedYear._id);
      }
    } catch (error) {
      console.error("Error fetching academic years:", error);
    }
  };

  const fetchStudentInfoByAcademicYear = async (academicYearId) => {
    setLoading(true);
    setNoDataFound(false);
    try {
      const response = await axiosInstance.get(
        "/history/studentByAcademicYearId",
        {
          params: {
            studentId: id,
            academicYearId,
          },
        }
      );


      if (response.data && Object.keys(response.data).length > 0) {
        setStudentHistory(response.data);
        setNoDataFound(false);
      } else {
        setStudentHistory(null);
        setNoDataFound(true);
      }
    } catch (error) {
      console.error("Error fetching student info by academic year:", error);
      setNoDataFound(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudentPromotionHistory = async (academicYearId) => {
    setLoading(true);
    setNoDataFound(false);
    try {
      const response = await axiosInstance.get("/history/promotion", {
        params: {
          studentId: id,
          academicYearId: academicYearId,
        },
      });
      setPromotionDetail(response.data);
    } catch (error) {
      console.error("Error fetching student info by academic year:", error);
      setNoDataFound(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudentResultHistory = async () => {
    setResultLoading(true);
    setNoDataFound(false);
    try {
      const response = await axiosInstance.get("/history/result", {
        params: {
          studentId: id,
          classId: studentHistory.class_id._id,
          sectionId: studentHistory.section_id
            ? studentHistory.section_id._id
            : null,
          academicId: studentHistory.academicyear_id._id,
        },
      });

      if (response.data == [] || response.data == "") {
        setResultDetail([]);
      } else {
        setResultDetail(response.data);
      }
    } catch (error) {
      setResultDetail([]);
      setNoDataFound(true);
    } finally {
      setResultLoading(false);
    }
  };

  const fetchStudentFeesHistory = async (academicYearId) => {
    setFeesLoading(true);
    setNoDataFound(false);
    try {
      const response = await axiosInstance.get("/history/fees", {
        params: {
          studentId: id,
          academicId: academicYearId,
        },
      });

      if (response.data == [] || response.data == "") {
        setFeesDetail([]);
      } else {
        setFeesDetail(response.data.feeDetails);
      }
    } catch (error) {
      console.error("Error fetching student Fees by academic year:", error);
      setFeesDetail([]);
      setNoDataFound(true);
    } finally {
      setFeesLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentResultHistory();
  }, [studentHistory]);

  useEffect(() => {
    if (formData?.academicYearId) {
      fetchStudentPromotionHistory(formData.academicYearId);
    }
  }, [formData.academicYearId]);

  const fetchStudentEditHistory = async (academicYearId) => {
    setEditHistoryLoading(true);
    setNoDataFound(false);
    try {
      const response = await axiosInstance.get("/history/edit", {
        params: {
          studentId: id,
          academicYearId: academicYearId,
        },
      });
      if (response.data.editHistory.length > 0) {
        setEditHistory(response.data.editHistory);
        setNoDataFound(false);
      } else {
        setEditHistory([]);
        setNoDataFound(true);
      }
    } catch (error) {
      console.error("Error fetching student edit history:", error);
      setEditHistory([]);
      setNoDataFound(true);
    } finally {
      setEditHistoryLoading(false);
    }
  };

  useEffect(() => {
    if (formData?.academicYearId) {
      fetchStudentEditHistory(formData.academicYearId);
    }
  }, [formData?.academicYearId]);

  const handleChange = ({ target: { name, value } }) => {
    setStudentHistory(null);
    setNoDataFound(false);
    setLoading(true);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    fetchStudentInfoByAcademicYear(value);
  };

  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  const handleRevert = async (historyId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to revert this promotion? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, revert it!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const response = await axiosInstance.delete(
          `/revertPramotion/${historyId}`
        );

        if (response.status === 200) {
          Swal.fire(
            "Reverted!",
            "The promotion has been successfully reverted.",
            "success"
          );
          if (formData?.academicYearId) {
            fetchStudentPromotionHistory(formData.academicYearId);
          }
          fetchStudentData();
        } else {
          Swal.fire(
            "Error",
            "Failed to revert promotion. Please try again.",
            "error"
          );
        }
      }
    } catch (error) {
      console.error("Error reverting promotion:", error);
    } finally {
      setLoading(false);
    }
  };

  const historyFormatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM dd, yyyy (hh:mm a)");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <button
        onClick={handleBackClick}
        className="floating-back-button"
        style={{ zIndex: "10" }}
      >
        <FiArrowLeft size={24} />
      </button>
      <div
        style={{
          backgroundColor: "white",
          padding: "0px",
          borderRadius: "0px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Row className="p-0 m-0">
          <Col md="4" style={{ display: "flex", justifyContent: "center" }}>
            {studentData && studentData.image ? (
              <img
                src={studentData.image || "default-image-url"}
                alt="Student"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                  borderRadius: "0px",
                  marginTop: "10px",
                }}
              />
            ) : (
              <div
                className="square mt-2 mt-md-0"
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#fb0f01",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  fontSize: "60px",
                  fontWeight: "bold",
                }}
              >
                {getInitial(studentData?.firstName)}
              </div>
            )}
          </Col>
          <Col md="8" className="p-3">
            <CardTitle tag="h4" className="mb-3">
              {studentData
                ? `${studentData.firstName} ${studentData.lastName}`
                : "Student Name"}
            </CardTitle>
            <CardText>
              <strong>Academic Year:</strong>{" "}
              {studentData?.academicyear_id.year || "N/A"}
            </CardText>
            <CardText>
              <strong>Register Number:</strong>{" "}
              {studentData?.registerNo || "N/A"}
            </CardText>
            <CardText>
              <strong>Branch:</strong>{" "}
              {studentData?.branch_id?.branchName || "N/A"}
            </CardText>
            <CardText>
              <strong>Class:</strong> {studentData?.class_id?.className}
              {studentData?.section_id
                ? ` (${studentData.section_id.sectionName})`
                : ""}
            </CardText>
          </Col>
        </Row>
      </div>

      <Card
        className=" px-3 pt-0 pb-5"
        style={{ borderRadius: "0", minHeight: "400px", marginTop: "10px" }}
      >
        <div>
          <Col
            sm="6"
            md="4"
            lg="3"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          >
            <FormGroup className="academic-year-selector">
              <Select
                id="academicYear"
                options={academicYears.map((year) => ({
                  value: year._id,
                  label: year.year,
                }))}
                onChange={(selected) => {
                  fetchStudentFeesHistory(selected ? selected.value : "");
                  handleChange({
                    target: {
                      name: "academicYearId",
                      value: selected ? selected.value : "",
                    },
                  });
                }}
                value={
                  formData.academicYearId &&
                  academicYears.find(
                    (year) => year._id === formData.academicYearId
                  )
                    ? {
                        value: formData.academicYearId,
                        label: academicYears.find(
                          (year) => year._id === formData.academicYearId
                        ).year,
                      }
                    : null
                }
                placeholder="Select Academic Year"
                isSearchable={true}
                styles={customStyles}
              />
            </FormGroup>
          </Col>

          <Card>
            {isSmallScreen ? (
              <Accordion open={openAccordion} toggle={toggleAccordion}>
                <AccordionItem>
                  <style>
                    {`
             .accordion-button:not(.collapsed) {
                background-color: #FBFBFB;
                font-weight: bold;
                box-shadow: 0 4px 6px rgb(173 170 170 / 50%);

              }
            .accordion-button{
                background-color: #e9ecef;
                color: "black",
            }
                .accordion-button:hover{
                color:red;
                }
                .accordion-button:focus {
                                box-shadow: 0 4px 6px rgb(173 170 170 / 50%);
            }
            `}
                  </style>
                  <AccordionHeader targetId="1">
                    Student Information
                  </AccordionHeader>
                  <style>
                    {`
                .accordion-body {
                  padding: 0 !important; 
                }
              .accordion-button:not(.collapsed) {
                color: black;
              }
              `}
                  </style>
                  <AccordionBody accordionId="1" className=" p-0">
                    <CardBody
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : studentHistory &&
                        Object.keys(studentHistory).length > 0 ? (
                        <div className="">
                          <p className=" mt-0 pt-0">
                            <strong>Roll No.:</strong> {studentHistory?.roll}
                          </p>

                          <p>
                            <strong>Student Name:</strong>{" "}
                            {studentHistory?.firstName}{" "}
                            {studentHistory?.lastName}
                          </p>
                          <p>
                            <strong>Register No.:</strong>{" "}
                            {studentHistory?.registerNo}
                          </p>
                          <p>
                            <strong>Class:</strong>{" "}
                            {studentHistory.class_id?.className}{" "}
                            {studentHistory.section_id
                              ? ` (${studentHistory.section_id.sectionName})`
                              : ""}
                          </p>

                          <p>
                            <strong>Branch:</strong>{" "}
                            {studentHistory?.branch_id?.branchName || "N/A"}
                          </p>

                          <div className="text-center d-flex justify-content-center mt-4">
                            <Button color="primary" onClick={toggleModal}>
                              View Details
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </CardBody>
                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      className="custom-modal"
                    >
                      <style>
                        {`
            .custom-modal  {
              max-width: 70%; 
              margin: 1.75rem auto; 
            }
            .custom-modal {
              border-radius: 8px;
            }

            @media (max-width: 550px) {
              .custom-modal  {
                max-width: 100%; 
                margin: 1rem; 
              }
            }
          `}
                      </style>
                      <ModalHeader toggle={toggleModal}>
                        Student Details
                      </ModalHeader>
                      <ModalBody>
                        {studentHistory &&
                        Object.keys(studentHistory).length > 0 ? (
                          <div>
                            <Row className="m-0 p-0 mt-2">
                              <Col lg="3" className="m-0 p-0 pe-0 pe-md-2">
                                <Card>
                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="pt-3 pe-2 ps-0 d-flex flex-column align-items-center"
                                  >
                                    {studentHistory.image ? (
                                      <img
                                        className="rounded-circle mt-3"
                                        src={studentHistory.image}
                                        alt="Student Image"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "contain",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="rounded-circle"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          backgroundColor: "#fb0f01",
                                          color: "#fff",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontSize: "40px",
                                          fontWeight: "bold",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {getInitial(studentHistory.firstName)}
                                      </div>
                                    )}

                                    <div>
                                      <CardTitle tag="h4">
                                        {studentHistory.firstName}{" "}
                                        {studentHistory.lastName}
                                      </CardTitle>
                                    </div>
                                    <CardText tag="h6" className="mb-3">
                                      (
                                      {studentHistory.academicyear_id?.year ||
                                        "N/A"}
                                      )
                                    </CardText>
                                  </div>

                                  <div className="m-3">
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Roll Number:</strong>
                                      </CardText>
                                      <CardText style={{ color: "blue" }}>
                                        {studentHistory.roll || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Class:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.class_id?.className}{" "}
                                        {studentHistory.section_id
                                          ? ` (${studentHistory.section_id.sectionName})`
                                          : ""}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Gender:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.gender || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Register No:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.registerNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Student ID:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.StudentID || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>
                                </Card>
                              </Col>

                              <Col className="ps-0 pe-0" lg="9">
                                <Card>
                                  <div className="m-3 me-sm-4">
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Branch:</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.branch_id?.branchName ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Admission Date:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.admissionDate
                                          ? formatDate(
                                              studentHistory.admissionDate
                                            )
                                          : "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Email:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.email || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mobile Number:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.mobileNumber || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Date of Birth:</strong>
                                      </CardText>
                                      <CardText>
                                        {formatDate(
                                          studentHistory.dateOfBirth
                                        ) || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Category:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.category || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Caste :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.caste || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Religion :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.religion || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Blood Group :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.bloodGroup || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother Tongue :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherTongue || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Aadhaar Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.aadhaarNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Nationality :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.nationality || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.state || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>District :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.district || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>City/Town/Village :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.city || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Tahsil :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.tahsil || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.address || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>

                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="px-3 pt-3"
                                  >
                                    <CardTitle tag="h4">
                                      Guardian's Details
                                    </CardTitle>
                                  </div>
                                  <div className="m-3 me-sm-5">
                                    <div className="pt-2 mb-4">
                                      <CardText>
                                        <strong style={{ fontSize: "1.20rem" }}>
                                          Mother's Details
                                        </strong>
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's First Name:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherName || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Student's Relation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherrelation || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Occupation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motheroccupation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Annual Income :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherincome || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Education:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.mothereducation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's Phone Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherMobileNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's Email :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherEmail || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherState || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>
                                          Mother's City/Town/Village :
                                        </strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherCity || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Mother's Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.motherAddress || "N/A"}
                                      </CardText>
                                    </div>

                                    <div className="pt-2 mb-4">
                                      <CardText>
                                        <strong style={{ fontSize: "1.20rem" }}>
                                          Father's Details
                                        </strong>
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's First Name:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherName || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Student's Relation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherrelation || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Occupation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatheroccupation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Annual Income :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherincome || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Education:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fathereducation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's Phone Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherMobileNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's Email :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherEmail || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherState || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>
                                          Father's City/Town/Village :
                                        </strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherCity || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Father's Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.fatherAddress || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>

                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="px-3 pt-3"
                                  >
                                    <CardTitle tag="h4">
                                      Previous School Details
                                    </CardTitle>
                                  </div>
                                  <div className="m-3 me-sm-5">
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Previous School :</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.previousSchoolName ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Qualification :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.qualification || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Remark :</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.remarks || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div>No Student History Available.</div>
                        )}
                      </ModalBody>

                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    Examination Information
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <CardBody
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {resultloading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <Table
                                bordered
                                striped
                                responsive
                                className=" mb-0"
                              >
                                <thead>
                                  <tr>
                                    <th>Exam Name</th>
                                    <th>Percentage</th>
                                    <th>Result</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resultDetail.length > 0 ? (
                                    resultDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>{history.examName}</td>
                                        <td>{history.percentage}</td>
                                        <td>{history.resultstatus}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                        No Examination History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Fees Information
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <CardBody
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {feesloading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <Table
                                bordered
                                striped
                                responsive
                                className=" mb-0"
                              >
                                <thead>
                                  <tr>
                                    <th>Class</th>
                                    <th>Fee Type</th>
                                    <th>Total Payable</th>
                                    <th>Collected</th>
                                    <th>Fee Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {feesDetail.length > 0 ? (
                                    feesDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>{history.className}</td>
                                        <td>{history.feeType}</td>
                                        <td>{history.totalPayableAmount}</td>
                                        <td>{history.totalAmount}</td>
                                        <td>{history.status}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        No Fees History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="4">
                    Promotion History
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <CardBody
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <Table
                                bordered
                                striped
                                responsive
                                className=" mb-0"
                              >
                                <thead>
                                  <tr>
                                    <th>Previous Session</th>
                                    <th>Promoted Session</th>
                                    <th>Result</th>
                                    <th>Promoted Date</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {promotionDetail.length > 0 ? (
                                    promotionDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>{history.from}</td>
                                        <td>
                                          {history.isContinue
                                            ? history.to
                                            : "Leave"}
                                        </td>
                                        <td>
                                          {history.isPass ? "Pass" : "Fail"}
                                        </td>
                                        <td>
                                          {history.date
                                            ? formatDate(history.date || "")
                                            : ""}
                                        </td>
                                        <td>
                                          {index ===
                                            promotionDetail.length - 1 && (
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                handleRevert(history._id)
                                              }
                                            >
                                              Revert
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        No Promotion History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="5">Edit History</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <CardBody
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {editHistoryLoading ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <Accordion
                          open={openIndex}
                          toggle={toggleAccordion2}
                          style={{ maxHeight: "263px", overflowY: "auto" }}
                        >
                          {editHistory.length > 0 ? (
                            editHistory.map((history, index) => (
                              <AccordionItem key={index}>
                                <style>
                                  {`
                                        .accordion-button:not(.collapsed) {
                                            background-color: #FBFBFB;
                                            font-weight: bold;
                                            box-shadow: 0 4px 6px rgb(173 170 170 / 50%);

                                          }
                                        .accordion-button{
                                            background-color: #e9ecef;
                                            color: "black",
                                        }
                                            .accordion-button:hover{
                                            color:red;
                                            }
                                            .accordion-button:focus {
                                                            box-shadow: 0 4px 6px rgb(173 170 170 / 50%);
                                        }
                                        `}
                                </style>
                                <AccordionHeader
                                  targetId={index.toString()}
                                  style={{
                                    backgroundColor: "#f1f1f1",
                                  }}
                                >
                                  {historyFormatDate(history.modifiedAt)}
                                </AccordionHeader>
                                <style>
                                  {`
                                            .accordion-body {
                                              padding: 0 !important; 
                                            }
                                                 .accordion-button:not(.collapsed) {
                                                      color: black;
                                                }
                                          `}
                                </style>

                                <AccordionBody
                                  accordionId={index.toString()}
                                  className=""
                                >
                                  <div
                                    style={{ overflowX: "auto" }}
                                    className="p-2"
                                  >
                                    <div
                                      style={{
                                        borderTop: "1px solid #DEE2E6",
                                      }}
                                      className="position-relative "
                                    >
                                      <Table
                                        bordered
                                        responsive
                                        striped
                                        className="mb-0"
                                      >
                                        <thead
                                          className="position-sticky"
                                          style={{ top: "-1px" }}
                                        >
                                          <tr>
                                            <th>Modified Field</th>
                                            <th>Previous Value</th>
                                            <th>Updated Value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {history.modifiedFields.length > 0 ? (
                                            history.modifiedFields.map(
                                              (field, idx) => (
                                                <tr key={idx}>
                                                  <td>{field.fieldName}</td>
                                                  <td>
                                                    {field.oldValue || "-"}
                                                  </td>
                                                  <td>
                                                    {field.newValue || "-"}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="3"
                                                className="text-center"
                                              >
                                                No Modified Fields Available
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </AccordionBody>
                              </AccordionItem>
                            ))
                          ) : (
                            <div className="text-center">
                              No Edit History Available
                            </div>
                          )}
                        </Accordion>
                      )}
                      <style>
                        {`
                                      table td, table th {
                                        white-space: nowrap;
                                      }
                                      @media (max-width: 768px) {
                                        table td, table th {
                                          padding: 5px;
                                        }
                                      }
                                    `}
                      </style>
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Nav
                  tabs
                  className="w-100 d-flex"
                  style={{ borderTop: "1px solid #e9ecef" }}
                >
                  <NavItem className="flex-fill ">
                    <NavLink
                      className={`text-center ${
                        activeTab === "1" ? "active" : ""
                      } d-flex justify-content-center`}
                      onClick={() => toggle("1")}
                      style={{
                        color: "black",
                        backgroundColor:
                          activeTab === "1" ? "#FBFBFB" : "#e9ecef",
                        fontWeight: activeTab === "1" ? "bold" : "normal",
                        padding: "15px 0",
                        border: "1px solid #FBFBFB",
                        borderBottom: "none",
                        borderRadius: "0",
                        cursor: "pointer",
                      }}
                    >
                      Student Information
                    </NavLink>
                  </NavItem>
                  <NavItem className="flex-fill">
                    <NavLink
                      className={`text-center ${
                        activeTab === "2" ? "active" : ""
                      }  d-flex justify-content-center`}
                      onClick={() => toggle("2")}
                      style={{
                        color: "black",
                        backgroundColor:
                          activeTab === "2" ? "#FBFBFB" : "#e9ecef",
                        fontWeight: activeTab === "2" ? "bold" : "normal",
                        padding: "15px 0",
                        border: "1px solid #FBFBFB",
                        borderBottom: "none",
                        borderRadius: "0",
                        cursor: "pointer",
                      }}
                    >
                      Examination Information
                    </NavLink>
                  </NavItem>
                  <NavItem className="flex-fill">
                    <NavLink
                      className={`text-center ${
                        activeTab === "3" ? "active" : ""
                      }  d-flex justify-content-center`}
                      onClick={() => toggle("3")}
                      style={{
                        color: "black",
                        backgroundColor:
                          activeTab === "3" ? "#FBFBFB" : "#e9ecef",
                        fontWeight: activeTab === "3" ? "bold" : "normal",
                        padding: "15px 0",
                        border: "1px solid #FBFBFB",
                        borderBottom: "none",
                        borderRadius: "0",
                        cursor: "pointer",
                      }}
                    >
                      Fees Information
                    </NavLink>
                  </NavItem>
                  <NavItem className="flex-fill">
                    <NavLink
                      className={`text-center ${
                        activeTab === "4" ? "active" : ""
                      }  d-flex justify-content-center`}
                      onClick={() => toggle("4")}
                      style={{
                        color: "black",
                        backgroundColor:
                          activeTab === "4" ? "#FBFBFB" : "#e9ecef",
                        fontWeight: activeTab === "4" ? "bold" : "normal",
                        padding: "15px 0",
                        border: "1px solid #FBFBFB",
                        borderBottom: "none",
                        borderRadius: "0",
                        cursor: "pointer",
                      }}
                    >
                      Promotion History
                    </NavLink>
                  </NavItem>
                  <NavItem className="flex-fill">
                    <NavLink
                      className={`text-center ${
                        activeTab === "5" ? "active" : ""
                      }  d-flex justify-content-center`}
                      onClick={() => toggle("5")}
                      style={{
                        color: "black",
                        backgroundColor:
                          activeTab === "5" ? "#FBFBFB" : "#e9ecef",
                        fontWeight: activeTab === "5" ? "bold" : "normal",
                        padding: "15px 0",
                        border: "1px solid #FBFBFB",
                        borderBottom: "none",
                        borderRadius: "0",
                        cursor: "pointer",
                      }}
                    >
                      Edit History
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <CardBody
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : studentHistory &&
                        Object.keys(studentHistory).length > 0 ? (
                        <div className="">
                          <p className=" mt-0 pt-0">
                            <strong>Roll No.:</strong> {studentHistory?.roll}
                          </p>

                          <p>
                            <strong>Student Name:</strong>{" "}
                            {studentHistory?.firstName}{" "}
                            {studentHistory?.lastName}
                          </p>
                          <p>
                            <strong>Register No.:</strong>{" "}
                            {studentHistory?.registerNo}
                          </p>
                          <p>
                            <strong>Class:</strong>{" "}
                                        {studentHistory.class_id?.className}{" "}
                                        {studentHistory.section_id
                                          ? ` (${studentHistory.section_id.sectionName})`
                                          : ""}

                          </p>
                          <p>
                            <strong>Branch:</strong>{" "}
                            {studentHistory?.branch_id?.branchName || "N/A"}
                          </p>

                          <div className="text-center d-flex justify-content-center mt-4">
                            <Button color="primary" onClick={toggleModal}>
                              View Details
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </CardBody>
                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      className="custom-modal"
                    >
                      <style>
                        {`
            .custom-modal  {
              max-width: 70%; 
              margin: 1.75rem auto; 
            }
            .custom-modal {
              border-radius: 8px;
            }

            @media (max-width: 550px) {
              .custom-modal  {
                max-width: 100%; 
                margin: 1rem; 
              }
            }
          `}
                      </style>
                      <ModalHeader toggle={toggleModal}>
                        Student Details
                      </ModalHeader>
                      <ModalBody>
                        {studentHistory &&
                        Object.keys(studentHistory).length > 0 ? (
                          <div>
                            <Row className="m-0 p-0 mt-2">
                              <Col lg="3" className="m-0 p-0 pe-0 pe-md-2">
                                <Card>
                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="pt-3 pe-2 ps-0 d-flex flex-column align-items-center"
                                  >
                                    {studentHistory.image ? (
                                      <img
                                        className="rounded-circle mt-3"
                                        src={studentHistory.image}
                                        alt="Student Image"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "contain",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="rounded-circle"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          backgroundColor: "#fb0f01",
                                          color: "#fff",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontSize: "40px",
                                          fontWeight: "bold",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {getInitial(studentHistory.firstName)}
                                      </div>
                                    )}

                                    <div>
                                      <CardTitle tag="h4">
                                        {studentHistory.firstName}{" "}
                                        {studentHistory.lastName}
                                      </CardTitle>
                                    </div>
                                    <CardText tag="h6" className="mb-3">
                                      (
                                      {studentHistory.academicyear_id?.year ||
                                        "N/A"}
                                      )
                                    </CardText>
                                  </div>

                                  <div className="m-3">
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Roll Number:</strong>
                                      </CardText>
                                      <CardText style={{ color: "blue" }}>
                                        {studentHistory.roll || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Class:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.class_id?.className}{" "}
                                        {studentHistory.section_id
                                          ? ` (${studentHistory.section_id.sectionName})`
                                          : ""}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Gender:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.gender || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Register No:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.registerNo || "N/A"}
                                      </CardText>
                                    </div>
                                   <div className="d-flex justify-content-between">
                                    <CardText className="" style={{width:'150px'}}>
                                      <strong>Student ID:</strong>
                                    </CardText>
                                    <CardText style={{ wordBreak: 'break-word', textWrap: 'wrap' }}> {studentData.StudentID}</CardText>
                                  </div>
                                  </div>
                                </Card>
                              </Col>

                              <Col className="ps-0 pe-0" lg="9">
                                <Card>
                                  <div className="m-3 me-sm-4">
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Branch:</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.branch_id?.branchName ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Admission Date:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.admissionDate
                                          ? formatDate(
                                              studentHistory.admissionDate
                                            )
                                          : "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Email:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.email || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mobile Number:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.mobileNumber || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Date of Birth:</strong>
                                      </CardText>
                                      <CardText>
                                        {formatDate(
                                          studentHistory.dateOfBirth
                                        ) || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Category:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.category || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Caste :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.caste || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Religion :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.religion || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Blood Group :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.bloodGroup || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother Tongue :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherTongue || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Aadhaar Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.aadhaarNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Nationality :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.nationality || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.state || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>District :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.district || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>City/Town/Village :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.city || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Tahsil :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.tahsil || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.address || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>

                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="px-3 pt-3"
                                  >
                                    <CardTitle tag="h4">
                                      Guardian's Details
                                    </CardTitle>
                                  </div>
                                  <div className="m-3 me-sm-5">
                                    <div className="pt-2 mb-4">
                                      <CardText>
                                        <strong style={{ fontSize: "1.20rem" }}>
                                          Mother's Details
                                        </strong>
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's First Name:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherName || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Student's Relation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherrelation || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Occupation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motheroccupation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Annual Income :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherincome || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Education:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.mothereducation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's Phone Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherMobileNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's Email :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherEmail || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Mother's State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherState || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>
                                          Mother's City/Town/Village :
                                        </strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.motherCity || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Mother's Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.motherAddress || "N/A"}
                                      </CardText>
                                    </div>

                                    <div className="pt-2 mb-4">
                                      <CardText>
                                        <strong style={{ fontSize: "1.20rem" }}>
                                          Father's Details
                                        </strong>
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's First Name:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherName || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Student's Relation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherrelation || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Occupation :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatheroccupation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Annual Income :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherincome || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Education:</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fathereducation ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's Phone Number :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherMobileNo || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's Email :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherEmail || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Father's State :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherState || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>
                                          Father's City/Town/Village :
                                        </strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.fatherCity || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Father's Address :</strong>
                                      </CardText>
                                      <CardText className="text-end">
                                        {studentHistory.fatherAddress || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>

                                  <div
                                    style={{ backgroundColor: "#f8f9fa" }}
                                    className="px-3 pt-3"
                                  >
                                    <CardTitle tag="h4">
                                      Previous School Details
                                    </CardTitle>
                                  </div>
                                  <div className="m-3 me-sm-5">
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Previous School :</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.previousSchoolName ||
                                          "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText>
                                        <strong>Qualification :</strong>
                                      </CardText>
                                      <CardText>
                                        {studentHistory.qualification || "N/A"}
                                      </CardText>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <CardText className="">
                                        <strong>Remark :</strong>
                                      </CardText>
                                      <CardText className=" text-end">
                                        {studentHistory.remarks || "N/A"}
                                      </CardText>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div>No Student History Available.</div>
                        )}
                      </ModalBody>

                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </TabPane>
                  <TabPane tabId="2">
                    <CardBody
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {resultloading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{
                                maxHeight: "260px",
                                overflowY: "auto",
                                borderTop: " 1px solid #DEE2E6",
                                borderBottom: " 1px solid #DEE2E6",
                              }}
                              className=" position-relative"
                            >
                              <Table bordered striped className=" mb-0">
                                <thead
                                  className=" position-sticky "
                                  style={{ top: "-1px" }}
                                >
                                  <tr>
                                    <th>Exam Name</th>
                                    <th>Percentage</th>
                                    <th>Result</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resultDetail.length > 0 ? (
                                    resultDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>{history.examName}</td>
                                        <td>{history.percentage}</td>
                                        <td>{history.resultstatus}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                        No Examination History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </TabPane>
                  <TabPane tabId="3">
                    <CardBody
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {feesloading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{
                                maxHeight: "260px",
                                overflowY: "auto",
                                borderTop: " 1px solid #DEE2E6",
                                borderBottom: " 1px solid #DEE2E6",
                              }}
                              className=" position-relative"
                            >
                              <Table bordered striped className=" mb-0">
                                <thead
                                  className=" position-sticky "
                                  style={{ top: "-1px" }}
                                >
                                  <tr>
                                    <th>Class</th>
                                    <th>Fee Type</th>
                                    <th>Total Payable</th>
                                    <th>Collected</th>
                                    <th>Fee Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {feesDetail.length > 0 ? (
                                    feesDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>{history.className}</td>
                                        <td>{history.feeType}</td>
                                        <td>{history.totalPayableAmount}</td>
                                        <td>{history.totalAmount}</td>
                                        <td>{history.status}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        No Fees History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </TabPane>
                  <TabPane tabId="4">
                    <CardBody
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ overflowX: "auto" }}>
                            <div
                              style={{
                                maxHeight: "260px",
                                overflowY: "auto",
                                borderTop: " 1px solid #DEE2E6",
                                borderBottom: " 1px solid #DEE2E6",
                              }}
                              className=" position-relative"
                            >
                              <Table bordered striped className=" mb-0">
                                <thead
                                  className=" position-sticky "
                                  style={{ top: "-1px" }}
                                >
                                  <tr>
                                    <th>Previous Session</th>
                                    <th>Promoted Session</th>
                                    <th>Result</th>
                                    <th>Promoted Date</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {promotionDetail.length > 0 ? (
                                    promotionDetail.map((history, index) => (
                                      <tr key={index}>
                                        <td>
                                          {history.isreverted &&
                                          history.isrevertedhistory &&
                                          !history.isContinue
                                            ? "Leave"
                                            : history.from}
                                        </td>
                                        <td>
                                          {history.isContinue ||
                                          (history.isreverted &&
                                            history.isrevertedhistory)
                                            ? history.to
                                            : "Leave"}
                                        </td>
                                        <td>
                                          {history.isPass ? "Pass" : "Fail"}
                                        </td>
                                        <td>
                                          {history.date
                                            ? formatDate(history.date || "")
                                            : ""}
                                        </td>

                                        <td>
                                          {history?.isrevertedhistory ? (
                                            <span style={{ color: "#fb0f01" }}>
                                              Reverted
                                            </span>
                                          ) : index ===
                                            promotionDetail.length - 1 ? (
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                handleRevert(history._id)
                                              }
                                              disabled={
                                                history?.isreverted || false
                                              }
                                            >
                                              Revert
                                            </Button>
                                          ) : (
                                            <span style={{ color: "#008ffb" }}>
                                              Promoted
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        No Promotion History Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </TabPane>
                  <TabPane tabId="5">
                    <CardBody
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        backgroundColor: "#fbfbfb",
                      }}
                    >
                      {editHistoryLoading ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Spinner
                            color="primary"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </div>
                      ) : (
                        <Accordion
                          open={openIndex}
                          toggle={toggleAccordion2}
                          style={{ height: "263px", overflowY: "auto" }}
                        >
                          {editHistory.length > 0 ? (
                            editHistory.map((history, index) => (
                              <AccordionItem key={index}>
                                <style>
                                  {`
             .accordion-button:not(.collapsed) {
                background-color: #FBFBFB;
                font-weight: bold;
                box-shadow: 0 4px 6px rgb(173 170 170 / 50%);

              }
            .accordion-button{
                background-color: #e9ecef;
                color: "black",
            }
                .accordion-button:hover{
                color:red;
                }
                .accordion-button:focus {
                                box-shadow: 0 4px 6px rgb(173 170 170 / 50%);
            }
            `}
                                </style>
                                <AccordionHeader
                                  targetId={index.toString()}
                                  style={{
                                    backgroundColor: "#f1f1f1",
                                  }}
                                >
                                  {historyFormatDate(history.modifiedAt)}
                                </AccordionHeader>
                                <style>
                                  {`
                .accordion-body {
                  padding: 0 !important;
                }
                  .accordion-button:not(.collapsed) {
                    color: black;
              }
              `}
                                </style>

                                <AccordionBody
                                  accordionId={index.toString()}
                                  className=""
                                >
                                  <div
                                    style={{ overflowX: "auto" }}
                                    className="p-2"
                                  >
                                    <div
                                      style={{
                                        borderTop: "1px solid #DEE2E6",
                                      }}
                                      className="position-relative "
                                    >
                                      <Table bordered striped className="mb-0">
                                        <thead
                                          className="position-sticky"
                                          style={{ top: "-1px" }}
                                        >
                                          <tr>
                                            <th>Modified Field</th>
                                            <th>Previous Value</th>
                                            <th>Updated Value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {history.modifiedFields.length > 0 ? (
                                            history.modifiedFields.map(
                                              (field, idx) => (
                                                <tr key={idx}>
                                                  <td>{field.fieldName}</td>
                                                  <td>
                                                    {field.fieldName ===
                                                      "image" &&
                                                    field.oldValue ? (
                                                      <a
                                                        href={field.oldValue}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        View image
                                                      </a>
                                                    ) : (
                                                      field.oldValue || "-"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {field.fieldName ===
                                                      "image" &&
                                                    field.newValue ? (
                                                      <a
                                                        href={field.newValue}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        View image
                                                      </a>
                                                    ) : (
                                                      field.newValue || "-"
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="3"
                                                className="text-center"
                                              >
                                                No Modified Fields Available
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </AccordionBody>
                              </AccordionItem>
                            ))
                          ) : (
                            <div className="text-center">
                              No Edit History Available
                            </div>
                          )}
                        </Accordion>
                      )}
                      <style>
                        {`
          table td, table th {
            white-space: nowrap;
          }
          @media (max-width: 768px) {
            table td, table th {
              padding: 5px;
            }
          }
        `}
                      </style>
                    </CardBody>
                  </TabPane>
                </TabContent>
              </>
            )}
          </Card>
        </div>
      </Card>
      <style>
        {`
    table td {
                white-space: nowrap;
              }
              table th {
                white-space: nowrap;
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }


    .report-card {
      border: 1px solid #ddd;
      padding: 15px;
      border-radius: 8px;
      background-color: #f8f9fa;
      flex: 1 1 45%;
      margin-bottom: 10px;
      max-height: 300px; 
      overflow-y: auto; 
    }

    .card-header {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 5px;
      text-align: center;
    }

    .card-content {
      font-size: 1rem;
      margin: 3px 0;
      line-height: 1.3;
    }

    .highlight-green {
      color: green;
    }

    .highlight-red {
      color: red;
    }

    .button-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }

    .view-details-button {
      width: 150px;
      height: 40px;
      display: inline-block;
    }

    .view-details-button-forhistory {
      width: 100px;
      height: 30px;
      display: inline-block;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
    }

    .card-student {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap; 
    }

    .academic-year-selector {
      width: 250px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      .report-card {
        flex: 1 1 100%;
      }

      .info-row {
        flex-direction: column;
      }

      .card-student {
        flex-direction: column;
        align-items: flex-start;
      }

      .right-column {
        padding-left: 0;
      }

      .academic-year-selector {
        width: 100%;
      }
    }

    .fee-info-table {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }

    .table-header,
    .table-row {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    .table-header {
      background-color: #f8f9fa;
      font-weight: bold;
    }

    .table-cell {
      flex: 1;
      text-align: center;
    }

    .table-cell strong {
      font-weight: bold;
    }

    .highlight-green {
      color: green;
    }

    .highlight-red {
      color: red;
    }

    .table-row:nth-child(even) {
      background-color: #f1f1f1;
    }
  `}
      </style>
    </>
  );
};

export default StudentDetails;
