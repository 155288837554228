import React from 'react'

export default function Receipt() {
  return (
   <div className=' '>
     <div className=' bg-white vh-100 m-auto ' style={{width:'90%' }}>
     ftftftf
     </div>
   </div>
  )
}
