import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Login.css';  
import { loginUser, selectIsAuthenticated,  selectError } from '../../Redux/AuthRelated/AuthSlice';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners'; 
import { CiLock } from "react-icons/ci";
import { CiUser } from 'react-icons/ci';
import tulipLogoo from '../../assets/images/logos/tulipschoolerp-logo.webp'


const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const error = useSelector(selectError);
  const [loading , setLoading] = useState(false); 

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admindashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateFields = () => {
    let formErrors = {};
    if (!user.email) {
      formErrors.email = "Email field is required";
    }
    if (!user.password) {
      formErrors.password = "Password field is required";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);
    const formErrors = validateFields();
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      toast.error("Please fill in all required fields", { autoClose: 3000 });
      setLoading(false);
      return;
    }
    const action = await dispatch(loginUser(user));
    if (loginUser.fulfilled.match(action)) {
      toast.success("Logged in successfully!");
      navigate('/admindashboard');
      setLoading(false);
    } else {
      setLoading(false);
      if (action.payload?.status === 'NotVerified') {
        await Swal.fire({
          title: action.payload.title, 
          text: action.payload.message, 
          icon: 'warning', 
          confirmButtonText: 'OK',
        });
      } else {
        toast.error(action.payload.message || "Login failed", { autoClose: 3000 });
        setLoading(false);
      }
    }
  };

  return (
    <div className="loginmain text-center">
      <div className='w-100'>
        <img src={tulipLogoo} alt="Tulip Logo" className='tuliplogo'/>
      </div>
        <div className='loginbox bg-white   text-center'>
          <h4 className='mb-1'>WELCOME</h4>
          <p className='mb-3'>Please enter your details to sign in</p>
          <form onSubmit={handleSubmit}>
                    <div className="input-group p-0 mb-1">
                    <CiUser className="input-icon" />
                        <input type="email" 
                      name="email" 
                      id="email" 
                      value={user.email} 
                      onChange={handleChange}
                      placeholder="Enter Login Email"
                       />
                    </div>
                    {errors.email && <p  className="error-text required-message text-start p-0 m-0">{errors.email}</p>}

                    <div className="input-group  p-0 mb-1 mt-3 pt-2">
                    <CiLock className="input-icon" />
                        <input type="password" 
                      name="password" 
                      id="password" 
                      placeholder="Password" 
                      value={user.password} 
                      onChange={handleChange}
                      autoComplete="off"
                     />
                    </div>
                    {errors.password && <p className="error-text required-message text-start p-0 m-0">{errors.password}</p>}
                    <div className="options py-2">
                        
                        <NavLink to='/forgot-password' className="forgot-password fw-bold mt-3">Forgot Password?</NavLink>
                    </div>
                    <button type="submit" name="login" id="login" disabled={loading} className="login-btn mt-2">{loading ? <ClipLoader size={24} color={"#fff"} /> : 'LOGIN'}</button>
                </form>
                <p className="footer-text mt-5 fw-bold">Don't have an account? <NavLink  to="/auth/register" className='fw-bold text-black'>Create account</NavLink></p>
          
        </div>
        <div className='py-md-2 py-0 position-absolute bottom-0 text-center w-100'>
          <p className='text-white  mt-4 pt-5 pt-md-3 fs-5  '>Copyright © 2024 - Termscript Data Solutions India Pvt Ltd.</p>
        </div>  
      <ToastContainer /> 
    </div>
  );
};

export default Login;