import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap";

import axiosInstance from "../../../middleware/axiosInstance";
import Select from "react-select";

const PromoteStudents = () => {
  const [formData, setFormData] = useState({
    branchId: "",
    classId: "",
    sectionId: "",
    academicYearId: "",
  });

  const [promoteFormData, setPromoteFormData] = useState({
    classId: "",
    sectionId: "",
    academicYearId: "",
  });

  



  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [sections, setSections] = useState([]);
  const [pramoteSections, setPramoteSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]); // State to store selected student IDs

  // State for individual student statuses (Passed/Continue/Leave)
  const [studentStatus, setStudentStatus] = useState({});
  const [hasSections, setHasSections] = useState(false);
  const [sectionrun, setSectionrun] = useState(false);
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        const sortedAcademicYears = response.data.sort((a, b) => {
          // Extract starting years from "year" (e.g., "2024-25" => 2024)
          const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);

          // Sort based on the starting year
          return startYearA - startYearB;
        });

        setAcademicYears(sortedAcademicYears);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
      
        let defaultAcademicYear;

        if (currentMonth > 3) {
         
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }


        const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear
        );


        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,
          }));

        
          const currentYearStart = parseInt(
            defaultAcademicYearEntry.year.split("-")[0]
          );

          const nextYear = sortedAcademicYears.find((year) => {
            const yearStart = parseInt(year.year.split("-")[0]);

            return yearStart === currentYearStart + 1;
          });

          // If next year found, set it in promoteFormData
          if (nextYear) {
            setPromoteFormData((prev) => ({
              ...prev,
              academicYearId: nextYear._id, // Automatically set the next academic year
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }
    };

    getAcademicYears();
  }, []);

  useEffect(() => {
    const fetchClassesForFormData = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/classes/branch", {
            params: { branchId: formData.branchId },
          });
          setClasses(response.data);
          setFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
        } catch (error) {
          setError("Failed to fetch classes for formData.");
        }
      } else {
        setClasses([]);
        setSections([]);
        setFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
      }
    };

    fetchClassesForFormData();
  }, [formData.branchId]);

  // Fetch classes based on promoteFormData.branchId
  useEffect(() => {
    const fetchClassesForPromoteFormData = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/classes/branch", {
            params: { branchId: formData.branchId },
          });
          setClasses(response.data);
          
          setPromoteFormData((prev) => ({
            ...prev,
            classId: "",
            sectionId: "",
          }));
        } catch (error) {
          setError("Failed to fetch classes for promoteFormData.");
        }
      } else {
        setClasses([]);
        setPramoteSections([]);
        setPromoteFormData((prev) => ({ ...prev, classId: "", sectionId: "" }));
      }
    };

    fetchClassesForPromoteFormData();
  }, [formData.branchId]);

  useEffect(() => {
    const fetchSectionsForFormData = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
            },
          });
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setSectionrun(true)
          setSections(sortedSections);  
        } catch (error) {
          setError("Failed to fetch sections for formData.");
        }
      } else {
        setSections([]);
      }
    };

    fetchSectionsForFormData();
  }, [formData.branchId, formData.classId]);

  // Fetch sections based on promoteFormData.branchId and promoteFormData.classId
  useEffect(() => {
    const fetchSectionsForPromoteFormData = async () => {
      if (promoteFormData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: promoteFormData.classId,
            },
          });
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setPramoteSections(sortedSections);
        } catch (error) {
          setError("Failed to fetch sections for promoteFormData.");
        }
      } else {
        setPramoteSections([]);
      }
    };

    fetchSectionsForPromoteFormData();
  }, [formData.branchId, promoteFormData.classId]);

  const fetchStudents = async () => {
    try {
      if (!formData.branchId || !formData.classId || !formData.academicYearId) {
        setStudents([]);
        setNoDataFound(false); // Don't show the message if criteria are not met
        return;
      }

      const response = await axiosInstance.get("/allstudents", {
        params: {
          ...formData,
        },
      });

      const sortedStudents = response.data.students.sort(
        (a, b) => a.roll - b.roll
      );
      setStudents(sortedStudents);
      setNoDataFound(response.data.students.length === 0);
      // Set default student statuses to "Pass" and "Continue"
      const defaultStatuses = sortedStudents.reduce((acc, student) => {
        acc[student._id] = { isPassed: true, isContinue: true };
        return acc;
      }, {});
      setStudentStatus(defaultStatuses);
      setSelectedStudentIds(sortedStudents.map((student) => student._id));

      // Filter out students with status: true and set the selected student IDs
      // const filteredStudents = sortedStudents.filter(
      //   (student) => student.status !== true
      // );
      // setSelectedStudentIds(filteredStudents.map((student) => student._id));
    } catch (error) {
      setError("No Data Found");
      setStudents([]);
    }
  };

  // useEffect(() => {
  //   fetchStudents();
  // }, [formData]);

  useEffect(() => {
    setStudents([]);
    if(sectionrun){
     if(sections.length > 0 && formData.sectionId){
      fetchStudents();
      setHasSections(true);
    }else if(sections.length === 0 && formData.classId){
      fetchStudents();
      setHasSections(true);
    }else
    {
  setStudents([]);
  setHasSections(false);
    }
}else{
  setStudents([]);
}
  }, [sectionrun, formData.sectionId, formData.academicYearId]
)

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "classId" ? { sectionId: "" } : {}),
    }));

    if (name === "branchId" || name === "classId") {
      setStudentStatus({});
      setSelectedStudentIds([]);
      setNoDataFound(false);
    }

    // Reset promoteFormData when branchId is unselected/cleared
    if (name === "branchId" && !value) {
      setPromoteFormData((prev) => ({
        ...prev,
       
        classId: "", // Reset classId
        sectionId: "", // Reset sectionId
      }));
    }

    // Reset promoteFormData when classId is unselected/cleared
    if (name === "classId" && !value) {
      setPromoteFormData((prev) => ({
        ...prev,
        classId: "", // Reset classId
        sectionId: "", // Reset sectionId
      }));
    }

    // Reset promoteFormData when academicYearId is unselected/cleared
    if (name === "academicYearId" && !value) {
      setPromoteFormData((prev) => ({
        ...prev,
        academicYearId: "", // Reset academicYearId
      }));
    }

    // // After selecting a class, set the next class automatically
    // if (name === "classId") {
    //   const currentClass = classes.find((cls) => cls._id === value); // Get the current class
    //   if (currentClass) {
    //     // Extract the class number (e.g., "Class 1" -> 1)
    //     const currentClassNumber = parseInt(
    //       currentClass.className.replace(/[^\d]/g, "")
    //     );

    //     // Try to find the next class
    //     const nextClass = classes.find((cls) => {
    //       const nextClassNumber = parseInt(cls.className.replace(/[^\d]/g, ""));
    //       return nextClassNumber === currentClassNumber + 1; // Find the immediate next class
    //     });

    //     // Set the next class if found, otherwise leave it unchanged
    //     if (nextClass) {
    //       setPromoteFormData((prev) => ({
    //         ...prev,
    //         classId: nextClass._id, // Automatically set the next class
    //       }));
    //     } else {
    //       // If no next class found, leave it as is or handle the situation (e.g., set to current class)
    //       setPromoteFormData((prev) => ({
    //         ...prev,
    //         classId: currentClass._id, // Keep the current class if no next class is available
    //       }));
    //     }
    //   }
    // }



    // After selecting a class, set the next class automatically
if (name === "classId") {
  setSectionrun(false);
  const currentClass = classes.find((cls) => cls._id === value); // Get the current class
  if (currentClass) {
    const currentClassSequence = currentClass.sequence; // Get the sequence of the selected class

    // Try to find the next class with the next sequence number
    const nextClass = classes.find((cls) => cls.sequence === currentClassSequence + 1); 
   

    // Set the next class if found, otherwise leave it unchanged
    if (nextClass) {
      setPromoteFormData((prev) => ({
        ...prev,
        classId: nextClass._id, // Automatically set the next class based on sequence
      }));
    } else {
      // If no next class found, leave it as is or handle the situation (e.g., set to current class)
      setPromoteFormData((prev) => ({
        ...prev,
        classId: currentClass._id, // Keep the current class if no next class is available
      }));
    } 
  }
}

    // After selecting an academic year, set the next academic year automatically
    if (name === "academicYearId") {
      const currentYear = academicYears.find((year) => year._id === value); // Get the current academic year
      if (currentYear) {
        const currentYearStart = parseInt(currentYear.year.split("-")[0]);

        // Find the next academic year based on the current year
        const nextYear = academicYears.find((year) => {
          const yearStart = parseInt(year.year.split("-")[0]);
          return yearStart === currentYearStart + 1;
        });

        // Set the next academic year automatically if found, otherwise keep the selected year
        if (nextYear) {
          setPromoteFormData((prev) => ({
            ...prev,
            academicYearId: nextYear._id, // Automatically set the next academic year
          }));
        } else {
          // If no next year is found, keep the current selection (no update needed)
          setPromoteFormData((prev) => ({
            ...prev,
            academicYearId: value, // Keep the selected year
          }));
        }
      }
    }
  };

  const handlePromoteChange = ({ target: { name, value } }) => {
    setPromoteFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "classId" ? { sectionId: "" } : {}),
    }));
  };

  // Save history for selected students

  const handleSelectStudent = (studentId) => {
    setSelectedStudentIds((prevSelectedIds) =>
      prevSelectedIds.includes(studentId)
        ? prevSelectedIds.filter((id) => id !== studentId)
        : [...prevSelectedIds, studentId]
    );
  };

  const handlePassedChange = (studentId, value) => {
    setStudentStatus((prevStatus) => ({
      ...prevStatus,
      [studentId]: {
        ...prevStatus[studentId],
        isPassed: value,
      },
    }));
  };

  const handleContinueChange = (studentId, value) => {
    setStudentStatus((prevStatus) => ({
      ...prevStatus,
      [studentId]: {
        ...prevStatus[studentId],
        isContinue: value,
      },
    }));
  };

  const handleSelectAllStudents = () => {
    setSelectedStudentIds(
      selectedStudentIds.length === students.length
        ? []
        : students.map((student) => student._id)
    );
  };

  const handlePromote = async (e) => {
    e.preventDefault();

    let validationFailed = false;

    const studentsToPromote = selectedStudentIds.map((studentId) => {
      const status = studentStatus[studentId] || {}; // Ensure the student status exists

      // Check for missing required fields
      if (
        status.isPassed === undefined ||
        status.isPassed === null ||
        status.isContinue === undefined ||
        status.isContinue === null
      ) {
        validationFailed = true; // Mark validation as failed
      }

      return {
        studentId,
        isPassed: status.isPassed ?? null, // Default to null if not set
        isContinue: status.isContinue ?? null, // Default to null if not set
      };
    });

    if (validationFailed) {
      // Show SweetAlert warning
      Swal.fire({
        title: "Incomplete Information",
        text: "Please fill all required fields (Pass/Fail and Continue/Leave) for selected students.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Stop execution if validation fails
    }

    try {
      // Make the API request to promote students with the selected data
      await axiosInstance.post("/promote/students", {
        branchId: formData.branchId,
        students: studentsToPromote,
        ...promoteFormData, // Send promoteFormData for promotion (branchId, classId, etc.)
      });

      // Clear selected students and form data after successful promotion
      setSelectedStudentIds([]);
      setStudentStatus({});

      setFormData({
        branchId: "",
        classId: "",
        sectionId: "",
        academicYearId: "",
      });

      setPromoteFormData({
        classId: "",
        sectionId: "",
        academicYearId: "",
      });
    } catch (error) {
      // console.log(error);
    }
  };

  // Custom styles for Select component
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const instructionsBoxStyle = {
    border: "2px solid #fb0f01", // Red border around the box
    padding: "0px", // Space around the content // Light background color
    fontSize: "14px",
    borderRadius: "0px", // Small font size
    // Rounded corners
  };

  // Inline style for the heading (red color)
  const headingStyle = {
    color: "#fb0f01", // Red color for the heading
    fontSize: "16px", // Slightly larger font size for the heading
  };

  const renderTable = () => (
    <>
      {formData.branchId &&
      formData.classId &&
      formData.academicYearId &&
      (hasSections || formData.sectionId) ? (
        <div>
          <Row>
            <Col md={12}>
              <Card style={instructionsBoxStyle}>
                <CardBody className="p-2">
                  <CardTitle tag="h6" style={headingStyle}>
                    Instructions:
                  </CardTitle>
                  <p className="p-0 mt-1">
                    1) Kindly mark the checkbox to approve the student's
                    promotion
                  </p>
                  <p className="p-0 mt-1">
                    2){" "}
                    <strong>
                      If the "Pass and Continue" option is selected
                    </strong>
                    , the student will be promoted to the selected academic
                    session and class.
                  </p>
                  <p className="p-0 mt-1">
                    3){" "}
                    <strong>
                      If the "Fail and Continue" option is selected
                    </strong>
                    , only the academic session will change, and the student
                    will remain in the same class.
                  </p>
                  <p className="p-0 mt-1">
                    4){" "}
                    <strong>
                      If the "Pass/Fail and Leave" option is selected
                    </strong>
                    , the student will remain in the same academic session and
                    class, and the information will be stored.
                  </p>

                  <p className="p-0 mt-1">
                    5) The roll number will be autogenerated. If a duplicate
                    roll number exists in the same academic session and class,
                    it will be resolved automatically.
                  </p>
                  <p className="p-0 mt-1">
                    6) If any mistake is made during promotion, it can be
                    reverted or undone by navigating to{" "}
                    <strong>
                      {" "}
                      Students → Academic History → Promotion History tab.
                    </strong>
                  </p>
                  <p>
                    7) To pass out a student, if they are in the last class,
                    select the next academic year and the same class in the
                    promotion option, and choose 'Pass & Leave option' from the
                    student list. Failed students will be promoted to the next
                    academic year.
                  </p>
                  <p className="p-0 mt-1">
                    8) Please fill in all fields carefully and double-check the
                    information before clicking the <strong>"Promote"</strong>{" "}
                    button.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CardTitle tag="h5" className="mt-3 mb-1">
            Student List
          </CardTitle>
          <div
            style={{
              maxHeight: "492px",
              overflowY: "auto",
              borderTop: "1px solid #DEE2E6",
              borderLeft: "1px solid #DEE2E6",
            }}
            className=" position-relative table-container mb-3"
          >
            <Table bordered striped className=" mb-0 pb-0">
              <thead
                style={{ backgroundColor: "#f8f9fa", color: "#495057" }}
                className="head-stick2"
              >
                <tr>
                  <th>
                    <div className="d-flex align-items-center px-2 ms-1 p-0 m-0">
                      <Input
                        type="checkbox"
                        id="selectAllCheckbox"
                        checked={
                          students.length > 0 &&
                          selectedStudentIds.length === students.length
                        }
                        onChange={handleSelectAllStudents}
                        style={{
                          marginBottom: "5px",
                        }}
                        disabled={students.length === 0}
                      />
                    </div>
                  </th>
                  <th>Academic Year</th>
                  <th>Roll No.</th>
                  <th>Student Name</th>
                  <th>Class</th>
                  <th>Branch</th>
                  <th>Current Result</th>
                  <th>Next Session Status</th>
                </tr>
              </thead>
              <tbody>
                {students.length ? (
                  students.map((student) => {
                    return (
                      <tr key={student._id}>
                        <td>
                          <div className="d-flex align-items-center px-2 ms-1 p-0 m-0 ">
                            <Input
                              type="checkbox"
                              checked={selectedStudentIds.includes(student._id)}
                              onChange={() => handleSelectStudent(student._id)}
                            />
                          </div>
                        </td>
                        <td>{student.academicyear_id?.year}</td>
                        <td>{student.roll}</td>
                        <td
                          style={{
                            color: student.status ? "red" : "inherit",
                          }}
                        >
                          {student.firstName} {student.lastName}
                          {/* {student.status
                            ? `${student.firstName} ${student.lastName} (leave)`
                            : `${student.firstName} ${student.lastName}`} */}
                        </td>
                        <td>
                          {student.class_id?.className}{" "}
                          {student.section_id
                            ? ` (${student.section_id.sectionName})`
                            : ""}
                        </td>
                        <td>{student.branch_id?.branchName}</td>
                        <td>
                          <label className="me-3">
                            <input
                              className="me-1"
                              type="radio"
                              name={`isPassed-${student._id}`}
                              value="pass"
                              onChange={() =>
                                handlePassedChange(student._id, true)
                              }
                              checked={
                                studentStatus[student._id]?.isPassed === true
                              }
                            />
                            Pass
                          </label>
                          <label>
                            <input
                              className="me-1"
                              type="radio"
                              name={`isPassed-${student._id}`}
                              value="fail"
                              onChange={() =>
                                handlePassedChange(student._id, false)
                              }
                              checked={
                                studentStatus[student._id]?.isPassed === false
                              }
                            />
                            Fail
                          </label>
                        </td>
                        <td>
                          <label className="me-3">
                            <input
                              className="me-1"
                              type="radio"
                              name={`isContinue-${student._id}`}
                              value="continue"
                              onChange={() =>
                                handleContinueChange(student._id, true)
                              }
                              checked={
                                studentStatus[student._id]?.isContinue === true
                              }
                            />
                            Continue
                          </label>
                          <label>
                            <input
                              className="me-1"
                              type="radio"
                              name={`isContinue-${student._id}`}
                              value="leave"
                              onChange={() =>
                                handleContinueChange(student._id, false)
                              }
                              checked={
                                studentStatus[student._id]?.isContinue === false
                              }
                            />
                            Leave
                          </label>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No Students Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <style jsx="true">{`
              .head-stick2 {
                position: sticky;
                top: -1px;
                background-color: #fff;
                z-index: 2;
              }
              table td {
                white-space: nowrap;
              }
              table th {
                white-space: nowrap;
              }
              table {
                position: relative;
              }
              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }
            `}</style>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <Card
        style={{ boxShadow: "none", marginBottom: "10px", borderRadius: "0" }}
      >
        <div className="d-flex justify-content-between align-items-center px-3 pt-1">
          <CardTitle className="mt-2" tag="h5">
            Promote Students
          </CardTitle>
        </div>
        <hr className="mt-1" />
        <div className="px-3 pt-0">
          <Form>
            <Row>
                            <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYear">
                    <span style={{ fontWeight: "bold" }}>Academic Year</span>
                  </Label>

                  <Select
                    id="academicYear"
                    options={academicYears
                      .filter((year) => {
                        const currentDate = new Date();
                        const currentYear = currentDate.getFullYear();
                        const currentMonth = currentDate.getMonth();
                       
                        let defaultAcademicYear;

                        if ( currentMonth > 3) {
                       
                          defaultAcademicYear = currentYear;
                        } else {
                          defaultAcademicYear = currentYear - 1;
                        }

                        const yearStart = parseInt(year.year.split("-")[0]);
                        return (
                          yearStart === defaultAcademicYear ||
                          yearStart === defaultAcademicYear - 1
                        ); // Show current year and last year only
                      })
                      .map((year) => ({
                        value: year._id,
                        label: year.year,
                      }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "academicYearId",
                          value: selected ? selected.value : "", // Handle null case
                        },
                      });
                    }}
                    value={
                      academicYears.find(
                        (year) => year._id === formData.academicYearId
                      )
                        ? {
                            value: formData.academicYearId,
                            label: academicYears.find(
                              (year) => year._id === formData.academicYearId
                            ).year,
                          }
                        : null
                    }
                    placeholder="Select Academic Year"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branchId">
                    <span style={{ fontWeight: "bold" }}>Branch</span>
                  </Label>
                  <Select
                    id="branchId"
                    options={branches.map((branch) => ({
                      value: branch._id,
                      label: branch.branchName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "branchId",
                          value: selected ? selected.value : "", // Check if selected is not null
                        },
                      });
                    }}
                    value={
                      branches.find(
                        (branch) => branch._id === formData.branchId
                      )
                        ? {
                            value: formData.branchId,
                            label: branches.find(
                              (branch) => branch._id === formData.branchId
                            ).branchName,
                          }
                        : null
                    }
                    placeholder="Select a Branch"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="classId">
                    <span style={{ fontWeight: "bold" }}>Class</span>
                  </Label>
                  <Select
                    id="classId"
                    options={classes
                      .sort((a, b) => a.sequence - b.sequence) // Sorting by 'sequence' field
                      .map((classItem) => ({
                        value: classItem._id,
                        label: classItem.className,
                      }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "classId",
                          value: selected ? selected.value : "", // Check if selected is not null
                        },
                      });
                    }}
                    value={
                      classes.find(
                        (classItem) => classItem._id === formData.classId
                      )
                        ? {
                            value: formData.classId,
                            label: classes.find(
                              (classItem) => classItem._id === formData.classId
                            ).className,
                          }
                        : null
                    }
                    placeholder="Select a Class"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="sectionId">
                    <span style={{ fontWeight: "bold" }}>Section</span>
                  </Label>
                  <Select
                    id="sectionId"
                    options={sections.map((section) => ({
                      value: section._id,
                      label: section.sectionName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "sectionId",
                          value: selected ? selected.value : "", // Check if selected is not null
                        },
                      });
                    }}
                    value={
                      sections.find(
                        (section) => section._id === formData.sectionId
                      )
                        ? {
                            value: formData.sectionId,
                            label: sections.find(
                              (section) => section._id === formData.sectionId
                            ).sectionName,
                          }
                        : null
                    }
                    placeholder={
                      sections.length === 0 ? "No Section" : "Select a Section"
                    }
                    isDisabled={sections.length === 0}
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {renderTable()}
        </div>
      </Card>

      <Card
        className="pt-0 mb-0"
        style={{ borderRadius: "0", minHeight: "400px" }}
      >
        <div className="d-flex justify-content-between align-items-center px-3 pt-1">
          <CardTitle className="mt-2" tag="h5">
            Promote Students in Next Session
          </CardTitle>
        </div>
        <hr className="mt-1" />
        <div className="px-3 pt-0">
          <Form onSubmit={handlePromote}>
            <Row>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYear">
                    <span style={{ fontWeight: "bold" }}>
                      Promote In Session*
                    </span>
                  </Label>
                 

                  <Select
                    id="academicYear"
                    options={academicYears
                      .filter((year) => {
                        const currentDate = new Date();
                        const currentYear = currentDate.getFullYear();
                        const currentMonth = currentDate.getMonth();
                       
                        let defaultAcademicYear;

                        if ( currentMonth > 3) {
                       
                          defaultAcademicYear = currentYear;
                        } else {
                          defaultAcademicYear = currentYear - 1;
                        }
                        const selectedyear = promoteFormData.academicYearId 
                        ? parseInt(
                            academicYears.find(
                              (year) => year._id === promoteFormData.academicYearId
                            )?.year?.split("-")[0], 
                            10 
                          ) 
                        : null;
                        const yearStart = parseInt(year.year.split("-")[0]);
                        return yearStart === selectedyear;
                      })
                      .map((year) => ({
                        value: year._id,
                        label: year.year,
                      }))}
                    styles={customStyles}
                    onChange={(option) =>
                      handlePromoteChange({
                        target: {
                          name: "academicYearId",
                          value: option ? option.value : "",
                        },
                      })
                    }
                    value={
                      promoteFormData.academicYearId
                        ? {
                            value: promoteFormData.academicYearId,
                            label: academicYears.find(
                              (year) =>
                                year._id === promoteFormData.academicYearId
                            )?.year,
                          }
                        : null
                    }
                    placeholder="Select a Session"
                    required
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="classId">
                    <span style={{ fontWeight: "bold" }}>Class*</span>
                  </Label>
                  <Select
                    id="classId"
                    options={classes.filter(
                      (classItem) =>
                        classItem?.sequence ===
                        classes.find((cls) => cls._id === promoteFormData.classId)?.sequence
                    ).map((classItem) => ({
                      value: classItem._id,
                      label: classItem.className,
                    }))}
                    onChange={(selected) => {
                      handlePromoteChange({
                        target: {
                          name: "classId",
                          value: selected ? selected.value : "", // Check if selected is not null
                        },
                      });
                    }}
                    value={
                      classes.find(
                        (classItem) => classItem._id === promoteFormData.classId
                      )
                        ? {
                            value: promoteFormData.classId,
                            label: classes.find(
                              (classItem) =>
                                classItem._id === promoteFormData.classId
                            ).className,
                          }
                        : null
                    }
                    placeholder="Select a Class"
                    isSearchable={true}
                    // isClearable={true}
                    styles={customStyles}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="sectionId">
                    <span style={{ fontWeight: "bold" }}>Section*</span>
                  </Label>
                  <Select
                    id="sectionId"
                    options={pramoteSections.map((section) => ({
                      value: section._id,
                      label: section.sectionName,
                    }))}
                    onChange={(selected) => {
                      handlePromoteChange({
                        target: {
                          name: "sectionId",
                          value: selected ? selected.value : "", // Check if selected is not null
                        },
                      });
                    }}
                    value={
                      pramoteSections.find(
                        (section) => section._id === promoteFormData.sectionId
                      )
                        ? {
                            value: promoteFormData.sectionId,
                            label: pramoteSections.find(
                              (section) =>
                                section._id === promoteFormData.sectionId
                            ).sectionName,
                          }
                        : null
                    }
                    placeholder={
                      pramoteSections.length === 0
                        ? "No Section"
                        : "Select a Section"
                    }
                    required={pramoteSections.length > 0}
                    isDisabled={pramoteSections.length === 0}
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit" className="mt-3">
              Promote Students
            </Button>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default PromoteStudents;
