import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, Card , CardTitle, Row, Col ,Spinner} from 'reactstrap';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { LuImport } from "react-icons/lu";
import axiosInstance from '../../../middleware/axiosInstance';
import StateCityData from '../Branch/StateCity.json';
import Select from 'react-select';


const EditStudent = () => {
  const { id } = useParams();  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    academicYear: '',
    registerNo: '',
    StudentID:"",
    roll: '',
    admissionDate: '',
    branchId: '',
    classId: '',
    sectionId: '',
    category: '',
    firstName: '',
    lastName: '',
    gender: '',
    bloodGroup: '',
    dateOfBirth: '',
    motherTongue: '',
    religion: '',
    caste: '',
    district: '',
    tahsil: '',
    nationality: '',
    aadhaarNo: '',
    mobileNumber: '',
    email: '',
    city: '',
    state: '',
    address: '',
    fatherName: '', fatherrelation: '', fatheroccupation: '', fatherincome: '', fathereducation: '',
    fatherCity: '', fatherState: '', fatherMobileNo: '', fatherEmail: '', fatherAddress: '',

    motherName: '', motherrelation: '', motheroccupation: '', motherincome: '', mothereducation: '',
    motherCity: '', motherState: '', motherMobileNo: '', motherEmail: '', motherAddress: '',

    previousSchoolName: '',
    qualification: '',

    remarks: '',
    image: null
  });

  const [errors, setErrors] = useState({
    registerNo: '',  StudentID:"", roll: '', firstName: '', lastName: '', motherTongue: '', religion: '',
    nationality: '', aadhaarNo: '',
    caste: '', mobileNumber: '', email: '', city: '', district: '', tahsil: '', state: '', address: '',

    fatherName: '', fatherrelation: '', fatheroccupation: '', fatherincome: '', fathereducation: '',
    fatherCity: '', fatherState: '', fatherMobileNo: '', fatherEmail: '', fatherAddress: '',

    motherName: '', motherrelation: '', motheroccupation: '', motherincome: '', mothereducation: '',
    motherCity: '', motherState: '', motherMobileNo: '', motherEmail: '', motherAddress: '',

    previousSchoolName: '', qualification: '', remarks: '',
    branchId: '', classId: '', sectionId: ''
  });



  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [metadata, setMetadata] = useState({
    gender: [],
    bloodGroup: [],
    category: [],
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [initialRollFetched, setInitialRollFetched] = useState(false);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        const student = response.data;
        setFormData({
          ...student,
          admissionDate: student.admissionDate ? student.admissionDate.split('T')[0] : '',
          dateOfBirth: student.dateOfBirth ? student.dateOfBirth.split('T')[0] : '',
          academicYear: student.academicyear_id._id,
          branchId: student.branch_id._id,
          classId: student.class_id._id,
          sectionId: student.section_id ? student.section_id._id : '',
          roll: student.roll,
          state: student.state || '',    
          district: student.district,
          fatherState: student.fatherState || '',
          motherState: student.motherState || '',
          image: null
        });
  
        const selectedState = StateCityData.states.find(
          (state) => state.state === student.state
        );

        setDistricts(selectedState ? selectedState.district : []);
         if (response.data.image) {
          setImagePreview(response.data.image);  
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching student data:', error);
        setLoading(false);
      }
    };
    fetchStudentData();
  }, []);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await axiosInstance.get('/metadata');
        setMetadata(response.data);
      } catch (err) {
        console.error('Error fetching metadata:', err);
      }
    };

    fetchMetadata();
  }, []);

  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await axiosInstance.get('/academicyears');
        const sortedAcademicYears = response.data.sort((a, b) => {
          // Extract starting years from "year" (e.g., "2024-25" => 2024)
          const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);
  
          // Sort based on the starting year
          return startYearA - startYearB;
        });
  
        setAcademicYears(sortedAcademicYears);

      } catch (err) {
        console.error('Error fetching academic years:', err);
      }
    };
  
    fetchAcademicYears();
  }, []);

  const academicYearOptions = academicYears.map(ay => ({
    value: ay._id,
    label: ay.year,
  }));

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get('/branches');
      setBranches(
        response.data.map((branch) => ({
          value: branch._id,
          label: branch.branchName,
        }))
      );
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };


  const fetchRollNumber = async (sectionId, classId) => {
    try {
    setFormData(prevData => ({ ...prevData, roll: '' }));

      const response = await axiosInstance.get('/rollnumber', {
        params: {
          sectionId: sectionId || '',
          classId: classId || '', 
          id,
          academicYear:formData.academicYear
        },
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        roll: response.data.nextRollNumber,
      }));
    
    } catch (error) {
      console.error('Error fetching roll number:', error);
    }
  };

  const fetchClasses = async (newBranchId) => {
    try {
      const response = await axiosInstance.get('/classes/branch', {
        params: {
          branchId: newBranchId,
        },
      });
      setClasses(
        response.data.map((cls) => ({
          value: cls._id,
          label: cls.className,
        }))
      );
      
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };
  

  const fetchSections = async (branchId , classId) => {
    try {

      const response = await axiosInstance.get('/sections', {
        params: {
          branchId: branchId,
          classId: classId,
        },
      });

      const sortedSections = response.data.sort((a, b) =>
        a.sectionName.localeCompare(b.sectionName)
      );
      setSections(
        sortedSections.map((section) => ({
          value: section._id,
          label: section.sectionName,
        }))
      );
    
      const fetchrollstate = response.data.length;
    
      if (fetchrollstate === 0) {
           fetchRollNumber(null, classId);
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };


  const genderOptions = metadata.gender.map(g => ({
    value: g,
    label: g,
  }));
  
  const bloodGroupOptions = metadata.bloodGroup.map(bg => ({
    value: bg,
    label: bg,
  }));

  const categoryOptions = metadata.category.map(cat => ({
    value: cat,
    label: cat,
  }));


  useEffect(() => {
    fetchBranches();
  }, []);
  
  useEffect(() => {
    if (formData.branchId) {
      fetchClasses(formData.branchId);
    } else {
      setClasses([]);
      setSections([]);
    }
  }, [formData.branchId]);
  
  useEffect(() => {
    if (formData.branchId && formData.classId) {
      fetchSections(formData.branchId,formData.classId );
    } else {
      setSections([]);
    }
  }, [formData.branchId, formData.classId]);


const [states, setStates] = useState([]);
const [districts, setDistricts] = useState([]);

 useEffect(() => {
  setStates(StateCityData.states);
  if (StateCityData.states.length > 0) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: StateCityData.states[0].state, 
    }));
  }
}, []);

 const handleSelectChange = (selectedOption, { name }) => {
  if (name === 'state') {
    if (selectedOption) {
      const selectedState = states.find(
        (state) => state.state === selectedOption.value
      );
      setDistricts(selectedState ? selectedState.district : []);
      setFormData((prevFormData) => ({
        ...prevFormData,
        state: selectedOption.value,
        district: '',
      }));
    } else {
       setFormData((prevFormData) => ({
        ...prevFormData,
        state: '',
        district: '',
      }));
      setDistricts([]);  
    }
  } else if (name === 'district') {
    setFormData((prevFormData) => ({
      ...prevFormData,
      district: selectedOption ? selectedOption.value : '',
    }));
  }
};

  const [states2, setStates2] = useState([]);

  useEffect(() => {
     setStates2(StateCityData.states);
  }, [id]);

  const handleStateChange = (selectedOption, { name }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption ? selectedOption.value : '',  
    }));
  };

   const options = states2.map((state) => ({
    value: state.state,
    label: state.state,
  }));


  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === 'fatherState') {
      setFormData({ ...formData, state: value });  
    }

    if (name === 'state') {
      const selectedState = states.find(state => state.state === value);

       setDistricts(selectedState ? selectedState.district : []);

       setFormData({ ...formData, state: value, district: '' });

     } else if (name === 'district') {
       setFormData({ ...formData, district: value });
    }

    if (name === 'StudentID') {

    
     
      if (/^\d{0,19}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

         if (value === '') {
          setErrors({ ...errors, [name]: '' });  
        }
         else if (value.length === 19) {
          setErrors({ ...errors, [name]: '' });  
        } else {
          setErrors({ ...errors, [name]: 'Student ID must be 19 digits.'});
        }
      }
    }
   
    if (name === 'registerNo') {
      if (/^\d{0,16}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
       
        if (value.length > 15) {
          setErrors({ ...errors, [name]: 'Register Number must be 15 digits or less.' });
        } else {
          setErrors({ ...errors, [name]: '' });
        }
      }
    } else if (name === 'roll') {
       if (/^\d*$/.test(value)) {  
        if (value.length <= 10) {
           setFormData({ ...formData, [name]: value });
           setErrors({ ...errors, [name]: '' });
        } else {
           setErrors({ ...errors, [name]: 'Roll Number must be 10 characters or less.' });
        }
      } else {
         setErrors({ ...errors, [name]: 'Invalid input. Only numbers are allowed.' });
      }
    }

    else if (name === 'firstName') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: 'Each word in First Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: 'First Name must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'First Name must only contain letters, numbers, and spaces.',
        }));
      }
    } else if (name === 'lastName') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            lastName: 'Each word in Last Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            lastName: 'Last Name must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: 'Last Name must only contain letters, numbers, and spaces.',
        }));
      }
    } else if (name === 'motherTongue') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherTongue: 'Each word in MotherTongue must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherTongue: 'MotherTongue must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, motherTongue: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          motherTongue: 'MotherTongue must only contain letters, numbers, and spaces.',
        }));
      }
    }
    else if (name === 'religion') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            religion: 'Each word in Religion must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            religion: 'Religion must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, religion: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          religion: 'Religion must only contain letters and spaces.',
        }));
      }
    } else if (name === 'nationality') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            nationality: 'Each word in Nationality must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            nationality: 'Nationality must be less or equal to than 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, nationality: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nationality: 'Nationality must only contain letters and spaces.',
        }));
      }
    } else if (name === 'caste') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            caste: 'Each word in Caste must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            caste: 'Caste must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, caste: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          caste: 'Cast must only contain letters and spaces.',
        }));
      }
    }
    else if (name === 'aadhaarNo') {
       if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

         if (value === '') {
          setErrors({ ...errors, [name]: '' });  
        }
         else if (value.length === 12) {
          setErrors({ ...errors, [name]: '' });  
        } else {
          setErrors({ ...errors, [name]: 'Aadhar Number must be exactly 12 digits.' });
        }
      }
    }
    else if (name === 'mobileNumber') {
       if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

         if (value === '') {
          setErrors({ ...errors, [name]: '' });  
        }
         else if (value.length === 10) {
          setErrors({ ...errors, [name]: '' });  
        } else {
          setErrors({ ...errors, [name]: 'Phone Number must be exactly 10 digits.' });
        }
      }
    }
    else if (name === 'email') {
       if (value.trim().length > 2000) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          guardianEmail: 'Email must be less than or equal to 2000 characters.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, guardianEmail: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }


    else if (name === 'city') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            city: 'Each word in City Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            city: 'City Name must be less than 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, city: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          city: 'City Name must only contain letters and spaces.',
        }));
      }
    }

    else if (name === 'tahsil') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            tahsil: 'Each word in Tehsil Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            tahsil: 'Tehsil Name must be less than 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, tahsil: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          tahsil: 'Tehsil Name must only contain letters and spaces.',
        }));
      }
    }

    else if (name === 'address') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: 'Each word in Address must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: 'Address must be between 1 to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, address: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
  

    else if (name === 'fatherName') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherName: 'Each word in Father Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherName: 'Father Name must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, fatherName: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    } 
    
    else if (name === 'fatherrelation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);  
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherrelation: 'Each word in Relation must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherrelation: 'Relation must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, fatherrelation: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    }
    
    else if (name === 'fatheroccupation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatheroccupation: 'Each word in Occupation must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatheroccupation: 'Occupation must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, fatheroccupation: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    }
    
    else if (name === 'fatherincome') {
      if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        if (value.length < 12) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: ' Please Enter valid Income' });
        }
      }
    }
    
    else if (name === 'fathereducation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fathereducation: 'Each word in Education must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fathereducation: 'Education must be between 1 to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, fathereducation: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    
    else if (name === 'fatherEmail') {
       if (value.trim().length > 2000) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fatherEmail: 'Father Email must be less than or equal to 2000 characters.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, fatherEmail: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    
    else if (name === 'fatherCity') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherCity: 'Each word in City Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fatherCity: 'City Name must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, fatherCity: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fatherCity: 'City Name must only contain letters and spaces.',
        }));
      }
    }
    
    else if (name === 'fatherAddress') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fatherAddress: 'Each word in Address must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fatherAddress: 'Address must be less than or equal to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, fatherAddress: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
      else if (name === 'motherName') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherName: 'Each word in Mother Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherName: 'Mother Name must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, motherName: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    }
    
    else if (name === 'motherrelation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherrelation: 'Each word in Relation must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherrelation: 'Relation must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, motherrelation: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    }
    
    else if (name === 'motheroccupation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z0-9\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motheroccupation: 'Each word in Occupation must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motheroccupation: 'Occupation must be less than or equal to 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, motheroccupation: '' }));
          setFormData({ ...formData, [name]: value });
        }
      }
    }
    
    else if (name === 'motherincome') {
      if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        if (value.length < 12) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: 'Please Enter valid Income' });
        }
      }
    }
    
    else if (name === 'mothereducation') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mothereducation: 'Each word in Education must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mothereducation: 'Education must be less than or equal to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, mothereducation: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    
    else if (name === 'motherEmail') {
       if (value.trim().length > 2000) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          motherEmail: 'Mother Email must be less than or equal to 2000 characters.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, motherEmail: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    
    else if (name === 'motherCity') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
       if (/^[A-Za-z\s]*$/.test(value)) {
        if (isWordTooLong) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherCity: 'Each word in City Name must be less than 50 characters.',
          }));
        } else if (wordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            motherCity: 'City Name must be less than 40 words.',
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, motherCity: '' }));
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          motherCity: 'City Name must only contain letters and spaces.',
        }));
      }
    }
    
    else if (name === 'motherAddress') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;
    
      if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          motherAddress: 'Each word in Address must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          motherAddress: 'Address must be less than or equal to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, motherAddress: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    

    else if (name === 'previousSchoolName') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          previousSchoolName: 'Each word in School Name must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          previousSchoolName: 'School Name must be between 1 to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, previousSchoolName: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    else if (name === 'qualification') {
      const words = value.trim().split(/\s+/);
      const isWordTooLong = words.some((word) => word.length > 50);
      const wordCount = words.length;

       if (isWordTooLong) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          qualification: 'Each word in Qualification must be less than 50 characters.',
        }));
      } else if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          qualification: 'Qualification must be between 1 to 40 words.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, qualification: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    else if (name === 'remarks') {
       const characterCount = value.length;

       if (characterCount > 2000) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          remarks: 'Remarks must be less than or equal to 2000 characters.',
        }));
      } else {
         setErrors((prevErrors) => ({ ...prevErrors, remarks: '' }));
        setFormData({ ...formData, [name]: value });
      }
    }
    else if (name === 'fatherMobileNo') {
       if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

         if (value === '') {
          setErrors({ ...errors, [name]: '' });  
        }
         else if (value.length === 10) {
          setErrors({ ...errors, [name]: '' });  
        } else {
          setErrors({ ...errors, [name]: 'Phone Number must be exactly 10 digits.' });
        }
      }
    }

    else if (name === 'motherMobileNo') {
       if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

         if (value === '') {
          setErrors({ ...errors, [name]: '' });  
        }
         else if (value.length === 10) {
          setErrors({ ...errors, [name]: '' });  
        } else {
          setErrors({ ...errors, [name]: 'Phone Number must be exactly 10 digits.' });
        }
      }
    }
    else {
      setFormData({ ...formData, [name]: value });
    }

    if (name === 'sectionId') {
       const result = await Swal.fire({
        title: 'Change Section?',
        text: 'Changing the section will update the roll number. Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'No, cancel',
      });

      if (result.isConfirmed) {
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        fetchRollNumber(value);
        setInitialRollFetched(true);
      } else {
        e.target.value = formData.sectionId;
      }
    } 
  };


 

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === 'image') {
      const file = files[0];
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: file,
      }));

       if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    const selectedAcademicYear = academicYears.find((ay) => ay.year === formData.academicYear);
    const data = new FormData();

    if (selectedAcademicYear) {
      data.append('academicYear', selectedAcademicYear._id);
    }

    Object.keys(formData).forEach((key) => {
      if (key === "fatherState") {
         data.append(key, formData.fatherState || "");
      } else if (key === "district") {
         data.append(key, formData.district || "");
      } else {
         data.append(key, formData[key]);
}
});

    if (formData.registerNo && formData.registerNo.length > 15) {
      setLoading2(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        registerNo: 'Register Number must be 15 digits or less.',
      }));
      setLoading2(false);
      return;  
    }

     
   
    
      // Check if StudentID length exceeds 19 digits

      if(formData.StudentID)
        {
        if (formData.StudentID.toString().length < 19) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            StudentID: 'Student ID  must be 19 digits.',
          }));
           setLoading2(false);
          return;
        }
        }
    
    if (formData.roll && formData.roll.length > 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        roll: 'Roll Number must be 10 digits or less.',
      }));
      setLoading2(false);
      return; 
    }
    const FirstNameWordCount = formData.firstName.trim().split(/\s+/).length;
    if (FirstNameWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: 'First Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    const lastNameWordCount = formData.lastName.trim().split(/\s+/).length;
    if (lastNameWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: 'Last Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    const mtWordCount = formData.motherTongue.trim().split(/\s+/).length;
    if (mtWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherTongue: 'MotherTongue must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return; 
    }
    const religionWordCount = formData.religion.trim().split(/\s+/).length;
    if (religionWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        religion: 'religion must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return; 
    }
    const nationalityWordCount = formData.nationality.trim().split(/\s+/).length;
    if (nationalityWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationality: 'Nationality must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    const casteWordCount = formData.caste.trim().split(/\s+/).length;
    if (casteWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        caste: 'Caste must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    if (formData.aadhaarNo && formData.aadhaarNo.length !== 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaarNo: 'Aadhar Number must be exactly 12 digits.',
      }));
      setLoading2(false);
      return;  
    }
    if (formData.mobileNumber && formData.mobileNumber.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: 'Phone Number must be exactly 10 digits.',
      }));
      setLoading2(false);
      return;  
    }

    const cityWordCount = formData.city.trim().split(/\s+/).length;
    if (cityWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        city: 'City Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    const tahsilWordCount = formData.tahsil.trim().split(/\s+/).length;
    if (tahsilWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tahsil: 'Tahsil Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return; 
    }
    const addressWordCount = formData.address.trim().split(/\s+/).length;
    if (addressWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: 'Address Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return; 
    }
    const fatherNameWordCount = formData.fatherName.trim().split(/\s+/).length;
    if (fatherNameWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherName: 'Father Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

    const relation = formData.fatherrelation ? formData.fatherrelation.trim() : '';
    const relationWordCount = relation.split(/\s+/).length;
    if (relationWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherrelation: 'Relation must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
     const occupation = formData.fatheroccupation ? formData.fatheroccupation.trim() : '';
    const occupationWordCount = occupation.split(/\s+/).length;
    if (occupationWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatheroccupation: 'occupation must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    if (formData.fatherincome && formData.fatherincome.length > 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherincome: 'Enter Valid Income',
      }));
      setLoading2(false);
      return; 
    }
     const education = formData.fathereducation ? formData.fathereducation.trim() : '';
    const educationWordCount = education.split(/\s+/).length;
    if (educationWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fathereducation: 'Education must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

     const fatherCity = formData.fatherCity ? formData.fatherCity.trim() : '';
    const fatherCityWordCount = fatherCity.split(/\s+/).length;
    if (fatherCityWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherCity: 'City must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
     const fatherAddress = formData.fatherAddress ? formData.fatherAddress.trim() : '';
    const fatherAddressWordCount = fatherAddress.split(/\s+/).length;
    if (fatherAddressWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherAddress: 'Address must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

     const motherName = formData.motherName ? formData.motherName.trim() : '';
    const motherNameWordCount = motherName.split(/\s+/).length;
    if (motherNameWordCount > 40 || motherNameWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherName: 'Mother Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

    // For motherrelation
    const motherrelation = formData.motherrelation ? formData.motherrelation.trim() : '';
    const motherrelationWordCount = motherrelation.split(/\s+/).length;
    if (motherrelationWordCount > 40 || motherrelationWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherrelation: 'Relation must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return; 
    }

     const motheroccupation = formData.motheroccupation ? formData.motheroccupation.trim() : '';
    const motheroccupationWordCount = motheroccupation.split(/\s+/).length;
    if (motheroccupationWordCount > 40 || motheroccupationWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motheroccupation: 'Occupation must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

     if (formData.motherincome && formData.motherincome.length > 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherincome: 'Enter Valid Income',
      }));
      setLoading2(false);
      return;  
    }

     const mothereducation = formData.mothereducation ? formData.mothereducation.trim() : '';
    const mothereducationWordCount = mothereducation.split(/\s+/).length;
    if (mothereducationWordCount > 40 || mothereducationWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mothereducation: 'Education must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

     const motherCity = formData.motherCity ? formData.motherCity.trim() : '';
    const motherCityWordCount = motherCity.split(/\s+/).length;
    if (motherCityWordCount > 40 || motherCityWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherCity: 'City must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }

     const motherAddress = formData.motherAddress ? formData.motherAddress.trim() : '';
    const motherAddressWordCount = motherAddress.split(/\s+/).length;
    if (motherAddressWordCount > 40 || motherAddressWordCount < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherAddress: 'Address must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }



    const previousSchoolNameWordCount = formData.previousSchoolName.trim().split(/\s+/).length;
    if (previousSchoolNameWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        previousSchoolName: 'School Name must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    const qualificationWordCount = formData.qualification.trim().split(/\s+/).length;
    if (qualificationWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        qualification: 'Qualification must be between 1 to 40 words.',
      }));
      setLoading2(false);
      return;  
    }
    if (formData.fatherMobileNo && formData.fatherMobileNo.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fatherMobileNo: 'Phone Number must be exactly 10 digits.',
      }));
      setLoading2(false);
      return;  
    }
    if (formData.motherMobileNo && formData.motherMobileNo.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        motherMobileNo: 'Phone Number must be exactly 10 digits.',
      }));
      setLoading2(false);
      return;  
    }



    try {
       const response = await axiosInstance.put(`/updatestudent/${id}`, data);

      navigate('/students');
    } catch (error) {
      console.error('Error updating student:', error.response || error);
    }
    setLoading2(false);
  };



  const handleCancel = () => {
    navigate('/students');
  };

  const handleCopyAddress = (event) => {
    event.preventDefault();
    setFormData((prev) => ({
      ...prev,
      fatherState: prev.state,
      fatherCity: prev.city,
      fatherAddress: prev.address,
      motherState: prev.state,
      motherCity: prev.city,
      motherAddress: prev.address,
    }));
  };


  
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis', 
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2' 
        : '#FFFFFF', 
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };

  return (
    <Card>
      <div className="d-flex justify-content-between align-items-center px-3 pt-3">
        <CardTitle tag="h4">Edit Student Details</CardTitle>
      </div>
      <hr className='mt-1 mb-0' />
      <Form onSubmit={handleSubmit}>
        <Row className='mx-0' >
          <div style={{ backgroundColor: "#f8f9fa" }} className='py-2 px-3 mb-4'>
            <CardTitle className='mb-0 mx-1' style={{ color: "#777" }} tag="h5">Academic Details</CardTitle>
          </div>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="academicYear">
                <span style={{ fontWeight: "bold" }}>Academic Year*</span>
              </Label>
              <Select
                id="academicYear"
                value={academicYearOptions.find(option => option.value === formData.academicYear)}
                onChange={(selectedOption) => {
                  handleChange({ target: { name: 'academicYear', value: selectedOption ? selectedOption.value : '' } });
                }}
                options={academicYearOptions}
                isClearable
                placeholder="Select Academic Year"
                isDisabled={academicYearOptions.length === 0}  
                styles={customStyles}  
                required
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="registerNo"><span style={{ fontWeight: "bold" }}>Register Number*</span></Label>
              <Input
                type="text"
                name="registerNo"
                id="registerNo"
                value={formData.registerNo}
                onChange={handleChange}
                required
                maxLength="16"  
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');  
                }}
              />
              {errors.registerNo && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.registerNo}
                </div>
              )}
            </FormGroup>
          </Col>

           <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="StudentID"><span style={{ fontWeight: "bold" }}>Student ID</span></Label>
              <Input
                type="text"
                name="StudentID"
                id="StudentID"
                value={formData.StudentID}
                onChange={handleChange}
                
                maxLength="19"  
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');  
                }}
              />
              {errors.StudentID && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.StudentID}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="branchId">
                <span style={{ fontWeight: 'bold' }}>Branch*</span>
              </Label>
              <Select
                id="branchId"
                value={branches.find((branch) => branch.value === formData.branchId)}
                onChange={(selectedOption) =>{
                  const newBranchId = selectedOption ? selectedOption.value : '';
                  handleChange({
                    target: {
                      name: 'branchId',
                      value: newBranchId, 
                    },
                  })
                  if (newBranchId) {
                    fetchClasses(newBranchId);
                  } else {
                    setClasses([]);
                  }
                  if (newBranchId !== formData.branchId) {
                    setFormData((prevData) => ({
                      ...prevData,
                      classId:'',
                      sectionId: '', 
                      roll: ''
                    }));
                  }
                }
              }
                options={branches}
                isClearable
                placeholder="Select a Branch"
                styles={customStyles}
                required
              
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="classId">
                <span style={{ fontWeight: 'bold' }}>Class*</span>
              </Label>
              <Select
                id="classId"
                value={classes.find((cls) => cls.value === formData.classId) || null }
                onChange={(selectedOption) =>{
                  const newClassId = selectedOption ? selectedOption.value : '';
                  handleChange({
                    target: {
                      name: 'classId',
                      value: newClassId, 
                    },
                  });
                  if (newClassId !== formData.classId) {
                    setFormData((prevData) => ({
                      ...prevData,
                      sectionId: '', 
                      roll: ''
                    }));
                  }
                  if (newClassId) {
                    fetchSections(formData.branchId, newClassId);
                  } else {
                    setSections([]); 
                  }

                }
              } 
                options={classes}
                isClearable
                placeholder="Select a Class"
                styles={customStyles}
                required
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="sectionId">
                <span style={{ fontWeight: 'bold' }}>Section*</span>
              </Label>
              <Select
                id="sectionId"
                 value={sections.find((section) => section.value === formData.sectionId) || null} 
                onChange={(selectedOption) =>
                  handleChange({
                    target: {
                      name: 'sectionId',
                      value: selectedOption ? selectedOption.value : '',  
                    },
                  })
                }
                options={sections}
                isClearable
                placeholder={sections.length === 0 ? 'No Section' : 'Select a Section'}
                isDisabled={sections.length === 0}
                styles={customStyles}
                required={sections.length > 0}
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="roll"><span style={{ fontWeight: "bold" }}>Roll Number*</span></Label>
              <Input
                type="text"
                name="roll"
                id="roll"
                value={formData.roll}
                onChange={handleChange}  
                required
                maxLength="11" 
              />
              {errors.roll && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.roll}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="admissionDate"><span style={{ fontWeight: "bold" }}>Admission Date*</span></Label>
              <Input
                type="date"
                name="admissionDate"
                id="admissionDate"
                placeholder="Enter admission date"
                value={formData.admissionDate}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <div style={{ backgroundColor: "#f8f9fa" }} className='mt-2 py-2 px-3 mb-4'>
            <CardTitle className='mb-0 mx-1' style={{ color: "#777" }} tag="h5">Student Details</CardTitle>
          </div>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="firstName"><span style={{ fontWeight: "bold" }}>First Name*</span></Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}

                required
              />
              {errors.firstName && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.firstName}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3} >
            <FormGroup>
              <Label for="lastName"><span style={{ fontWeight: "bold" }}>Last Name*</span></Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}

                required
              />
              {errors.lastName && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.lastName}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="gender">
                <span style={{ fontWeight: "bold" }}>Gender*</span>
              </Label>
              <Select
                id="gender"
                value={genderOptions.find(option => option.value === formData.gender)}
                onChange={(selectedOption) => {
                  handleChange({ target: { name: 'gender', value: selectedOption ? selectedOption.value : '' } });
                }}
                options={genderOptions}
                isClearable
                placeholder="Select Gender"
                styles={customStyles} 
                required 
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="bloodGroup">
                <span style={{ fontWeight: "bold" }}>Blood Group</span>
              </Label>
              <Select
                id="bloodGroup"
                value={bloodGroupOptions.find(option => option.value === formData.bloodGroup)}
                onChange={(selectedOption) => {
                  handleChange({ target: { name: 'bloodGroup', value: selectedOption ? selectedOption.value : '' } });
                }}
                options={bloodGroupOptions}
                isClearable
                placeholder="Select Blood Group"
                styles={customStyles} 
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="dateOfBirth"><span style={{ fontWeight: "bold" }}>Date Of Birth</span></Label>
              <Input
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}

              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherTongue"><span style={{ fontWeight: "bold" }}>Mother Tongue</span></Label>
              <Input
                type="text"
                name="motherTongue"
                id="motherTongue"
                value={formData.motherTongue}
                onChange={handleChange}

              />
              {errors.motherTongue && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherTongue}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="religion"><span style={{ fontWeight: "bold" }}>Religion</span></Label>
              <Input
                type="text"
                name="religion"
                id="religion"
                value={formData.religion}
                onChange={handleChange}

              />
              {errors.religion && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.religion}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="nationality"><span style={{ fontWeight: "bold" }}>Nationality</span></Label>
              <Input
                type="text"
                name="nationality"
                id="nationality"
                value={formData.nationality}
                onChange={handleChange}

              />
              {errors.nationality && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.nationality}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="category">
                <span style={{ fontWeight: "bold" }}>Category</span>
              </Label>
              <Select
                id="category"
                value={categoryOptions.find(option => option.value === formData.category)}
                onChange={(selectedOption) => {
                  handleChange({ target: { name: 'category', value: selectedOption ? selectedOption.value : '' } });
                }}
                options={categoryOptions}
                isClearable
                placeholder="Select Category"
                styles={customStyles} 
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="caste"><span style={{ fontWeight: "bold" }}>Caste</span></Label>
              <Input
                type="text"
                name="caste"
                id="caste"
                value={formData.caste}
                onChange={handleChange}

              />
              {errors.caste && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.caste}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="aadhaarNo"><span style={{ fontWeight: "bold" }}>Aadhaar No(UID NO)</span></Label>
              <Input
                type="text"
                name="aadhaarNo"
                id="aadhaarNo"
                value={formData.aadhaarNo}
                onChange={handleChange}
                inputMode="numeric"
                maxLength="12" 
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');  
                }}
              />
              {errors.aadhaarNo && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.aadhaarNo}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="mobileNumber"><span style={{ fontWeight: "bold" }}>Mobile Number*</span></Label>
              <Input
                type="text"
                name="mobileNumber"
                id="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                inputMode="numeric"
                maxLength="10" 
                required
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');  
                }}
              />
              {errors.mobileNumber && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.mobileNumber}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="email"><span style={{ fontWeight: "bold" }}>Email</span></Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
            
              />
              {errors.email && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.email}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="state" className="fw-bold">
                State
              </Label>
              <Select
                name="state"
                options={states.map((state) => ({
                  value: state.state,
                  label: state.state,
                }))}
                value={
                  formData.state
                    ? { value: formData.state, label: formData.state }
                    : null
                }
                onChange={handleSelectChange}
                isClearable
                isSearchable
                placeholder="Select a State"
                styles={customStyles}
              />
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="district" className="fw-bold">
                District
              </Label>
              <Select
                name="district"
                options={districts.map((district) => ({
                  value: district,
                  label: district,
                }))}
                value={
                  formData.district
                    ? { value: formData.district, label: formData.district }
                    : null
                }
                onChange={handleSelectChange}
                isClearable
                isSearchable
                placeholder="Select a District"
                styles={customStyles}
              />
              {errors.district && (
                <div className="error-message">{errors.district}</div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="city"><span style={{ fontWeight: "bold" }}>City/Town/Village</span></Label>
              <Input
                type="text"
                name="city"
                id="city"
                value={formData.city}
                onChange={handleChange}

              />
              {errors.city && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.city}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="tahsil"><span style={{ fontWeight: "bold" }}>Tahsil</span></Label>
              <Input
                type="text"
                name="tahsil"
                id="tahsil"
                value={formData.tahsil}
                onChange={handleChange}

              />
              {errors.tahsil && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.tahsil}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="permanentAddress"><span style={{ fontWeight: "bold" }}>Address</span></Label>
              <Input
                type="textarea"
                name="address"
                id="address"
                value={formData.address}
                onChange={handleChange}

              />
              {errors.address && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.address}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="image"><span style={{ fontWeight: "bold" }}>Upload Student Photo</span></Label>
              <Input
                type="file"
                name="image"
                id="image"
                onChange={handleFileChange}
                accept="image/jpeg, image/png, image/jpg"
              />
              <span style={{ fontSize: "12px", color: "#d83030" }}>Recommended Photo Size: 413 x 531 pixels (Maximum Upload Limit: 5MB)</span>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <div className="d-flex flex-column align-items-start mt-0">
              {imagePreview && (
                <>
                  <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    style={{ width: '95px', height: '122px', objectFit: 'cover', }}
                  />
                </>
              )}
            </div>
          </Col>




          <div style={{ backgroundColor: "#f8f9fa" }} className='mt-2 px-3 py-2 mb-4 d-flex'>
            <CardTitle className='mb-0 mx-sm-1 mt-2' style={{ color: "#777" }} tag="h5">Guardian's Details</CardTitle>
            <button className='btn btn-outline-danger ms-md-5 ms-4' onClick={handleCopyAddress} style={{ color: '#FB0F01', borderColor: '#FB0F01' }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#FB0F01';  
                e.currentTarget.style.color = 'white'; 
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';  
                e.currentTarget.style.color = '#FB0F01'; 

              }}>
              Copy address
            </button>


          </div>





          <CardTitle className='mb-0  mb-2' style={{ color: "black" }} tag="h5">Father's Details</CardTitle>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherName"><span style={{ fontWeight: "bold" }}>Father's First Name</span></Label>
              <Input
                type="text"
                name="fatherName"
                id="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
              />
              {errors.fatherName && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherName}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherrelation"><span style={{ fontWeight: "bold" }}>Student's Relation</span></Label>
              <Input
                type="text"
                name="fatherrelation"
                id="fatherrelation"
                value={formData.fatherrelation}
                onChange={handleChange}
              />
              {errors.fatherrelation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherrelation}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatheroccupation"><span style={{ fontWeight: "bold" }}>Occupation</span></Label>
              <Input
                type="text"
                name="fatheroccupation"
                id="fatheroccupation"
                value={formData.fatheroccupation}
                onChange={handleChange}
              />
              {errors.fatheroccupation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatheroccupation}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherincome"><span style={{ fontWeight: "bold" }}>Annual Income</span></Label>
              <Input
                type="text"
                name="fatherincome"
                id="fatherincome"
                value={formData.fatherincome}
                onChange={handleChange}
                maxLength="12"  
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                }}
              />
              {errors.fatherincome && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherincome}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fathereducation"><span style={{ fontWeight: "bold" }}>Education</span></Label>
              <Input
                type="text"
                name="fathereducation"
                id="fathereducation"
                value={formData.fathereducation}
                onChange={handleChange}
              />
              {errors.fathereducation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fathereducation}
                </div>
              )}
            </FormGroup>
          </Col>


          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherMobileNo"><span style={{ fontWeight: "bold" }}>Father's Mobile Number</span></Label>
              <Input
                type="text"
                name="fatherMobileNo"
                id="fatherMobileNo"
                value={formData.fatherMobileNo}
                onChange={handleChange}
                inputMode="numeric"
                maxLength="10"  
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
              {errors.fatherMobileNo && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherMobileNo}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherEmail"><span style={{ fontWeight: "bold" }}>Father's Email</span></Label>
              <Input
                type="email"
                name="fatherEmail"
                id="fatherEmail"
                value={formData.fatherEmail}
                onChange={handleChange}
              />
              {errors.fatherEmail && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherEmail}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherState" className="fw-bold">
                Father's State
              </Label>
              <Select
                name="fatherState"
                id="fatherState"
                value={
                  options.find(
                    (option) => option.value === formData.fatherState
                  ) || null
                }
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    fatherState: selectedOption ? selectedOption.value : "",
                  })
                }
                options={options}
                isClearable  
                placeholder="Select Father's State"
                styles={customStyles}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherCity"><span style={{ fontWeight: "bold" }}>Father's City/Town/Village </span></Label>
              <Input
                type="text"
                name="fatherCity"
                id="fatherCity"
                value={formData.fatherCity}
                onChange={handleChange}

              />
              {errors.fatherCity && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherCity}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="fatherAddress"><span style={{ fontWeight: "bold" }}>Father's Address</span></Label>
              <Input
                type="textarea"
                name="fatherAddress"
                id="fatherAddress"
                value={formData.fatherAddress}
                onChange={handleChange}
              />
              {errors.fatherAddress && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.fatherAddress}
                </div>
              )}
            </FormGroup>
          </Col>



          <CardTitle className='mb-0  mb-2' style={{ color: "black" }} tag="h5">Mother's Details</CardTitle>

          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherName"><span style={{ fontWeight: "bold" }}>Mother's First Name</span></Label>
              <Input
                type="text"
                name="motherName"
                id="motherName"
                value={formData.motherName}
                onChange={handleChange}
              />
              {errors.motherName && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherName}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherrelation"><span style={{ fontWeight: "bold" }}>Student's Relation</span></Label>
              <Input
                type="text"
                name="motherrelation"
                id="motherrelation"
                value={formData.motherrelation}
                onChange={handleChange}
              />
              {errors.motherrelation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherrelation}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motheroccupation"><span style={{ fontWeight: "bold" }}>Occupation</span></Label>
              <Input
                type="text"
                name="motheroccupation"
                id="motheroccupation"
                value={formData.motheroccupation}
                onChange={handleChange}
              />
              {errors.motheroccupation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motheroccupation}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherincome"><span style={{ fontWeight: "bold" }}>Annual Income</span></Label>
              <Input
                type="text"
                name="motherincome"
                id="motherincome"
                value={formData.motherincome}
                onChange={handleChange}
                maxLength="12"  
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');  
                }}
              />
              {errors.motherincome && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherincome}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="mothereducation"><span style={{ fontWeight: "bold" }}>Education</span></Label>
              <Input
                type="text"
                name="mothereducation"
                id="mothereducation"
                value={formData.mothereducation}
                onChange={handleChange}
              />
              {errors.mothereducation && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.mothereducation}
                </div>
              )}
            </FormGroup>
          </Col>


          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherMobileNo"><span style={{ fontWeight: "bold" }}>Mother's Mobile Number</span></Label>
              <Input
                type="text"
                name="motherMobileNo"
                id="motherMobileNo"
                value={formData.motherMobileNo}
                onChange={handleChange}
                inputMode="numeric"
                maxLength="10"  

                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                }}
              />
              {errors.motherMobileNo && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherMobileNo}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherEmail"><span style={{ fontWeight: "bold" }}>Mother's Email</span></Label>
              <Input
                type="email"
                name="motherEmail"
                id="motherEmail"
                value={formData.motherEmail}
                onChange={handleChange}
              />
              {errors.motherEmail && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherEmail}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
        <FormGroup>
          <Label for="motherState" className="fw-bold">Mother's State</Label>
          <Select
            name="motherState"
            id="motherState"
            value={options.find(option => option.value === formData.motherState) || null} 
            onChange={handleStateChange}  
            options={options}  
            isClearable  
            placeholder="Select Mother's State"  
            styles={customStyles}
          />
        </FormGroup>
      </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherCity"><span style={{ fontWeight: "bold" }}>Mother's City/Town/Village </span></Label>
              <Input
                type="text"
                name="motherCity"
                id="motherCity"
                value={formData.motherCity}
                onChange={handleChange}

              />
              {errors.motherCity && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherCity}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="motherAddress"><span style={{ fontWeight: "bold" }}>Mother's Address</span></Label>
              <Input
                type="textarea"
                name="motherAddress"
                id="motherAddress"
                value={formData.motherAddress}
                onChange={handleChange}
              />
              {errors.motherAddress && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.motherAddress}
                </div>
              )}
            </FormGroup>
          </Col>


          <div style={{ backgroundColor: "#f8f9fa" }} className='mt-2 py-2 px-3 mb-4'>
            <CardTitle className='mb-0 mx-1' style={{ color: "#777" }} tag="h5">Previous School Details</CardTitle>
          </div>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="previousSchoolName"><span style={{ fontWeight: "bold" }}>Previous School Name</span></Label>
              <Input
                type="text"
                name="previousSchoolName"
                id="previousSchoolName"
                value={formData.previousSchoolName}
                onChange={handleChange}

              />
              {errors.previousSchoolName && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.previousSchoolName}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="qualification"><span style={{ fontWeight: "bold" }}>Qualification</span></Label>
              <Input
                type="text"
                name="qualification"
                id="qualification"
                value={formData.qualification}
                onChange={handleChange}

              />
              {errors.qualification && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.qualification}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={4}  xl={3}>
            <FormGroup>
              <Label for="remarks"><span style={{ fontWeight: "bold" }}>Remarks</span></Label>
              <Input
                type="textarea"
                name="remarks"
                id="remarks"
                value={formData.remarks}
                onChange={handleChange}

              />
              {errors.remarks && (
                <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                  {errors.remarks}
                </div>
              )}
            </FormGroup>
          </Col>

        </Row>
        <div className="d-flex justify-content-center my-3">
          <Button className="btn-no-radius" color="primary" type="submit" disabled={loading2}>
          {loading2 ? <Spinner size="sm" /> : "Save"}
          </Button>
          <Button color="secondary" type="button" onClick={handleCancel} className="btn-no-radius mx-2" disabled={loading2}>
            Cancel
          </Button>
        </div>
      </Form>
    </Card >
  );
};

export default EditStudent;























































































































































