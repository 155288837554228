import React, {useState, useEffect} from 'react';
import { DropdownMenu, DropdownItem, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import axiosInstance from '../middleware/axiosInstance';

const ProfileCard = ({ toggle, handleLogOut}) => {
  const [orgData, setOrgData] = useState(null);

  const getInitial = (name) => name.charAt(0).toUpperCase();

  const fetchOrganizationplan = async () => {
    try {
      const response = await axiosInstance.get('/details/orgplan');
      if (response.data) {
        setOrgData(response.data)
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationplan(); 
  }, [toggle]); 


  return (
    <DropdownMenu className="px-4 pb-4 pt-3 rounded-0" style={{ width: '320px' }}>
      <ModalHeader toggle={toggle} className="">
        <span className="fw-lighter fs-5">Profile</span>
      </ModalHeader>
      <hr className='p-0 mt-2'></hr>
      <ModalBody>
        <Row>
          <Col xs="2" className="d-flex p-0 justify-content-end">
          <div
              className="rounded-circle mt-1"
              style={{
                width: '25px',
                height: '25px',
                backgroundColor: '#fb0f01',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '19px',
                fontWeight: 'bold',
                padding:'19px'
              }}
            >
              {getInitial(orgData?.name || '')}
            </div>
          </Col>
          <Col xs="10" className="d-flex flex-column ps-2">
            <h5 style={{ margin: 0, wordWrap: 'break-word'}}> {orgData?.name || ' '}</h5>
            <p style={{ margin: 0, fontSize: '14px', wordWrap: 'break-word' }}>
            {orgData?.email || " "}
            </p>
            <p style={{ margin: 0, fontSize: '15px' }} className="fw-bold mt-2">
              Organization:
            </p>
            <p style={{ margin: 0, fontSize: '14px', wordWrap: 'break-word' }}> {orgData?.organizationName ||  " "}</p>
            <p style={{ margin: 0, fontSize: '15px' }} className="fw-bold mt-2">
              License type:
            </p>
            <p style={{ margin: 0, fontSize: '14px', wordWrap: 'break-word' }}> {orgData?.license  ||   " "}</p>
            <p style={{ margin: 0, fontSize: '15px' }} className="fw-bold mt-2">
              Trial status:
            </p>
            <p style={{ margin: 0, fontSize: '14px', wordWrap: 'break-word' }}> {orgData?.licenseExpiry || " "}</p>
          </Col>
        </Row>
      </ModalBody>
      <hr />
      <ModalFooter>
        <DropdownItem
          onClick={handleLogOut}
          className="py-2 text-center"
          style={{
            backgroundColor: '#EDEDED',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#FB0F01'; 
            e.target.style.color = 'white'; 
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#EDEDED';
            e.target.style.color = 'black';
          }}
        >
          Logout
        </DropdownItem>
      </ModalFooter>
    </DropdownMenu>
  );
};

export default ProfileCard;
