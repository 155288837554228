import React, { useEffect, useState } from 'react';
import { Card, CardTitle, Table,Button,Spinner } from 'reactstrap';
import axiosInstance from '../../../middleware/axiosInstance';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const ShowOrganizations = () => {

  const navigate = useNavigate();

  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchOrganization = async () => {
    try {
      const response = await axiosInstance.get('/admindashboard');
      if (response.data) {
        setOrganization(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
    }finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchOrganization();
  }, []); 




const formatDate = (dateString) => {
  return format(new Date(dateString), 'MMMM dd, yyyy');
};


const handleEdit = (id)=>{
  navigate(`/edit/organization/${id}`)
}

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center center align-items-center p-4" style={{ minHeight: '90vh' }}>
          <Spinner color="primary" />
        </div>
      ) : (
        <>
            <Card  style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}>
          
          <CardTitle tag="h4" className="p-2 mt-1 mb-1 ps-3 mb-0">
            Organization
          </CardTitle>
         
          </Card>
          <Card  className=" px-3 pt-0" style={{ borderRadius: '0', minHeight: '400px'}}>
          {organization ? (
                  <div style={{ overflowX: 'auto' }}>
                    <Table bordered striped responsive className='mt-4 mb-0'>
                      <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }}>
                        <tr>
                          <th>Organization Name</th>
                          <th>Admin Name</th>
                          <th>Subscription Created Date</th>
                          {organization.orgEstablishmentDate && <th>Establishment Date</th>}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={organization._id}>
                          <td>{organization.organization}</td>
                          <td>{organization.name}</td>
                          <td>{formatDate(organization.createdAt)}</td>
                          {organization.orgEstablishmentDate && <td>{formatDate(organization.orgEstablishmentDate)}</td>}
                          <td>
                            <Button onClick={()=>{handleEdit(organization._id)}} className="btn-no-radius responsive-button" color="primary">Edit</Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <style jsx="true">{`
                    table td, table th {
                      white-space: nowrap; 
                    }
                        .responsive-button {
                      padding: 8px 16px; 
                      font-size: 16px; 
                   }
                    @media (max-width: 768px) {
                      table td, table th {
                        padding: 5px;
                      }
                         .responsive-button {
                        padding: 5px 11px; 
                        font-size: 13px;   
                      }
                    }

                    @media (max-width: 576px) {
                      table td, table th {
                      }
                         .responsive-button {
                          padding: 5px 11px; 
                          font-size: 10px;   
          }
                    }
                  `}</style>
                </div>
          ) : (
            <p className="p-4">No organization data found</p>
          )}
          </Card>
        </>
      )}
    </>

  );
};

export default ShowOrganizations;


