import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  Table,
  Row,
  Col,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FaFileAlt } from "react-icons/fa";

import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Swal from "sweetalert2";
import Select from "react-select";

const Exams = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    branchId: "",
    classId: "",
    academicYearId: "",
  });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [exams, setExams] = useState([]);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [overviewData, setOverviewData] = useState(false);
  const [examId, setExamId] = useState(null);
  const [examName, setExamName] = useState("");

  const toggleModal = (exam_id) => {
    setExamId(exam_id);
    setIsModalOpen(!isModalOpen);
  };


  const handleDownload = async type => {

    const responsepdf = await axiosInstance.get(
      `exam/generatereTimeTable/${examId}`

    )

    const buffer = responsepdf.data.buffer
    const byteArray = new Uint8Array(buffer.data)
    const blob = new Blob([byteArray], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${examName || "Exam"}_Timetable.pdf`
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  useEffect(() => {
    const fetchExamOverview = async () => {

      if (!examId) return;
      try {
        const response = await axiosInstance.get(
          `exam/fetch/examoverview/${examId}`
        );

        setOverviewData(response.data.timetable);
        setExamName(response.data.examName);
      } catch (error) {
        // console.error("Error fetching exam overview:", error);
        // setError("Failed to fetch exam overview data.");
        setOverviewData([]);
        setExamName('');
      }
    };

    if (isModalOpen && examId) {
      fetchExamOverview();
    }
  }, [isModalOpen, examId]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        setError("Failed to fetch branches.");
      }
    };
    fetchBranches();
  }, []);

  // Reusable function to fetch classes
  const fetchClasses = async (branchId) => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId },
      });
      setClasses(response.data);
    } catch (error) {
      setError("Failed to fetch classes.");
    }
  };

  // Fetch classes when branchId changes
  useEffect(() => {
    if (formData.branchId) {
      fetchClasses(formData.branchId);
    }
  }, [formData.branchId]);


  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
            },
          });
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setSections(sortedSections);
        } catch (error) {
          setError("Failed to fetch sections.");
        }
      } else {
        setSections([]);
      }
    };
    fetchSections();
  }, [formData.branchId, formData.classId]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        const sortedAcademicYears = response.data.sort((a, b) => {
          // Extract starting years from "year" (e.g., "2024-25" => 2024)
          const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);
  
          // Sort based on the starting year
          return startYearA - startYearB;
        });
  
        setAcademicYears(sortedAcademicYears);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;
        if (currentMonth > 3) {

          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

        const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear
        );



        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      } finally {
        setLoading(false);
      }
    };

    getAcademicYears();
  }, []);


  useEffect(() => {
    const fetchExams = async () => {
      try {
        let response;
        if (
          formData.branchId ||
          formData.academicYearId ||
          formData.classId ||
          formData.sectionId
        ) {
          response = await axiosInstance.get("/exam/exams", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId || null,
              sectionId: formData.sectionId || null,
              academicYearId: formData.academicYearId || null,
            },
          });
        } else {
          response = await axiosInstance.get("/exam/exams");
        }
        setExams(response.data);
        setNoDataFound(response.data.length === 0);
      } catch (error) {
        setError("Failed to fetch exams.");
      } finally {
        setLoading(false);
      }
    };

    fetchExams();
  }, [
    formData.branchId,
    formData.classId,
    formData.sectionId,
    formData.academicYearId,
  ]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "branchId") {
      setClasses([]);
      setNoDataFound(false);
      if (value) {
        // Fetch classes only if branchId is not empty
        fetchClasses(value);
      }
      setFormData((prev) => ({ ...prev, classId: "" }));
    } else if (name === "classId" || name === "academicYearId") {
      setNoDataFound(false);
    }
  };

  const showResult = (id) => {
    navigate(`/examsstudents/${id}`);
  };



  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const renderTable = () => (
    <div>
      <div className="px-0 pb-3">
        <CardTitle tag="h4" className="mt-3 mb-1">
          Exam List
        </CardTitle>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>Exam Name</th>
              <th>Exam Types</th>
              <th>Branch</th>
              <th>Class</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exams.length
              ? exams.map((examItem) => (
                <tr key={examItem._id}>
                  <td>{examItem.examName}</td>
                  <td>
                    {examItem.examtype_id && examItem.examtype_id.length > 0
                      ? examItem.examtype_id
                        .map((val) => val.examType)
                        .join(", ")
                      : "Written"}
                  </td>
                  <td>{examItem.branch_id.branchName}</td>
                  <td>
                    {examItem.class_id?.className}{" "}
                    {examItem.section_id && examItem.section_id.length > 0
                      ? ` (${examItem.section_id
                        .map((val) => val.sectionName)
                        .join(", ")})`
                      : ""}
                  </td>
                  <td>{examItem.academicyear_id.year}</td>
                  <td>
                    <Button
                      className="btn-no-radius mx-2 responsive-button"
                      color="primary"
                      onClick={() => showResult(examItem._id)}
                    >
                      Result
                    </Button>

                    <Button
                      className="btn-no-radius mx-2 responsive-button"
                      color="primary"
                      onClick={() => toggleModal(examItem._id)}
                    >
                      Overview
                    </Button>
                    <NavLink to={`/examsoverview/setting/${examItem._id}`}>
                      <Button
                        className="btn-no-radius mx-2 responsive-button"
                        color="primary"
                      >
                        Setting
                      </Button>
                    </NavLink>
                  </td>
                </tr>
              ))
              : noDataFound && (
                <tr>
                  <td rowSpan="4" colSpan="6" className="text-center">
                    <FaFileAlt className="mt-3" size={70} color="#888" />
                    <h4 className="alert-heading mb-3 mt-3">
                      No Exam Overview Found
                    </h4>
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center p-4"
          style={{ minHeight: "90vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
            }}
          >
            <div className="d-flex justify-content-between align-items-center px-3 pt-1">
              <CardTitle className="mt-2" tag="h4">
                Exam Overview
              </CardTitle>
            </div>
            <hr className="mt-1" />
            <Row className="m-0 p-0 ">
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYear">
                    <span style={{ fontWeight: "bold" }}>Academic Year</span>
                  </Label>
                  <Select
                    id="academicYear"
                    options={academicYears.map((year) => ({
                      value: year._id,
                      label: year.year,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "academicYearId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      academicYears.find(
                        (year) => year._id === formData.academicYearId
                      )
                        ? {
                          value: formData.academicYearId,
                          label: academicYears.find(
                            (year) => year._id === formData.academicYearId
                          ).year,
                        }
                        : null
                    }
                    placeholder="Select Academic Year"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch</span>
                  </Label>
                  <Select
                    className=""
                    id="branch"
                    options={branches.map((branch) => ({
                      value: branch._id,
                      label: branch.branchName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "branchId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      branches.find(
                        (branch) => branch._id === formData.branchId
                      )
                        ? {
                          value: formData.branchId,
                          label: branches.find(
                            (branch) => branch._id === formData.branchId
                          ).branchName,
                        }
                        : null
                    }
                    placeholder="Select a Branch"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class</span>
                  </Label>
                  <Select
                    id="class"
                    options={classes.map((classItem) => ({
                      value: classItem._id,
                      label: classItem.className,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "classId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      classes.find(
                        (classItem) => classItem._id === formData.classId
                      )
                        ? {
                          value: formData.classId,
                          label: classes.find(
                            (classItem) => classItem._id === formData.classId
                          ).className,
                        }
                        : null
                    }
                    placeholder="Select a Class"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="section">
                    <span style={{ fontWeight: "bold" }}>Section</span>
                  </Label>
                  <Select
                    id="section"
                    options={sections.map((section) => ({
                      value: section._id,
                      label: section.sectionName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "sectionId",
                          value: selected ? selected.value : "",
                        },
                      });
                    }}
                    value={
                      sections.find(
                        (section) => section._id === formData.sectionId
                      )
                        ? {
                          value: formData.sectionId,
                          label: sections.find(
                            (section) => section._id === formData.sectionId
                          ).sectionName,
                        }
                        : null
                    }
                    placeholder={
                      sections.length === 0 ? "No Section" : "Select a Section"
                    }
                    isDisabled={sections.length === 0}
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Card>

          <Card
            style={{
              boxShadow: "none",
              marginTop: "0px",
              paddingTop: "0px",
              minHeight: "400px",
            }}
            className="px-3 "
          >
            {renderTable()}
          </Card>

          <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Exam Overview</ModalHeader>
            <ModalBody>
              <div>
                <div className="px-0 pb-1">
                  <CardTitle tag="h4" className="mt-1 mb-1 text-center">
                    {examName}
                  </CardTitle>
                </div>

{overviewData && Object.keys(overviewData).length > 0 ? (
  Object.keys(overviewData).map((examType) => (
    <div key={examType} className="mt-3">
      <h5>
        {examType === "subDetails" &&
        Object.keys(overviewData).length === 1
          ? ""
          : examType === "subDetails" &&
            overviewData[examType]?.length
            ? "Grade"
            : examType.charAt(0).toUpperCase() + examType.slice(1)}
      </h5>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive className="mb-0">
          <thead
            style={{
              backgroundColor: "#f8f9fa",
              color: "#495057",
            }}
          >
            <tr>
              <th>Subject</th>
              {examType !== "subDetails" && <th>Exam Type</th>}
              <th>Passing Marks</th>
              <th>Total Marks</th>
              <th>Exam Date</th>
              <th>Exam Start Time</th>
              <th>Exam End Time</th>
            </tr>
          </thead>
          <tbody>
            {overviewData[examType] &&
            overviewData[examType].length > 0 ? (
              overviewData[examType].map((examDetail, index) => (
                <tr key={index}>
                  <td>{examDetail.subject}</td>
                  {examType !== "subDetails" && (
                    <td>{examDetail.examType}</td>
                  )}
                  <td>{examDetail.passingMarks}</td>
                  <td>{examDetail.totalMarks}</td>
                  <td>
                    {examDetail.examDate
                      ? new Date(examDetail.examDate).toLocaleDateString('en-GB')
                      : ''}
                  </td>
                  <td>
                    {examDetail.examTime
                      ? new Date(
                          `1970-01-01T${examDetail.examTime}:00`
                        ).toLocaleString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : ''}
                  </td>
                  <td>
                    {examDetail.examEndTime
                      ? new Date(
                          `1970-01-01T${examDetail.examEndTime}:00`
                        ).toLocaleString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : ''}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data available for {examType} exams.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  ))
) : (
  <div className="text-center mt-3">
    <h5>No Subjects added</h5>
  </div>
)}

              </div>
            </ModalBody>

            <style>
              {`
          @media (max-width: 992px) {
            .responsive-row {
              width: 100%; 
            }
          }
          @media (min-width: 992px) {
            .responsive-row {
              width: 70%; 
            }
          }
        `}
            </style>
            <ModalFooter className="d-flex flex-column flex-md-row justify-content-between align-items-start">
              {/* Responsive button container */}
              <div className="mb-2 mb-lg-0">
                <Button className="me-2" color="primary"
                  onClick={() => handleDownload()}
                >
                  Download
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Close
                </Button>
              </div>


            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default Exams;
