import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../middleware/axiosInstance";
import Select from "react-select";

const CreateReceiver = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    branchId: null,
  }); 

  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
  });

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(
          response.data.map((branch) => ({
            value: branch._id,
            label: branch.branchName,
          }))
        );
      } catch (error) {
        // console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Validation logic
    switch (name) {
      case "fullName":
  if (!value.trim()) {
    // Set error for empty value
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "Full Name is required.",
    }));

    // Allow updating the state to reflect the empty field
    setFormData({ ...formData, [name]: value });
    return;
  } else if (value.length > 100) {
    // Set error for exceeding length
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "Full Name must not exceed 100 characters.",
    }));
    return; // Prevent updating state if error exists
  } else {
    // Clear errors when input is valid
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "",
    }));
  }

  // Update state when there are no errors
  setFormData({ ...formData, [name]: value });
  break;

  
  case "email":
  if (value.trim().length > 2000) {
    // Set error for exceeding maximum length
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "Email must be less than or equal to 2000 characters.",
    }));
    return; // Prevent updating state if error exists
  }

  // Clear errors when input is valid
  setErrors((prevErrors) => ({ ...prevErrors, email: "" }));

  // Update state when there are no errors
  setFormData({ ...formData, [name]: value });
  break;



  case "mobileNumber":
  // Allow only numeric input (reject special characters and letters)
  if (!/^\d*$/.test(value)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "Only numeric digits are allowed.",
    }));

    // Remove error after 2 seconds
    setTimeout(() => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }, 2000);

    return; // Prevent further processing
  }

  // Handle errors for input greater than 10 digits
  if (value.length > 10) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "Mobile Number must be exactly 10 digits.",
    }));

    // Remove error after 2 seconds
    setTimeout(() => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }, 2000);

    return; // Prevent adding further data
  }

  // Allow only up to 10 numeric digits
  if (/^\d{0,10}$/.test(value)) {
    setFormData({ ...formData, [name]: value });

    // Validation logic
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      })); // Clear error if field is empty
    } else if (value.length === 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      })); // Clear error if valid
    } else if (value.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Mobile Number must be exactly 10 digits.",
      }));
    }
  }
  break;


      default:
        break;
    }
  
    // Update state only if validation passes
    setFormData({ ...formData, [name]: value });
  };
  

  const handleCreateStaff = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.post("fees/create/receiver", formData);
      navigate("/receivedby");
    } catch (error) {
      // console.warn("Error creating receiver:", error);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/receivedby");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Create Received By
          </CardTitle>
          <Form className="mt-5" onSubmit={handleCreateStaff}>
            <Row>
            <Col xs={12} sm={6} md={4}  xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Select
                    name="branchId"
                    options={branches}
                    value={
                      branches.find(
                        (branch) => branch.value === formData.branchId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Branch"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>  
              <Col xs={12} sm={6} md={4}  xl={3}>
                <FormGroup>
                  <Label for="fullName">
                    <span style={{ fontWeight: "bold" }}>Full Name*</span>
                  </Label>
                  <Input
                    type="text"
                    name="fullName"
                    id="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    
                    required
                    placeholder="Enter Full Name"
                  />
                  {errors.fullName && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.fullName}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4}  xl={3}>
                <FormGroup>
                  <Label for="email">
                    <span style={{ fontWeight: "bold" }}>Email*</span>
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter Email"
                  />
                  {errors.email && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.email}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4}  xl={3}>
                <FormGroup>
                  <Label for="mobileNumber">
                    <span style={{ fontWeight: "bold" }}>Mobile Number*</span>
                  </Label>
                  <Input
                    type="text"
                    name="mobileNumber"
                    id="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                    placeholder="Enter Mobile Number"
                  />
                  {errors.mobileNumber && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.mobileNumber}
                    </div>
                  )}
                </FormGroup>
              </Col>
      
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateReceiver;
