import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Col,
  Row,
  FormGroup,
  CardBody,
  CardHeader,
  Spinner
} from "reactstrap";
import axiosInstance from "../../middleware/axiosInstance";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import { FaBirthdayCake, FaUserGraduate } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaFilter } from "react-icons/fa"; 
import { useDispatch,  useSelector } from 'react-redux';
import {  updateOrgData } from '../../Redux/AuthRelated/AuthSlice';
import { orgData } from "../../Redux/AuthRelated/AuthSlice.js";
const Starter = () => {
  const [ay, setAy] = useState([]);
  const [branches, setBranches] = useState([]);
  const [studentBirthdayData, setStudentBirthdayData] = useState([]);
  const [admissionData, setAdmissionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);  
  const dispatch = useDispatch();

  const fetchOrganizationplan = async () => {
    try {
      const response = await axiosInstance.get('/details/orgplan');
      if (response.data) {
        dispatch(updateOrgData(response.data)); // Dispatch action to update global state
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationplan();
  }, []);


  const [yearBarSeries, setYearBarSeries] = useState([
    { name: "Total Payable Amount", data: [] },
    { name: "Total Paid Amount", data: [] },
    { name: "Total Outstanding Amount", data: [] },
    { name: "Total Fine Paid", data: [] },
  ]);



  const [totalStudents, setTotalStudents] = useState("");
  const [pieSeries, setPieSeries] = useState([0, 0, 0]);

  const [reportData, setReportData] = useState({
    totalPayableAmount: 0,
    totalPaidAmount: 0,
    totalPendingAmount: 0,
    totalPaidFine: 0,
  });

  const [formData, setFormData] = useState({
    academicYearId: "",
    branchId: "",
    
  });

 
  const cardStyle = {
    height: "400px",  
    overflow: "hidden",
    display: "flex", 
    flexDirection: "column",  
    justifyContent: "center",  
    alignItems: "center",  
    boxShadow: "none",
   backgroundColor:'#fbfbfb'
  };

  useEffect(() => {
    const fetchBranches = async () => {

      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }finally {
        setLoading(false); 
      }
    };
    fetchBranches();
  }, []);



  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
      
        const sortedAcademicYears = response.data.sort((a, b) => {
          // Extract starting years from "year" (e.g., "2024-25" => 2024)
          const startYearA = parseInt(a.year.split("-")[0]);
          const startYearB = parseInt(b.year.split("-")[0]);
  
          // Sort based on the starting year
          return startYearA - startYearB;
        });
  
        setAy(sortedAcademicYears);
        
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();  
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 3) {
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

         const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear  
        );

        

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,  
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }finally {
        setLoading(false);  
      }
    };

    getAcademicYears();
  }, []);



  const fetchFeesByAcademicYear = async () => {
    try {
      const params = {
        academicYearId: formData.academicYearId,
      };

       if (formData.branchId) {
        params.branchId = formData.branchId;
      }

      const response = await axiosInstance.get("/fees/report/academicyear", {
        params: params,
      });

      if (response.data) {
        const {
          totalPayableAmount,
          totalPaidAmount,
          totalPendingAmount,
          totalPaidFine,
        } = response.data;
        setReportData({
          totalPayableAmount,
          totalPaidAmount,
          totalPendingAmount,
          totalPaidFine,
        });
      } else {
        setReportData({
          totalPayableAmount: 0,
          totalPaidAmount: 0,
          totalPendingAmount: 0,
          totalPaidFine: 0,
        });
      }
    } catch (error) {
      setReportData({
        totalPayableAmount: 0,
        totalPaidAmount: 0,
        totalPendingAmount: 0,
        totalPaidFine: 0,
      });
    }finally {
      setLoading(false);  
    }
  };

  useEffect(() => {
    if (formData.academicYearId || formData.branchId) {
      fetchFeesByAcademicYear();
    }
  }, [formData.academicYearId, formData.branchId]);


  const fetchTotalStudentsByGender = async () => {

    try {
       const params = {
        academicYearId: formData.academicYearId,
      };

       if (formData.branchId) {
        params.branchId = formData.branchId;
      }

       const response = await axiosInstance.get("/total/students", {
        params: params,
      });

 
      const { totalMale, totalFemale, totalOthers, totalStudents } = response.data;

      setPieSeries([totalMale, totalFemale, totalOthers]);
      setTotalStudents(totalStudents);  
    } catch (error) {
      console.error("Error fetching total students by gender:", error);
    }finally {
      setLoading(false);  
    }
  };


  useEffect(() => {
     if (formData.academicYearId) {
      fetchTotalStudentsByGender();
    }
  }, [formData.academicYearId, formData.branchId]); 

  const pieOptions = {
    labels: ["Male", "Female", "Others"],
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total Students",
              formatter: () => `${totalStudents}`,
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",  
      horizontalAlign: "center",
    },
    colors: ["#008FFB", "#FF5F1F", "#808080"],
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const fetchAdmission = async () => {

    try {
       const params = {
        academicYearId: formData.academicYearId,
        branchId: formData.branchId,  
      };

      const response = await axiosInstance.get("/latest/admissions", {
        params: params,
      });

      if (response.status === 200) {
        const sortedAdmissions = (response.data.admissions || []).sort(
          (a, b) => new Date(b.admissionDate) - new Date(a.admissionDate)
        );
        setAdmissionData(sortedAdmissions);
      } else {
        console.error("Failed to fetch latest admissions");
      }
    } catch (error) {
      console.error("Error fetching latest admissions:", error);
    }finally {
      setLoading(false);  
    }
  };



  const fetchBirthdays = async () => {

    try {
      const params = {
        academicYearId: formData.academicYearId,
        branchId: formData.branchId,  
      };

      const response = await axiosInstance.get("/latest/birthdays", {
        params: params,
      });

      if (response.status === 200) {
        setStudentBirthdayData(response.data.birthdayStudents);
      } else {
        console.error("Failed to fetch student birthdays");
      }
    } catch (error) {
      console.error("Error fetching student birthdays:", error);
    }finally {
      setLoading(false);  
    }
  };

  useEffect(() => {
    if (formData.academicYearId || formData.branchId) {
      fetchAdmission();  
      fetchBirthdays();  
    }
  }, [formData.academicYearId, formData.branchId]);



  const fetchAcademicYearFeesReport = async () => {

    try {
      const response = await axiosInstance.get("/bar/report/academicYear", {
        params: {
          branchId: formData.branchId, 
        },
      });
  
      if (response.data) {
        const {
          totalPayableAmount,
          totalPaidAmount,
          totalPendingAmount,
          totalPaidFine,
          academicYears,  
        } = response.data;
  
        setYearBarSeries([
          { name: "Total Receivable Amount", data: totalPayableAmount },
          { name: "Total Amount Received", data: totalPaidAmount },
          { name: "Total Outstanding Amount", data: totalPendingAmount },
          { name: "Total Fine Paid", data: totalPaidFine },
        ]);
  
  if (academicYears.length < 5) {
    // Calculate how many empty placeholders are needed
    const placeholdersNeeded = 5 - academicYears.length;
  
    // Add empty placeholders to the array
    for (let i = 0; i < placeholdersNeeded; i++) {
      academicYears.push("");
    }
  }
         setYearChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: academicYears,  
          },
        }));
      } else {
         setYearBarSeries([
          { name: "Total Receivable Amount", data: Array(5).fill(0) },
          { name: "Total Amount Received", data: Array(5).fill(0) },
          { name: "Total Outstanding Amount", data: Array(5).fill(0) },
          { name: "Total Fine Paid", data: Array(5).fill(0) },
        ]);
      }
    } catch (error) {
      setYearBarSeries([
        { name: "Total Receivable Amount", data: Array(5).fill(0) },
        { name: "Total Amount Received", data: Array(5).fill(0) },
        { name: "Total Outstanding Amount", data: Array(5).fill(0) },
        { name:"Total Fine Paid", data: Array(5).fill(0) },
      ]);
    }finally {
      setLoading(false);  
    }
  };
  
  useEffect(() => {
    fetchAcademicYearFeesReport();
  }, [formData.branchId]);  
  
  const [yearChartOptions, setYearChartOptions] = useState({
    chart: {
      type: "bar",
      height: 250,
      stacked: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
        endingShape: "rounded",
      },
    },
    xaxis: {
      categories: [],  
    },
    yaxis: {
      title: {
        text: "Amount (₹)",
      },
    },
    
    legend: {
      position: "top",
      horizontalAlign: "center",
    },
    colors: ["#008FFB", "#FF5F1F", "#808080", "#fb0f01"],
    dataLabels: {
      enabled: false,
    },
  });




  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM dd, yyyy");
  };

  const [paddingRight, setPaddingRight] = useState(window.innerWidth > 575 ? "7px" : "5px");
  const [right,setright]=useState(window.innerWidth > 575 ? "0px" : "40px");
  const [width,setwidth]=useState(window.innerWidth > 575 ? "400px" : "100%");


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 575) {
        setPaddingRight("14px"); 
        setright("0px");
        setwidth('400px')
      } else {
        setPaddingRight("17px"); 
        setright("40px");
        setwidth('100%')
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
        <Spinner  color="primary" />
      </div>
    ) : (
    <div>
      <Card
        style={{ boxShadow: "none", marginBottom: "10px", borderRadius: "0" }}
      >
        <div
          className="d-sm-flex justify-content-between align-items-center py-sm-0 py-1   position-relative"
          style={{ height: "" }}
        >
          <CardTitle tag="h4" className="pt-2 ps-3">
            Dashboard
          </CardTitle>
          <style>
  {`
    /* Hide filter icon on screens above 580px */
    @media (min-width: 580px) {
      .filter-icon {
        display: none;
      }
      .filter-options {
        display: flex !important;
      }
    }

    /* Show filter options only when icon is clicked on screens below 580px */
    @media (max-width: 580px) {
      .filter-options {
        display: none;
      }
      .show-filters {
        display: flex !important;
      }
    }
  `}
</style>
          <div
              className="filter-icon "
              onClick={() => setShowFilters(!showFilters)}
              style={{ cursor: "pointer", padding: "10px", position:"absolute" , top:'3px', right:'6px'}}
            >
              <FaFilter size={24} />
            </div>
            {(showFilters || window.innerWidth > 575) && (
          <div
          className={`py-0  mt-2   filter-options d-flex justify-content-end  gap-2 ${showFilters ? "show-filters" : ""}`}
            style={{
              paddingLeft: "15px",
              paddingRight: paddingRight,
              minWidth: "200px",
              right:right,
              width:width,
              marginBottom:"-5px",
              
              
            }}
          >
            <FormGroup className=" w-100 ">
              <Select
                id="branchId" 
                options={branches.map((branch) => ({
                  value: branch._id,
                  label: branch.branchName,
                }))}
                onChange={(selected) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    branchId: selected ? selected.value : "",
                  }));
                }}
                value={
                  branches.find((branch) => branch._id === formData.branchId)
                    ? {
                        value: formData.branchId,
                        label: branches.find(
                          (branch) => branch._id === formData.branchId
                        ).branchName,
                      }
                    : null
                }
                
                placeholder="Select Branch"
                isSearchable={true}
                isClearable
                styles={customStyles}
              />
            </FormGroup>
            <style>
                {`
                  .responsive-form-group {
                    width: 180px;
                  }

                  /* Apply width 210px when screen width is less than 450px */
                  @media (max-width: 450px) {
                    .responsive-form-group {
                      width: 210px;
                    }
                  }
                `}
              </style>

              <FormGroup className="responsive-form-group">
              <Select
                id="academicYear"
                options={ay.map((year) => ({
                  value: year._id,
                  label: year.year,
                }))}
                onChange={(selected) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    academicYearId: selected ? selected.value : "",
                    // branchId: "",
                  }));
                }}
                value={
                  ay.find((year) => year._id === formData.academicYearId)
                    ? {
                        value: formData.academicYearId,
                        label: ay.find(
                          (year) => year._id === formData.academicYearId
                        ).year,
                      }
                    : null
                }
                placeholder="Select Academic Year"
                isSearchable={true}
                styles={{ ...customStyles, width: '150px' }}
              />
            </FormGroup>
          </div>
)}
        </div>
      </Card>

      <div style={{ margin: "0px", padding: "0px", marginBottom: "15px" }}>
        <Row
          className="mx-0 px-0 pt-0 text-center"
          style={{ marginBottom: "-7px" }}
        >
          <Col sm="6" lg="3" className="mt-lg-0 mt-2 px-0 pe-sm-1">
            <div
              className="custom-card"
              style={{ backgroundColor: "#ffffff", padding: "16px" }}
            >
              {" "}
              {/* Lightened blue */}
              <div className="custom-card-body">
                <div className="custom-card-icon">
                  <i className="bi bi-receipt" style={{ color: "#008FFB" }}></i>
                </div>
                <div className="custom-card-content">
                  <h5 className="custom-card-title">Total Receivable Amount</h5>
                  <h5 className="custom-card-earning">
                    ₹{" "}
                    {reportData.totalPayableAmount
                      ? reportData.totalPayableAmount.toFixed(2)
                      : "0.00"}
                  </h5>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="6" lg="3" className="mt-lg-0 mt-2 px-0 px-sm-1 pe-sm-0 pe-lg-1">
            <div className="custom-card" style={{ backgroundColor: "#ffffff" }}>
              {" "}
              {/* Lightened green */}
              <div className="custom-card-body">
                <div className="custom-card-icon">
                  <i
                    className="bi bi-currency-rupee"
                    style={{ color: "#FF5F1F" }}
                  ></i>
                </div>
                <div className="custom-card-content">
                  <h5 className="custom-card-title">Total Amount Received</h5>
                  <h5 className="custom-card-earning">
                    ₹ {(reportData.totalPaidAmount || 0).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="6" lg="3" className="mt-lg-0 mt-2 px-0 px-sm-1 ps-sm-0 ps-lg-1">
            <div className="custom-card" style={{ backgroundColor: "#ffffff" }}>
              {" "}
              {/* Lightened red */}
              <div className="custom-card-body">
                <div className="custom-card-icon">
                  <i
                    className="bi bi-hourglass-split"
                    style={{ color: "#808080" }}
                  ></i>
                </div>
                <div className="custom-card-content">
                  <h5 className="custom-card-title">Total Outstanding Amount</h5>
                  <h5 className="custom-card-earning">
                    ₹ {(reportData.totalPendingAmount || 0).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="6" lg="3" className="mt-lg-0 mt-2 px-0 ps-sm-1">
            <div className="custom-card" style={{ backgroundColor: "#ffffff" }}>
              {" "}
              {/* Lightened yellow */}
              <div className="custom-card-body">
                <div className="custom-card-icon">
                  <i
                    className="bi bi-exclamation-circle"
                    style={{ color: "#fb0f01" }}
                  ></i>
                </div>
                <div className="custom-card-content">
                  <h5 className="custom-card-title">Total Fine Paid</h5>
                  <h5 className="custom-card-earning">
                    ₹ {(reportData.totalPaidFine || 0).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>


      <Card
        className="mt-3 shadow"
        style={{ padding: "1rem", boxShadow: "none", width: "100%" }}
      >
        <div id="chart" style={{ width: "100%", maxWidth: "100%" }}>
              <ReactApexChart
                options={yearChartOptions}
                series={yearBarSeries}
                type="bar"
                height={350}
                width="100%"
              />
            </div>
          </Card>  
        


      <Row className="mx-0 p-0" style={{ marginTop: "-20px" }}>
        <Col md="4" className="p-0">
          <Card style={cardStyle}  className=" shadow">
          <CardHeader className="fw-bold w-100" style={{ borderBottom: "1px solid #C5C8CA", backgroundColor:'#f9f9fa' }}>
            Total Students by Gender
          </CardHeader>
            <CardBody >
              <ReactApexChart
                key={totalStudents}
                options={pieOptions}
                series={pieSeries}
                type="donut"
                height={300}
              />
            </CardBody>
            <div className="d-flex text-center mb-3">
              <p className="pe-3">
                <strong>Male: </strong>
                {pieSeries[0] || 0}
              </p>
              <p className="pe-3">
                <strong>Female: </strong>
                {pieSeries[1] || 0}
              </p>
              <p className="pe-3">
                <strong>Others: </strong>
                {pieSeries[2] || 0}
              </p>
            </div>
          </Card>
        </Col>

        <Col md="5" xs="12" className=" px-0 px-md-2">
          <Card className="mt-0">
            <CardHeader className="fw-bold" style={{backgroundColor:'#f9f9fa' }}>Latest Admissions</CardHeader><hr className=" m-0 p-0"></hr>
            <CardBody style={{ height: "360px", overflowY: "auto" ,backgroundColor:'#fbfbfb' }}>
              {admissionData.length > 0 ? (
                <Row>
                  {admissionData.slice(0, 6).map((student, index) => (
                    <Col
                      xs="12"
                      sm="6"
                      md="12"
                      xl="6"
                      key={index}
                      className="mb-0"
                    >
                      <NavLink
                        to={`/singlestudent/${student._id}`} 
                        className="text-decoration-none w-100"
                        style={{ textDecoration: "none" }}
                      >
                         <style>
                            {`
                              .card-hover {
                                transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                              }

                              .card-hover:hover {
                                transform: scale(1.05);
                                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
                              }
                            `}
                          </style>

                          <Card
                            className="d-flex flex-column align-items-center px-0 py-3 card-hover"
                            style={{ height: "90%" }}
                          >
                          <div className="text-center">
                            {student.image ? (
                              <img
                                className="rounded-circle mb-3"
                                src={student.image}
                                alt="Student"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                className="rounded-circle mb-3"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  backgroundColor: "#fb0f01",
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "24px",
                                  fontWeight: "bold",
                                }}
                              >
                                {getInitial(student.firstName)}
                              </div>
                            )}
                          </div>
                          <CardBody className="text-center py-2 px-4 w-100">
                            <h6 className="card-title mb-1">
                              {student.firstName} {student.lastName}
                            </h6>
                            <div className=" text-center">
                              <p
                                style={{ fontSize: "14px" }}
                                className="card-text mb-1"
                              >
                                {student.branchName || "N/A"}
                              </p>
                              <div>
                                <p
                                  style={{ fontSize: "14px" }}
                                  className="card-text mb-1"
                                >
                                  {student.className || "N/A"}
                                  {student.sectionName
                                    ? ` (${student.sectionName})`
                                    : ""}
                                </p>
                              </div>
                              <p className="card-text">
                                {formatDate(student.admissionDate)}
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div
                  className="text-center p-4 h-100 align-content-center"
                  style={{
                    color: "#888",
                    borderRadius: "8px",
                  }}
                >
                  <FaUserGraduate className="mt-3" size={70} color="#888" />
                  <h4 className="alert-heading mb-3 mt-3">
                    No Admissions Found!
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md="3" xs="12" className="p-0">
          <Card className="mt-0">
            <CardHeader className="fw-bold" style={{backgroundColor:'#f9f9fa' }}>Today's Birthdays</CardHeader><hr className=" m-0 p-0"></hr>
            <CardBody style={{ overflowY: "auto", height: "360px" ,backgroundColor:'#fbfbfb' }}>
              <Row className=" m-0 p-0 ">
              {studentBirthdayData.length > 0 ? (
                studentBirthdayData.map((student, index) => (
                  <Col sm='6' md='12' className="mb-0 px-0 px-sm-2 px-md-0" key={index}>
                                          <NavLink
                        to={`/singlestudent/${student._id}`}
                        className="text-decoration-none w-100"
                        style={{ textDecoration: "none" }}
                      >
                     <style>
                        {`
                          .card-hover-row {
                            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                          }

                          .card-hover-row:hover {
                            transform: scale(1.03);
                            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
                          }
                        `}
                      </style>
                      <Card
                        className="d-flex flex-row flex-md-column flex-xl-row align-items-center card-hover-row"
                        style={{ height: "90%" }}
                      >
                      <div className="p-2">
                        {student && student.image ? (
                          <img
                            className="rounded-circle mt-3"
                            src={student.image || "default-image-url"}
                            alt="Student Image"
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              marginBottom: "15px",
                            }}
                          />
                        ) : (
                          <div
                            className="rounded-circle"
                            style={{
                              width: "70px",
                              height: "70px",
                              backgroundColor: "#fb0f01",
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "30px",
                              fontWeight: "bold",
                              marginBottom: "15px",
                            }}
                          >
                            {getInitial(student.firstName)}
                          </div>
                        )}
                      </div>

                      {/* Right side: Student Details */}
                      <CardBody className="p-2 text-md-center text-xl-start">
                        <h6 className="mb-1">
                          {student.firstName} {student.lastName}
                        </h6>
                        <p style={{ fontSize: "14px" }} className="mb-1">
                          {student.branchName || "N/A"}
                        </p>
                          <p
                            style={{ fontSize: "14px" }}
                            className="card-text mb-1"
                          >
                            {student.className || "N/A"}
                            {student.sectionName
                              ? ` (${student.sectionName})`
                              : ""}
                          </p>
                        <p className="mb-0">
                          {formatDate(student.dateOfBirth)}
                        </p>
                      </CardBody>
                    </Card>
                    </NavLink>
                  </Col>
                ))
              ) : (
                <div
                  className="text-center p-4 h-100 align-content-center"
                  style={{
                    color: "#888",
                    borderRadius: "8px",
                  }}
                >
                 <div className=" align-content-center justify-content-center " style={{height:'280px'}}>
                   <FaBirthdayCake className="mt-3" size={70} color="#888" />
                  <h4 className="alert-heading mb-3 mt-3">
                    No Birthdays Today!
                  </h4>
                 </div>
                </div>
              )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      </div>
    )}
      </>
  );
};

export default Starter;
