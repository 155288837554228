import React from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import tulipLogoo from '../../assets/images/logos/tulip_logo.png'
import { FaExclamationTriangle } from 'react-icons/fa';
 
const ExpiredPage = () => {
  return (
    <div
      className='text-center  px-3 pb-5 vh-100'
      style={{
        background: 'white',  
        width: '100%',
      }}
    >
       <div className='w-100'>
        <img src={tulipLogoo} alt="Tulip Logo" className='tuliplogo'/>
      </div>
      <div
        className='container bg-white  shadow-lg mb-4 p-3 p-md-5 text-center animate__animated animate__fadeIn mt-3'
        style={{ maxWidth: '600px', border: 'none' }}  
      >
         <div className='mb-4'>
          
          <FaExclamationTriangle className=" img-fluid " style={{ width: '80px', maxWidth: '100%' , color:'#FB0F01' }}/>
        </div>

         <h1 className='mb-3 fw-bold' style={{ fontSize: '2rem', color: '#FB0F01' }}>
          Subscription Expired
        </h1>

         <p className='mb-4 text-secondary' style={{ fontSize: '1rem' }}>
          Your trial subscription has ended. Please renew to continue enjoying our premium services.
        </p>

        <p className='mb-4 text-secondary' style={{ fontSize: '1rem' }}>
          Please contact our team using the details below to get help with your premium plan:
        </p>

         <div className='d-flex flex-column align-items-center mt-4'>
          <div className='d-flex align-items-center mb-2'>
            <FaPhoneAlt size={18} className='me-2 text-primary' />
            <a href='tel:+91-9307646349' className='text-primary' style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
              +91-9307646349
            </a>
          </div>

          <div className='d-flex align-items-center mb-2'>
            <FaEnvelope size={18} className='me-2 text-primary' />
            <a href='mailto:contact@tulipschoolerp.com' className='text-primary' style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
              contact@tulipschoolerp.com
            </a>
          </div>
        </div>
      </div>

       <style jsx="true">{`
         @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

        .img-fluid {
          animation-duration: 1.5s;
        }

        @media (max-width: 576px) {
          h1 {
            font-size: 1.8rem;  
          }

          p {
            font-size: 0.9rem;  
          }
        }

        @media (max-width: 768px) {
          .container {
            padding: 3rem 2rem;  
          }
        }
      `}</style>
    </div>
  );
}

export default ExpiredPage;
