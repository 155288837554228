
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, CardBody, CardTitle, Col, Row, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaFileAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { FaFileInvoice, FaMoneyBillWave } from 'react-icons/fa';
import axiosInstance from '../../../../middleware/axiosInstance';


const HistoryTransaction = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        studentId: '',
        studentName: '',
        address: '',
        registerNo: '',
        mobileNumber: '',
        email: '',
        academicYear: '',
        className: '',
        branchName: '',
        sectionName: '',
        finalAmount: '',
        paidAmount: '',
        receivedAmount: '',
        dueDate: '',
        paymentDate: '',
        paymentMethod: '',
        fine: '',
        discount: '',
        status: '',

    });


    const [status, setStatus] = useState([])
    const [fee, setFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [transactionHistory, setTransactionHistory] = useState([]);

    useEffect(() => {
        const fetchFeeDetails = async () => {
            // try {
            //     const token = localStorage.getItem('token');
            //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/singlefees/${id}`, {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //         },
            //     });

            //     const fetchedFee = response.data.data;
            //     setFee(fetchedFee);

            //     setFormData({
            //         studentId: fetchedFee.student_id._id,
            //         registerNo: fetchedFee.student_id.registerNo, // Ensure correct field names
            //         address: fetchedFee.student_id.address,       // Ensure correct field names
            //         studentName: `${fetchedFee.student_id.firstName} ${fetchedFee.student_id.fatherName} ${fetchedFee.student_id.lastName}`,
            //         finalAmount: fetchedFee.finalAmount || '0',
            //         paidAmount: fetchedFee.paidAmount || '0',
            //         receivedAmount: '',
            //         branchName: fetchedFee.branch_id.branchName,
            //         academicYear: fetchedFee.academicyear_id.academicYearName,
            //         className: fetchedFee.class_id.className,
            //         sectionName: fetchedFee.section_id ? fetchedFee.section_id.sectionName : '',
            //         dueDate: fetchedFee.dueDate ? new Date(fetchedFee.dueDate).toISOString().split('T')[0] : '',
            //         mobileNumber: fetchedFee.student_id.mobileNumber || '',
            //         email: fetchedFee.student_id.email || '',
            //         classFees: fetchedFee.classFees || '',
            //         discount: fetchedFee.discount || '0',
            //         fine: fetchedFee.fine || '0',
            //         status: fetchedFee.status
            //     });

            //     fetchTransactionHistory()


            // } catch (error) {
            //     console.error('Error fetching fee details:', error);
            //     Swal.fire('Error!', 'Failed to fetch fee details.', 'error');
            // }
            try {
                // Make the API request using axiosInstance
                const response = await axiosInstance.get(`/singlefees/${id}`);
        
                const fetchedFee = response.data.data;
                setFee(fetchedFee);
        
                setFormData({
                    studentId: fetchedFee.student_id._id,
                    registerNo: fetchedFee.student_id.registerNo,
                    address: fetchedFee.student_id.address,
                    studentName: `${fetchedFee.student_id.firstName} ${fetchedFee.student_id.fatherName} ${fetchedFee.student_id.lastName}`,
                    finalAmount: fetchedFee.finalAmount || '0',
                    paidAmount: fetchedFee.paidAmount || '0',
                    receivedAmount: '',
                    branchName: fetchedFee.branch_id.branchName,
                    academicYear: fetchedFee.academicyear_id.academicYearName,
                    className: fetchedFee.class_id.className,
                    sectionName: fetchedFee.section_id ? fetchedFee.section_id.sectionName : '',
                    dueDate: fetchedFee.dueDate ? new Date(fetchedFee.dueDate).toISOString().split('T')[0] : '',
                    mobileNumber: fetchedFee.student_id.mobileNumber || '',
                    email: fetchedFee.student_id.email || '',
                    classFees: fetchedFee.classFees || '',
                    discount: fetchedFee.discount || '0',
                    fine: fetchedFee.fine || '0',
                    status: fetchedFee.status
                });
        
                fetchTransactionHistory();
        
            } catch (error) {
                
                console.error('Error fetching fee details:', error);
                // intereter.error('Failed to fetch fee details.');
            }
        };

        const fetchPaymentMethod = async () => {
            // try {
            //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/paymentmethod`);
            //     setPaymentMethod(response.data);
            // } catch (error) {
            //     console.error('Error fetching fees type:', error);
            // }
            try {
                const response = await axiosInstance.get('/paymentmethod');
                setPaymentMethod(response.data);
            } catch (error) {
                // Handle errors using intereter
                console.error('Error fetching payment method:', error);
                // intereter.error('Failed to fetch payment methods.');
            }
        };


        fetchFeeDetails();
        fetchPaymentMethod();
    }, [id]);



    // const fetchTransactionHistory = async () => {
    //     try {
    //         const token = localStorage.getItem('token');
    //         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transactionhistory/${id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });

    //         setTransactionHistory(response.data.transactionHistories);
    //     } catch (error) {
    //         console.error('Error fetching payment history:', error);
    //     }
    // };

    const fetchTransactionHistory = async () => {
        try {
            const response = await axiosInstance.get(`/transactionhistory/${id}`);
            setTransactionHistory(response.data.transactionHistories);
        } catch (error) {
            console.error('Error fetching transaction history:', error);
            // intereter.error('Failed to fetch transaction history.');
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        if (isNaN(d.getTime())) {
            return 'Invalid Date';
        }
        return d.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };


    // const handleRevert = async (historyId) => {
    //     try {
    //         const token = localStorage.getItem('token');
    //         await axios.delete(`http://localhost:4000/transactionhistory/${historyId}/revert`, {
    //             headers: { Authorization: `Bearer ${token}` },
    //         });

    //         setTransactionHistory(transactionHistory.filter(entry => entry._id !== historyId));
    //         fetchFeeDetails();

    //         Swal.fire('Success!', 'Payment history entry reverted successfully.', 'success');
    //         navigate('/fees');
    //     } catch (error) {
    //         console.error('Error reverting payment history:', error);
    //     }
    // };

    const handleEdit = (id) => {
        navigate(`/update/transaction/${id}`);
    };

    return (
        <Card>
            <div className=' p-1 mt-2 d-flex justify-content-start align-items-start'>
                <NavLink
                    to={`/details/transaction/${id}`}
                    style={{ textDecoration: 'none', color: '#777', marginLeft: '1rem', display: 'flex', alignItems: 'center' }}
                >
                    <FaFileInvoice style={{ paddingBottom: '0.2rem', fontSize: '1.2rem', fontWeight: 'bold', color: '#777' }} />
                    <CardTitle className='mx-1 mt-1' tag="h5">Details</CardTitle>
                </NavLink>
                <NavLink
                    to={`/create/transaction/${id}`}
                    style={{ textDecoration: 'none', color: '#777', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}
                >
                    <FaMoneyBillWave style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#777' }} />
                    <CardTitle className='mx-1 mt-1' tag="h5">Collect Fees</CardTitle>
                </NavLink>
            </div>
            <hr className='mt-0'></hr>
            <div className=' p-3 mt-2'>
                <CardTitle tag="h5" className="text-start mb-3">Transaction History:</CardTitle>
                <Table bordered>
                    <thead>
                        <tr>
                        <th>Payment ID</th>
                            <th>Payment Method</th>
                            <th>Next Due Date</th>
                            <th>Agreed Final Amount</th>
                            <th>Paid</th>
                            <th>Fine</th>
                            <th>Payment Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionHistory.map((history) => (
                            <tr key={history._id}>
                                <td>{history.paymentId}</td>
                                <td>{history.paymentMethod ? history.paymentMethod : 'N/A'}</td>
                                <td>{formatDate(history.dueDate)}</td>
                                <td>{history.finalAmount}</td>
                                <td>{history.receivedAmount}</td>
                                <td>{history.fine}</td>
                                <td>{formatDate(history.paymentDate)}</td>
                                <td>
                                    <Button className='btn-no-radius mx-2' color="success" onClick={() => handleEdit(history._id)}>
                                        Edit
                                    </Button>
                                    {/* <Button className='btn-no-radius' color="danger" onClick={() => handleRevert(history._id)}>
                                        Revert
                                    </Button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className=' p-3 d-flex justify-content-end'>
                <Card style={{ minWidth: '250px', minHeight: '250px', backgroundColor: "#f8f9fa" }}>
                    <div className="d-flex flex-column justify-content-center p-4">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0.5rem',
                                marginBottom: '1rem',
                                backgroundColor: 'white',
                                textAlign: 'center'
                            }}
                        >
                            <p style={{ margin: 0 }}>
                                <span style={{ fontWeight: 'bold' }}>Status :</span> {formData.status}
                            </p>
                        </div>
                        <p><span style={{ fontWeight: "bold" }}>Grand Total :</span> {formData.finalAmount}</p>
                        <p><span style={{ fontWeight: "bold" }}>Discount :</span> {formData.discount} %</p>
                        <p><span style={{ fontWeight: "bold" }}>Total Paid :</span> {formData.paidAmount}</p>
                        <p><span style={{ fontWeight: "bold" }}>Fine :</span> {formData.fine}</p>
                        <p><span style={{ fontWeight: "bold" }}>Total Paid (With Fine) :</span> {(parseFloat(formData.paidAmount) + parseFloat(formData.fine || '0'))}</p>
                        <p><span style={{ fontWeight: "bold" }}>Balance : </span> {(parseFloat(formData.finalAmount) - parseFloat(formData.paidAmount || '0'))}</p>
                    </div>
                </Card>
            </div>
        </Card >
    );
};

export default HistoryTransaction;





