import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import { FaSearch, FaRegEdit, FaFileAlt } from 'react-icons/fa'
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import axiosInstance from '../../../../middleware/axiosInstance.js'
import Select from 'react-select'
import { FiArrowLeft } from 'react-icons/fi';


const ExamStudents = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState({ sectionId: '' })
  const [branches, setBranches] = useState([])
  const [classes, setClasses] = useState([])
  const [sections, setSections] = useState([])
  const [students, setStudents] = useState([])
  const [totalStudents, setTotalStudents] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [studentsPerPage] = useState(75)
  const [error, setError] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [noDataFound, setNoDataFound] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingStates, setLoadingStates] = useState({})
  const [resultsending, setResultSending] = useState({
    sending: false,
    studentId: ''
  })
  const [exam, setExam] = useState({});

  // Modal state
  const [modalOpen, setModalOpen] = useState(false)
  const [marksheetUrl, setMarksheetUrl] = useState({
    url: '',
    fullName: ''
  })
  const [email, setEmail] = useState('')

  const fetchStudents = async () => {
    try {
      const response = await axiosInstance.get(
        `exam/allstudentbyexamid/${id}`,
        {
          params: {
            section_Id: formData.sectionId
          }
        }
      )
      const sortedStudents = response.data.students.sort(
        (a, b) => a.roll - b.roll
      )
      setStudents(sortedStudents)
    
    } catch (error) {
      setError('No Data Found')
    }
  }

  const fetchExam = async () => {
    try {
      const response = await axiosInstance.get(`/exam/details/exam/${id}`)
      const fetchedExam = response.data

      const sortedSections = fetchedExam.section_id.sort((a, b) =>
        a.sectionName.localeCompare(b.sectionName)
      )
      setSections(sortedSections)
      setExam(fetchedExam);
    } catch (error) {
      // console.error('Error fetching exam details:', error)
    } finally {
    }
  }

  useEffect(() => {
    fetchStudents()
   
    fetchExam()
  }, [formData.sectionId])

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
    setCurrentPage(1)
  }

  const GenerateResult = async studentId => {
    setLoadingStates(prev => ({ ...prev, [studentId]: true }))
    try {
      const response = await axiosInstance.get(
        `/exam/fetch/studentsResultByExamid/${id}/${studentId}`
      )
      const { url, lastGeneratedDate } = response.data.responsedata
      // const student = students.find((student) => student._id === studentId);
      const student = students.find((student) =>  student.fromHistory 
      ? student.student_id === studentId 
      : student._id === studentId);


      const fullName = `${student.roll}_${student.firstName}_${student.lastName}`;

      const marksheetData = { fullName, url };
      // Update the students state with the new marksheet data
      setStudents(prevStudents =>
        prevStudents.map(student =>
          student._id === studentId
            ? { ...student, marksheet: { url, lastGeneratedDate } } // Add or update the marksheet field
            : student
        )
      )
      handleViewMarksheet(marksheetData , student.email, studentId);
    } catch (err) {
      // console.error('Error generating Leaving Certificate:', err)
    } finally {
      setLoadingStates(prev => ({ ...prev, [studentId]: false }))
    }
  }



  const handleViewMarksheet = (marksheetData , email, student_id) => {
    setEmail(email)
    setMarksheetUrl({
    url: marksheetData.url,
    fullName: marksheetData.fullName
    })
    setModalOpen(true)
    setResultSending(prevState => ({
      ...prevState,
      studentId: student_id
    }))
  }

  const closeModal = () => {
    setModalOpen(false) // Close the modal
  }

  const SendResult = async studentId => {

    setResultSending(prevState => ({
      ...prevState,
      sending: true
    }))

    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/exam//sendgeneratedresult/${id}/${studentId}`,
        {
          email: email
        }
      )
    } catch (err) {
      // console.error('Error generating Leaving Certificate:', err)
    } finally {
      setResultSending(prevState => ({
        ...prevState,
        sending: false
      }))
    }
  }


  const customStyles = {
    placeholder: provided => ({
      ...provided,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01'
      }
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999 
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FDD7C2' : '#FFFFFF',
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black'
      },
      '&:active': {
        backgroundColor: '#E8F0FE'
      }
    })
  }

  const handleChange = ({ target: { name, value } }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleDownload = async (data, filename) => {
  const url = data.url;
  const studentName = data.fullName;

    try {
      const mainresponse = await axiosInstance.post('/api/download', { url })
      if (mainresponse?.data?.url) {
        const response = await fetch(mainresponse.data.url)
        const blob = await response.blob()
        const contentType = response.headers.get('Content-Type')

        let extension = ''

        if (contentType) {
          extension = contentType.split('/')[1]
        } else {
          extension = url.split('.').pop().split(/\#|\?/)[0]
        }

        // const finalFilename = `${studentData.roll}_${studentData.firstName}_${studentData.lastName}_${filename}.${extension}`;
        const finalFilename = `${studentName || 'student'}_${exam?.examName || "exam"}_${filename}.${extension}`
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', finalFilename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      // console.error('Download failed:', error)
      Swal.fire({
        title: 'Warning',
        text: 'Server is facing some issue, please try again later',
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    }
  }

  const handleBackClick = () => {
    navigate(-1);  
  };

  const renderTable = () => (
    <div>
       <style>
            {`
          @media (max-width: 575.98px) {
            .responsive-block {
              display: block !important;
            }
          }
        `}
          </style>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="responsive-block"
          >
      <div className="px-0 pb-sm-3">
        <CardTitle tag='h4' className="mt-3 mb-sm-1">
          Student List
        </CardTitle>
      </div>
      <Col xs={12} sm={6} md={4} xl={3} className=" mt-sm-3">
            <FormGroup>
              <Select
                id='section'
                options={sections.map(section => ({
                  value: section._id,
                  label: section.sectionName
                }))}
                onChange={selected => {
                  handleChange({
                    target: {
                      name: 'sectionId',
                      value: selected ? selected.value : ''
                    }
                  })
                }}
                value={
                  sections.find(
                    section => section._id === formData.sectionId
                  )
                    ? {
                        value: formData.sectionId,
                        label: sections.find(
                          section => section._id === formData.sectionId
                        ).sectionName
                      }
                    : null
                }
                placeholder={
                  sections.length === 0 ? 'No Section' : 'Select a Section'
                }
                isDisabled={sections.length === 0}
                isSearchable={true}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
          </div>
      <div style={{ overflowX: 'auto' }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }}>
            <tr>
              <th>Roll No.</th>
              <th>Student Name</th>
              <th>Class</th>
              <th>Branch</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
             {students.length > 0 ? (
               students.map(student => (
                  <tr key={student._id}>
                    <td>{student.roll}</td>
                    <td>
                      {student.firstName} {student.lastName}
                    </td>
                    <td>
                      {student.class_id?.className}{' '}
                      {student.section_id
                        ? ` (${student.section_id.sectionName})`
                        : ''}
                    </td>
                    <td>{student.branch_id?.branchName}</td>
                    <td>{student.academicyear_id?.year}</td>
                    <td>
                      <Button
                        color='primary'
                        style={{
                          backgroundColor: loading ? 'black' : '',
                          marginRight: '20px',
                          width:'220px'
                        }}
                        onClick={() => GenerateResult(student.fromHistory
                          ? student.student_id
                          : student._id)}
                        disabled={loadingStates[student.fromHistory
                          ? student.student_id
                          : student._id]}
                      >
                        {loadingStates[student.fromHistory
                          ? student.student_id
                          : student._id] ? (
                          <>
                            <Spinner size='sm' /> Generating Result...
                          </>
                        ) : (
                          'Generate Result'
                        )}
                      </Button>
                    </td>
                  </tr>
              ))
            ) : (
                  <tr>
                      <td colSpan="6" className="text-center">
                      <FaFileAlt className="mt-3" size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">No Students Found</h4>
                    </td>
                  </tr>
                )}
          </tbody>
        </Table>
        <style jsx='true'>{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
      </div>
    </div>
  )

  return (
    <>
     {/* <button onClick={handleBackClick} className="floating-back-button">
        <FiArrowLeft size={24} />  
      </button> */}


      <div style={{ position: 'relative' }}>
      {/* Floating Back Button */}
      <button
        onClick={handleBackClick}
        className="floating-back-button"
        style={{
          position: 'fixed',  // Fix the button in place
          buttom: '20px',        // Adjust top position as needed
          left: '280px',       // Adjust left position as needed
          zIndex: 2,       // Ensure it's on top of all other content
          backgroundColor: '#FFFFFF',
          border: 'none',
          borderRadius: '50%', // Circular button
          padding: '10px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: shadow for better visibility
        }}
      >
        <FiArrowLeft size={24} />
      </button>

      
    </div>
      <Card
        style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}
        className='p-1 px-3'
      >
       <div className="d-flex justify-content-between align-items-center  ">
       <CardTitle tag="h4" className='mt-2' >
            Result
          </CardTitle>
        
        </div>
      </Card>
      <Card
        className=' px-3 pt-0'
        style={{ borderRadius: '0', minHeight: '400px' }}
      >
        {renderTable()}
      </Card>

      {/* Modal to View Marksheet */}
      <Modal isOpen={modalOpen} toggle={closeModal} size='lg'>
        <ModalHeader toggle={closeModal}>View Marksheet</ModalHeader>
        <ModalBody>
          {marksheetUrl.url ? (
            <iframe
              src={marksheetUrl.url}
              title='Result PDF Preview'
              style={{ width: '100%', height: '420px', objectFit: 'contain' }}
            />
          ) : (
            <p>No marksheet available.</p>
          )}
        </ModalBody>
        <style>
          {`
          @media (max-width: 992px) {
            .responsive-row {
              width: 100%; /* 100% for small screens */
            }
          }
          @media (min-width: 992px) {
            .responsive-row {
              width: 70%; /* 50% for md and larger screens */
            }
          }
        `}
        </style>
        <ModalFooter className='d-flex flex-column flex-md-row justify-content-between align-items-start'>
          {/* Responsive button container */}
          <div className='mb-2 mb-lg-0'>
            <Button
              className='me-2'
              color='primary'
              onClick={() => handleDownload(marksheetUrl, 'Result')}
            >
              Download
            </Button>
            <Button color='secondary' onClick={closeModal}>
              Close
            </Button>
          </div>

          {/* Email input field */}
          <Row className=' d-flex m-0 p-0 mt-1 responsive-row   text-end justify-content-center'>
            <Col xs={12} lg={7} className=' mt-lg-0 p-0 '>
              <FormGroup className='p-0 pe-lg-1 pe-0'>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  className='mt-2 mt-lg-0'
                  value={email} // Bind the local state value
                  onChange={e => setEmail(e.target.value)} // Update state on change
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} lg={5} className='p-0'>
              <Button
                color='primary'
                className='mt-2 mt-lg-0 w-100'
                onClick={() => SendResult(resultsending.studentId)}
              >
                {resultsending.sending ? (
                  <>
                    <Spinner size='sm' /> Sending Result...
                  </>
                ) : (
                  'Send mail'
                )}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ExamStudents
