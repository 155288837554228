import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import axiosInstance from "../../../middleware/axiosInstance";
import Select from "react-select";

const CreateClass = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    className: "",
    branchName: "",
    branchId: "",
    classId:"",
    sections: [],
  });

  const [errors, setErrors] = useState({
    className: "",
    classId:"",
    branchName: "",
    branchId: "",
    sections: [],
  });

  const [branches, setBranches] = useState([]);
  const [existingClasses, setExistingClasses] = useState([]);
  const [sectionInput, setSectionInput] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);


  const [classOptions, setClassOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axiosInstance.get("/fetchclasses");
        if (response.data.success && response.data.data) {
          // Map the data to `react-select` format
          const options = response.data.data.map((item) => ({
            label: item.name,
            value: item._id,
          }));
          setClassOptions(options);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, []);
  const handleClassChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      classId: value,
    }));
  };
  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get("/branches");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };



  useEffect(() => {
    fetchBranches();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "className") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;
    
      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "ClassName must be less than 40 words.",
        });
        return;
      }
      else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
        return;
      }
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  
  };


  const options = branches.map((branch) => ({
    value: branch._id,
    label: branch.branchName,
  }));

   const handleBranchChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      branchId: value,
    }));
  };

  const handleSectionInputChange = (e) => {
    const { value } = e.target;

    const words = value.trim().split(/\s+/);
    const wordCount = words.length;

    if (wordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sections: "Section name must be 40 words or less",
      }));
    }
     else if (words.some((word) => word.length > 50)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sections: "Each word must be 50 characters or less",
      }));
    }
     else {
      setSectionInput(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        sections: "",  
      }));
    }
  };

  const addSection = () => {
    const trimmedSectionInput = sectionInput.trim();

    if (trimmedSectionInput === "") {
      Swal.fire({
        title: "Warning!",
        text: "Section Name Cannot Be Empty",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit the function if input is empty
    }
    if (isEditing && formData.sections[editIndex] === trimmedSectionInput) {
      setIsEditing(false);
      setEditIndex(-1);
      setSectionInput("");
      return;
    }
    
    const isDuplicate = formData.sections.some(
      (section) =>
        section.trim().toLowerCase() === trimmedSectionInput.toLowerCase()
    );

    if (isDuplicate) {
      Swal.fire({
        title: "Warning!",
        text: "Section already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isEditing) {
      const updatedSections = [...formData.sections];
      updatedSections[editIndex] = trimmedSectionInput;
      setFormData({ ...formData, sections: updatedSections });
      setIsEditing(false);
      setEditIndex(-1);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        sections: [...prevData.sections, trimmedSectionInput],
      }));
    }

    setSectionInput("");
  };

  const editSection = (index) => {
    setSectionInput(formData.sections[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  const deleteSection = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((_, i) => i !== index),
    }));
    if (isEditing && editIndex === index) {
      setSectionInput('');
      setIsEditing(false);
      setEditIndex(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

     if (formData.className) {
      const words = formData.className.trim().split(/\s+/);
      if (words.length > 40 || words.some((word) => word.length > 50)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          className:
            "Class Name must be less than 40 words and each word must be less than 50 characters.",
        }));
        return;
      }
    }

     if (formData.sections) {
      for (let section of formData.sections) {
        const words = section.trim().split(/\s+/);
        if (words.length > 40 || words.some((word) => word.length > 50)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            sections:
              "Sections must be less than 40 words and each word must be less than 50 characters.",
          }));
          return;
        }
      }
    }

     const existingClass = existingClasses.find(
      (cls) =>
        cls.className === formData.className &&
        cls.branch_id === formData.branchName
    );

    try {
      await axiosInstance.post("/createclass", formData);
      navigate("/classes");
    } catch (err) {
      console.error("Error processing class:", err);

    }
  };

  const handleCancel = () => {
    navigate("/classes");
  };

  useEffect(() => {
    if (branches.length > 0 && !formData.branchId) {
       setFormData((prevFormData) => ({
        ...prevFormData,
        branchId: "",  
      }));
    }
  }, [branches, formData.branchId]);

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis', 
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2' 
        : '#FFFFFF', 
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };



  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-4 text-center">
            Add New Class
          </CardTitle>
          <Form onSubmit={handleSubmit}>
            <Row>
{/*             
              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="className" className=" fw-bold">Class*</Label>
                  <Input
                    type="text"
                    name="className"
                    id="className"
                    value={formData.className}
                    onChange={handleChange}
                    placeholder="Enter Class Name"
                    required
                  />
                  {errors.className && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.className}
                    </div>
                  )}
                </FormGroup>
              </Col> */}

              <Col xs={12} sm={6} xl={3}>
                <FormGroup>
                  <Label for="classId" className="fw-bold">
                    Class*
                  </Label>
                  <Select
                    id="classId"
                    options={classOptions}
                    value={
                      classOptions.find(
                        (option) => option.value === formData.classId
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleClassChange(selectedOption ? selectedOption.value : "")
                    }
                    placeholder="Select a Class"
                    isClearable
                    required
                    styles={customStyles}
                  />
                  {errors.classId && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.classId}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="branchId" className=" fw-bold">
                    Branch*
                  </Label>
                  <Select
                    id="branchId"
                    options={options}
                    value={
                      options.find(
                        (option) => option.value === formData.branchId
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleBranchChange(
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    placeholder="Select a Branch"
                    isClearable
                    required
                    styles={customStyles}
                  />
                  {errors.branchId && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.branchId}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="sectionInput" className=" fw-bold">
                    Section
                  </Label>
                  <Input
                    type="text"
                    id="sectionInput"
                    value={sectionInput}
                    onChange={handleSectionInputChange}
                    placeholder="Enter Section Name"
                  />
                  {errors.sections && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.sections}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}   xl={3} className="mt-sm-2">
                <Button
                  color="primary"
                  className="mt-sm-4 btn-no-radius"
                  onClick={addSection}
                >
                  {isEditing ? "Update Section" : "Add Section"}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt-3">
              {formData.sections.length > 0 && (
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Section</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.sections.map((section, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{section}</td>
                        <td>
                          <FaRegEdit
                            className="mx-2"
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() => editSection(index)}
                          />
                          <AiOutlineDelete
                            className="mx-2"
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() => deleteSection(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                 )}

              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button className="btn-no-radius" color="primary" type="submit">
                Save
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={handleCancel}
                className="btn-no-radius mx-2"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateClass;
