

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, CardBody, CardTitle, Col, Row, Form, FormGroup, Label, Input, Spinner ,CardText, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaFileAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { FaFileInvoice, FaMoneyBillWave , FaRegEdit,FaDownload} from 'react-icons/fa';
import { FaPrint } from 'react-icons/fa';
import axiosInstance from '../../../../middleware/axiosInstance';
import Select from 'react-select';
import { AiOutlineEye } from "react-icons/ai";
import { format } from "date-fns";






const DetailsTransaction = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        studentId: '',
        studentName: '',
        address: '',
        registerNo: '',
        mobileNumber: '',
        email: '',
        academicYear: '',
        className: '',
        branchName: '',
        sectionName: '',
        finalAmount: '',
        paidAmount: '',
        receivedAmount: '',
        dueDate: '',
        paymentDate: '',
        paymentMethod: '',
        fine: '',
        discount: '',
        status: '',
    });

    const [fee, setFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [studentData, setStudentData] = useState(null);
    const [academicYears, setAcademicYears] = useState([]);
    const [modal, setModal] = useState(false);
    const [transactions,setTransactions] = useState(null)
 const [transSubHistory,setTransSubHistory] = useState([])    // const  toggle =  async (transaction) => {
    //   console.log("transaction",transaction.transaction._id); // Ensure the transactionId is logged correctly
    //  const transactionId = transaction?.transaction?._id
    //   // const fetchStudentData = async () => {
    //     try {
    //       // Correctly pass the transactionId to the API endpoint
    //       const response = await axiosInstance.get(`/transaction-history/${id}`,transactionId); 
    //       console.log("response", response.data);
    //       // setStudentData(response.data); // Uncomment this to set student data if needed
    //     } catch (err) {
    //       console.error("Error fetching student data:", err);
    //     }
    //   // };
    
    // //  fetchStudentData();
    //   setModal(!modal); // Toggle modal state
    // };

    const toggle = async (transaction) => {
      const transactionId = transaction?.transaction?._id; 
      // console.log("transactionId", transactionId); 
    if(modal===false)
    {

      try {
       
        const response = await axiosInstance.post(`/transaction-history/${id}`, { transactionId }); 
        console.log("response", response.data.transactions);
        setTransSubHistory(response.data.transactions)
        
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    
    };
      setModal(!modal);   
  }


      

  


    const getInitial = (name) => {
        if (!name) return "";
        return name.charAt(0).toUpperCase();
      };

      useEffect(() => {
        const fetchStudentData = async () => {
          try {
            const response = await axiosInstance.get(`/singlestudent/${id}`);
            setStudentData(response.data);
          } catch (err) {
            console.error("Error fetching student data:", err);
          }
        };
    
        fetchStudentData();
      }, [id]);

      useEffect(() => {
        const fetchAcademicYears = async () => {
            try {
                const response = await axiosInstance.get(`/getUniqueAcademicYearsByStudentId/${id}`);
                if (response.data.academicYears) {
                    setAcademicYears(response.data.academicYears); // Ensure you're accessing the correct data structure
                }

                console.log("khabd",response.data.academicYears)
            } catch (error) {
                console.error('Error fetching academic years:', error);
            }
        };

        fetchAcademicYears();
    }, [id]);

    const handleAcademicYearChange = (selectedOption) => {
        setFormData({ ...formData, academicYear: selectedOption ? selectedOption.value : '' });
    };



    useEffect(() => {
        const fetchTransaction = async () => {
            try {
                const data = { academicYear: formData.academicYear };
                const response = await axiosInstance.post(`/getTransactionHistoryById/${id}`, data);
                console.log("trans",response.data.transactionHistories);
                setFee(response.data.transactionHistories);
            } catch (err) {
              console.log ("error",err)
                console.error("Error fetching Transactions:", err);
            }
        };
        if (formData.academicYear) {
            fetchTransaction();
        }
    }, [id, formData.academicYear]);
    

    useEffect(() => {
       
        
        const fetchFeeDetails = async () => {
            try {
                // Make the API request using axiosInstance
                const response = await axiosInstance.get(`/singlefees/${id}`);
        
                // Extract the fetched fee details
                const fetchedFee = response.data.data;
                setFee(fetchedFee);
                console.log('dcdf',fee);
        
                // Update form data state
                setFormData({
                    studentId: fetchedFee.student_id._id,
                    registerNo: fetchedFee.student_id.registerNo,
                    address: fetchedFee.student_id.address,
                    studentName: `${fetchedFee.student_id.firstName} ${fetchedFee.student_id.fatherName} ${fetchedFee.student_id.lastName}`,
                    finalAmount: fetchedFee.finalAmount || '0',
                    paidAmount: fetchedFee.paidAmount || '0',
                    receivedAmount: '',
                    branchName: fetchedFee.branch_id.branchName,
                    academicYear: fetchedFee.academicyear_id.academicYearName,
                    className: fetchedFee.class_id.className,
                    sectionName: fetchedFee.section_id ? fetchedFee.section_id.sectionName : '',
                    dueDate: fetchedFee.dueDate ? new Date(fetchedFee.dueDate).toISOString().split('T')[0] : '',
                    mobileNumber: fetchedFee.student_id.mobileNumber || '',
                    email: fetchedFee.student_id.email || '',
                    classFees: fetchedFee.classFees || '',
                    discount: fetchedFee.discount || '0',
                    fine: fetchedFee.fine || '0',
                    status: fetchedFee.status
                });
        
              
            } catch (error) {
                console.error('Error fetching fee details:', error);
            }
        };
        
      
        fetchFeeDetails();
    }, [id]);




    const customStyles = {
      placeholder: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis', 
        overflow: 'hidden',
      }),
      control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
        borderWidth: state.isFocused ? '0px' : '1px',
        transition: 'border-color 0.2s, box-shadow 0.2s',
        '&:hover': {
          borderColor: '#FB0F01',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#FFFFFF',
        zIndex: 999,
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? '#FDD7C2' 
          : '#FFFFFF', 
        color: 'black',
        '&:hover': {
          backgroundColor: '#FDD7C2',
          color: 'black',
        },
        '&:active': {
          backgroundColor: '#E8F0FE',
        },
      }),
    };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        
         if (isNaN(date.getTime()) || date.toISOString().startsWith('1970-01-01')) {
          return "N/A";
        }
      
        return format(date, "MMMM dd, yyyy");
      };
      
      const handleDownloadClick = () => {

        // Navigate to the receipt page with the transaction ID
        navigate(`/details/transaction/receipt`);
      };
    

    return (
        <Card>
            <div className=' p-1 my-1 d-flex justify-content-end align-items-end '>
               <Button className="btn-no-radius " color="primary">
               <NavLink
                    to={`/create/transaction/${id}`}
                    style={{ textDecoration: 'none',  display: 'flex' }}
                >
                    <FaMoneyBillWave  className='text-white mt-2' />
                    <CardTitle className='mx-1 mt-1  text-white m-0'>Collect Fees</CardTitle>
                </NavLink>
               </Button>
            </div>
            <hr className='mt-0'></hr>
            <div className='invoice'>
            <div className="">
              <Row className='m-0 p-0'>
                <Col md={4} style={{ display: "flex", justifyContent: "center" }}>
                  {studentData?.image ? (
                    <img
                      src={studentData.image || "default-image-url"}
                      alt="Student"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        marginTop: "10px",
                      }}
                    />
                  ) : (
                    <div
                      className="square mt-2 "
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#fb0f01",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "60px",
                        fontWeight: "bold",
                      }}
                    >
                      {getInitial(studentData?.firstName || "S")}
                    </div>
                  )}
                </Col>

                <Col md={8} className="p-2">
                  <CardTitle tag="h4">
                    {studentData
                      ? `${studentData.firstName} ${studentData.lastName}`
                      : "Student Name"}
                  </CardTitle>

                  <CardText className="p-0 mb-2">
                    <strong >Academic Year:</strong> {studentData?.academicyear_id?.year || "N/A"}
                  </CardText>
                  <CardText className="p-0 mb-2">
                    <strong>Register Number:</strong> {studentData?.registerNo || "N/A"}
                  </CardText>
                  <CardText className="p-0 mb-2">
                    <strong>Branch:</strong> {studentData?.branch_id?.branchName || "N/A"}
                  </CardText>
                  <CardText className="p-0 mb-2">
                    <strong>Class:</strong> {studentData?.class_id?.className || "N/A"}
                    {studentData?.section_id
                      ? ` (${studentData.section_id.sectionName})`
                      : ""}
                  </CardText>
                  <CardText className="p-0 mb-3">
                    <strong>Roll Number:</strong> {studentData?.roll || "N/A"}
                  </CardText>
                </Col>
              </Row>
            </div>
                <hr></hr>
                <div className=' p-3 mt-2'>
                    <CardTitle tag="h5" className="text-start mb-3 me-5 mt-1">Transaction Details:</CardTitle>
                    <Col xs={12} sm={6} md={4} xl={3} className="mb-4">
 
                      <Select
                        name="academicYear"
                        id="academicYear"
                        options={academicYears.map(ay => ({
                          value: ay._id, // _id for value
                          label: ay.year  // year for label
                        }))}
                        value={formData.academicYear
                          ? {
                              value: formData.academicYear, 
                              label: academicYears.find(ay => ay._id === formData.academicYear)?.year || "Select Academic Year" // Ensure label is set correctly
                            }
                          : null
                        }
                        onChange={handleAcademicYearChange} // Handles when user selects an option
                        isClearable // Allows clearing the selected option
                        isSearchable // Allows searching in the dropdown
                        placeholder="Select Academic Year"
                        styles={customStyles} // Custom styles passed
                        required
                      />

                  </Col>
                        <div className='w-100 border border-1 mb-4'>

                          <div style={{ overflow: 'auto' }}> {/* Overflow for the table container */}
                            <Table bordered className='mb-0' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                              <thead>
                                <tr>
                                  <th>S. No</th>
                                  <th>Fees Type</th>
                                  <th>Total Fees</th>
                                  <th>Discount</th>
                                  <th>Fine</th>
                                  <th>Total Payable Fees</th>
                                  <th>Remaining Amount</th>
                                  <th>Paid</th>
                                  <th>Next Due Date</th>
                                  <th>Status</th>   
                                  <th>History</th>
                                </tr>
                              </thead>
                              <tbody>
                              {fee && fee.map((transaction, index) => (
                                
                                <tr key={transaction._id}>
                                  <td>{index + 1}</td>
                                  <td>{transaction.transaction.feestype_id.feesType}</td>
                                  <td>{transaction.transaction.feestype_id.amount}</td>
                                  <td>{transaction.transaction.totalDiscount}</td>
                                  <td>{transaction.transaction.paidFine}</td>
                                  <td>{transaction.transaction.totalPayableAmount}</td>
                                  <td>{transaction.transaction.balance}</td>
                                 
                                  <td>{transaction.transaction.paidAmount}</td>
                                  <td>{formatDate(transaction.transaction.dueDate)}</td>
                                  <td>{transaction.transaction.status}</td>
                                  <td><AiOutlineEye title="Transaction History" onClick={() => toggle(transaction)} style={{ cursor: 'pointer' }} className="ms-3" /></td>
                                </tr>
                              ))}
                              </tbody>
                            </Table>
                          </div>

                          <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: '75%' }}>
                            <ModalHeader toggle={toggle}>Transaction History</ModalHeader>
                            <ModalBody>   
                              <div style={{ overflow: 'auto' }}>
                                <Table bordered className='mb-0' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                  <thead>
                                    <tr>
                                      <th>S. No.</th>
                                      <th>Payment Date</th>
                                      <th>Payment Method</th>
                                      <th>Amount Paid</th>
                                      <th> </th>
                                    </tr>
                                  </thead>
                                  {/* <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>March 29, 2022</td>
                                      <td>Cash</td>
                                      <td>5000</td>
                                      <td className=' text-center'>
                                      <FaRegEdit  style={{ cursor: 'pointer' }}  className=' ms-2 m-0'/>
                                      <FaDownload style={{ cursor: 'pointer' }}  onClick={handleDownloadClick}   className=' ms-4 m-0'/>
                                      </td>
                                    </tr>
                                   
                                  </tbody> */}
                                  <tbody>
                              {transSubHistory && transSubHistory.map((transaction, index) => (
                                
                                <tr key={transaction._id}>
                                  <td>{index + 1}</td>
                                  <td>{formatDate(transaction.paymentDate)}</td>
                                  <td>{transaction.paymentMethod}</td>
                                 
                                  <td>{transaction.amount}</td>
                                  <td><AiOutlineEye title="Transaction History" onClick={() => toggle(transaction)} style={{ cursor: 'pointer' }} className="ms-3" /></td>
                                </tr>
                              ))}
                              </tbody>
                                </Table>
                              </div>
                            </ModalBody>
                          </Modal>
                        </div>


                </div>
            
            </div>
           
        </Card>
    );
};

export default DetailsTransaction;


