import axios from 'axios';
import { store } from '../Redux/Store'; 
import { logoutSuccess, refreshToken } from '../Redux/AuthRelated/AuthSlice';
import Swal from 'sweetalert2';


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {

    if (response.data.message) {
      Swal.fire({
        title: response.data.message.title,
        text: response.data.message.text,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }
    return response;
  },
  async (error) => {
    const { response, config } = error;
  

    if (response && response.status === 401 && !config._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          config.headers['Authorization'] = `Bearer ${token}`;
          return axiosInstance(config);
        }).catch(err => Promise.reject(err));
      }

      config._retry = true;
      isRefreshing = true;

      try {
        const { token } = await store.dispatch(refreshToken()).unwrap();
        config.headers['Authorization'] = `Bearer ${token}`;
        processQueue(null, token);
        return axiosInstance(config); 
      } catch (err) {
        processQueue(err, null);
        Swal.fire({
          title: 'Session Expired',
          text: 'Your session has expired. Please log in again.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          store.dispatch(logoutSuccess()); 
        });
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    if (response) {
      switch (response.status) {
        case 400:
          Swal.fire({
            title: 'Warning!',
            text: response.data.message || 'There was a problem with your request.',
            icon: 'warning',
            confirmButtonText: 'OK',
          });
          break;
        case 403:
          Swal.fire({
            title: 'Forbidden!',
            text: 'You do not have permission to access this resource.',
            icon: 'warning',
            confirmButtonText: 'OK',
          });
          break;
        case 404:
          console.warn('Resource not found:', response.data.message || 'The requested resource could not be found.');
          break;
          case 402:
            window.location.href = '/planexpired';
          break;
        default:
          Swal.fire({
            title: 'Error!',
            text: response.data.message || 'An unexpected error occurred.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
          break;
      }
    } else {
      console.warn('Server Issue or Network Issue');
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

