import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";

const EditSubject = () => {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    subject: "",
    branchName: "",

  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [errors, setErrors] = useState({
    subject: "",
  });

  const fetchSubject = async () => {
    try {
      const response = await axiosInstance.get(`/details/subject/${id}`);
      const fetchedSubject = response.data;
  
      setFormData({
        branchName: fetchedSubject.branch_id.branchName,
        subject: fetchedSubject.subject, // Assuming `subjectName` is the field for the subject name
      });
    } catch (error) {
      console.error("Error fetching subject details:", error);
    } finally {
      setInitialLoading(false);
    }
  };
  

  useEffect(() => {
    fetchSubject();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });


    // Validation logic for className
 if (name === "subject") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      // Check if word count exceeds 20
      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "subject must be less than 40 words.",
        });
      }
      // Check if any word exceeds 30 characters
      else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
      }
      // No errors
      else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  const handleEditSubject = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.put(`/update/subject/${id}`, formData);
      navigate("/subjects");
    } catch (error) {
      console.error("Error updating subject:", error);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/subjects");
  };


  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Edit Subject
          </CardTitle>
          {initialLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Form className="mt-5" onSubmit={handleEditSubject}>
              <Row>
                <Col xs={12} sm={6} md={6} xl={4}>
                  <FormGroup>
                    <Label for="branch">
                      <span style={{ fontWeight: "bold" }}>Branch*</span>
                    </Label>
                    <Input
                      type="text"
                      name="branch"
                      id="branch"
                      value={formData.branchName} // Use branchName from formData
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={6} md={6} xl={4}>
                  <FormGroup>
                    <Label for="subject">
                      <span style={{ fontWeight: "bold" }}>Subject Name*</span>
                    </Label>
                    <Input
                      type="text"
                      name="subject"
                      id="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      placeholder="Enter Subject Name"
                    />
                    {errors.subject && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "0.25rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {errors.subject}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  className="btn-no-radius"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Save"}
                </Button>
                <Button
                  className="btn-no-radius mx-2"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EditSubject;
