import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../middleware/axiosInstance'

const EditReceiver = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    branchName: null
  })

  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    mobileNumber: ''
  })

  // Fetch staff details
  const fetchStaff = async () => {
    try {
      const response = await axiosInstance.get(`fees/details/receiver/${id}`)
       const fetchedStaff = response.data
      //  console.log("fetchedStaff",fetchedStaff)


      setFormData({
        fullName: fetchedStaff.fullName,
        email: fetchedStaff.email,
        mobileNumber: fetchedStaff.mobileNumber,
        branchName: fetchedStaff.branch_id.branchName
      })
    } catch (error) {
      // console.error('Error fetching staff details:', error)
    } finally {
      setInitialLoading(false)
    }
  }

  // Fetch branches for dropdown
  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get('/branches')
      setBranches(response.data)
    } catch (error) {
      // console.error('Error fetching branches:', error)
    }
  }

  useEffect(() => {
    fetchStaff()
    fetchBranches()
  }, [id])

  

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Validation logic
    switch (name) {
      case "fullName":
  if (!value.trim()) {
    // Set error for empty value
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "Full Name is required.",
    }));

    // Allow updating the state to reflect the empty field
    setFormData({ ...formData, [name]: value });
    return;
  } else if (value.length > 100) {
    // Set error for exceeding length
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "Full Name must not exceed 100 characters.",
    }));
    return; // Prevent updating state if error exists
  } else {
    // Clear errors when input is valid
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: "",
    }));
  }

  // Update state when there are no errors
  setFormData({ ...formData, [name]: value });
  break;

  
  case "email":
  if (value.trim().length > 2000) {
    // Set error for exceeding maximum length
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "Email must be less than or equal to 2000 characters.",
    }));
    return; // Prevent updating state if error exists
  }

  // Clear errors when input is valid
  setErrors((prevErrors) => ({ ...prevErrors, email: "" }));

  // Update state when there are no errors
  setFormData({ ...formData, [name]: value });
  break;

 
  case "mobileNumber":
  // Allow only numeric input (reject special characters and letters)
  if (!/^\d*$/.test(value)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "Only numeric digits are allowed.",
    }));

    // Remove error after 2 seconds
    setTimeout(() => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }, 2000);

    return; // Prevent further processing
  }

  // Handle errors for input greater than 10 digits
  if (value.length > 10) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "Mobile Number must be exactly 10 digits.",
    }));

    // Remove error after 2 seconds
    setTimeout(() => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }, 2000);

    return; // Prevent adding further data
  }

  // Allow only up to 10 numeric digits
  if (/^\d{0,10}$/.test(value)) {
    setFormData({ ...formData, [name]: value });

    // Validation logic
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      })); // Clear error if field is empty
    } else if (value.length === 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      })); // Clear error if valid
    } else if (value.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Mobile Number must be exactly 10 digits.",
      }));
    }
  }
  break;


      default:
        break;
    }
  
    // Update state only if validation passes
    setFormData({ ...formData, [name]: value });
  };
  
  
  const handleEditStaff = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      await axiosInstance.put(`fees/update/receiver/${id}`, formData)
      navigate('/receivedby')
    } catch (error) {
      // console.error('Error updating staff:', error)
    }
    setLoading(false)
  }

  const handleCancel = () => {
    navigate('/receivedby')
  }

  return (
    <div>
      <Card style={{ margin: '0 auto' }}>
        <CardBody>
          <CardTitle tag='h3' className='mb-1 text-center'>
            Edit Received By
          </CardTitle>
          {initialLoading ? (
            <div className='text-center'>
              <Spinner color='primary' />
            </div>
          ) : (
            <Form className='mt-5' onSubmit={handleEditStaff}>
              <Row>
              <Col xs={12} sm={6} md={4}  xl={3}>
                  <FormGroup>
                    <Label for='branchName'>
                      <span style={{ fontWeight: 'bold' }}>Branch*</span>
                    </Label>
                    <Input
                      type='text'
                      name='branchName'
                      id='branchName'
                      value={formData.branchName}
                      onChange={handleChange}
                      required
                      disabled
                    >
                      
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}  xl={3}>
                  <FormGroup>
                    <Label for='fullName'>
                      <span style={{ fontWeight: 'bold' }}>Full Name*</span>
                    </Label>
                    <Input
                      type='text'
                      name='fullName'
                      id='fullName'
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                      placeholder='Enter Full Name'
                    />
                    {errors.fullName && (
                      <div   style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}>{errors.fullName}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}  xl={3}>
                  <FormGroup>
                    <Label for='email'>
                      <span style={{ fontWeight: 'bold' }}>Email*</span>
                    </Label>
                    <Input
                      type='email'
                      name='email'
                      id='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder='Enter Email'
                    />
                    {errors.email && (
                      <div   style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}>{errors.email}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}  xl={3}>
                  <FormGroup>
                    <Label for='mobileNumber'>
                      <span style={{ fontWeight: 'bold' }}>Mobile Number*</span>
                    </Label>
                    <Input
                      type='text'
                      name='mobileNumber'
                      id='mobileNumber'
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      required
                      placeholder='Enter Mobile Number'
                    />
                    {errors.mobileNumber && (
                      <div   style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}>
                        {errors.mobileNumber}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                
              </Row>
              <div className='d-flex justify-content-center mt-3'>
                <Button
                  className='btn-no-radius'
                  type='submit'
                  color='primary'
                  disabled={loading}
                >
                  {loading ? <Spinner size='sm' /> : 'Save'}
                </Button>
                <Button
                  className='btn-no-radius mx-2'
                  color='secondary'
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default EditReceiver
