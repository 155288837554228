// Validation function
export const validateNameWithSpecial = (name, fieldName) => {
    const words = name.trim().split(/\s+/);
    const wordCount = words.length;
  
    // Check for word count limits
    if (wordCount < 1 || wordCount > 40) {
      return `${fieldName} must be between 1 to 40 words.`;
    }
  
    // Check for individual word length limits
    const wordLengthExceeded = words.some(word => word.length > 50);
    if (wordLengthExceeded) {
      return `Each word in ${fieldName} must be up to 50 characters long.`;
    }
  
    // Check for valid characters
    const isValid = /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(name);
    if (!isValid) {
      return `${fieldName} must contain only letters, numbers, spaces, and special characters.`;
    }
  
    return ''; // No errors
  };

  // Validation function
export const validateName = (name, fieldName) => {
    const words = name.trim().split(/\s+/);
    const wordCount = words.length;
  
    // Check for word count limits
    if (wordCount < 1 || wordCount > 40) {
      return `${fieldName} must be between 1 to 40 words.`;
    }
  
    // Check for individual word length limits
    const wordLengthExceeded = words.some(word => word.length > 50);
    if (wordLengthExceeded) {
      return `Each word in ${fieldName} must be up to 50 characters long.`;
    }
    return ''; // No errors
  };



export const validatePincode = (pincode) => {
    const pincodeRegex = /^[1-9][0-9]{5}$/; // Pincode must be a 6-digit number starting from 1-9

    // Allow empty input (no error)
    if (pincode === '') {
        return '';
    }

    // Validate pincode format
    if (!pincodeRegex.test(pincode)) {
        return 'Pincode must be a 6-digit number starting from 1-9.';
    }

    return ''; // No errors
};

export const validatePhoneno = (phoneno) => {
    const PhonenoRegex = /^[1-9][0-9]{9}$/; 

    // Allow empty input (no error)
    if ( phoneno=== '') {
        return '';
    }

    // Validate pincode format
    if (!PhonenoRegex.test(phoneno)) {
        return 'Phoneno must be a exact 10-digit number';
    }

    return ''; // No errors
};

export const validateTenDigitCode = (code, fieldName) => {
    const tenDigitRegex = /^[0-9]{10}$/; 

    // If the input is empty, no error
    if (code === '') {
        return '';
    }

    if (!tenDigitRegex.test(code)) {
        return `${fieldName} must be upto 10-digit number.`;
    }
    return '';
};


// validation.js

// export const validateMaxDigits = (value, maxDigits = 10) => {
//     // Ensure the value is numeric and doesn't exceed max digits
//     const regex = new RegExp(`^-?\\d{0,${maxDigits}}$`);
//     if (!regex.test(value)) {
//       return {
//         isValid: false,
//         errorMessage: `Input must be a upto ${maxDigits} digits.`,
//       };
//     }
  
//     return {
//       isValid: true,
//       errorMessage: '',
//     };
//   };
  

export const validateMaxDigits = (value, fieldName, maxDigits = 10) => {
  // Ensure the value is numeric and doesn't exceed max digits
  const regex = new RegExp(`^-?\\d{0,${maxDigits}}$`);
  if (!regex.test(value)) {
    return {
      isValid: false,
      errorMessage: `${fieldName} must be up to ${maxDigits} digits.`,
    };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

 // New function to validate discount
export const validateDiscount = (value) => {
    // Allow empty discount value
    if (value === "") {
        return {
            isValid: true,
            errorMessage: '', // No error for empty value
        };
    }

    const discountValue = parseFloat(value);

    // Check if it's not a number, less than 0, or greater than 100
    if (isNaN(discountValue) || discountValue < 0 || discountValue > 100) {
        return {
            isValid: false,
            errorMessage: 'Discount must be a number between 0 and 100.',
        };
    }

    return {
        isValid: true,
        errorMessage: '',
    };
};





