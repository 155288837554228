import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { format } from "date-fns";
import Swal from "sweetalert2";

const EditExamSubject = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    branchName: "",
    className: "",
    academicYear: "",
    examName: "",
    subjectName: "",
    examTypeName: "",
    academicYearId: "",
    branchId: "",
    classId: "",
    examId: "",
    subjectId: "",
    examTypeId: "",
    marks: [],
    subjects: [],
    examDate: "",
    examTime: "",
    examEndTime: "",
    totalMarks: "",
    passingMarks: "",
    grades: [],
  });

  const [initialLoading, setInitialLoading] = useState(true);
  const [examType, setExamType] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); // To track which subject is being edited
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [isGrade, setIsGrade] = useState(false);
  const [errors, setErrors] = useState({
    subjectName: "",
    subjects: [],
  });

  const fetchSubject = async () => {
    try {
      const response = await axiosInstance.get(`/exam/subject/${id}`);
      const fetchSubject = response.data;

      let isGradeValue = false; // Default value for isGrade

      if (
        fetchSubject.examSubject &&
        fetchSubject.examSubject.ExamType && // Ensure ExamType is not undefined
        Array.isArray(fetchSubject.examSubject.ExamType) && // Check if it's an array
        fetchSubject.examSubject.ExamType.length === 0 // Check if it's an empty array
      ) {
        // If ExamType is empty and isGrade is true, fetch from subDetails
        const { passingMarks, totalMarks, examTime, examDate, examEndTime } =
          fetchSubject.examSubject.subDetails;

        isGradeValue = fetchSubject.examSubject.isGrade; // Get the isGrade value

        setFormData({
          branchName: fetchSubject.examSubject.branch_id.branchName,
          className: fetchSubject.examSubject.class_id.className,
          academicYear: fetchSubject.examSubject.academicyear_id.year,
          examName: fetchSubject.examSubject.exam_id.examName,
          subjectName: fetchSubject.examSubject.subject_id.subject,
          examId: fetchSubject.examSubject.exam_id,
          branchId: fetchSubject.examSubject.branch_id,
          classId: fetchSubject.examSubject.class_id,
          academicYearId: fetchSubject.examSubject.academicyear_id,
          subjectId: fetchSubject.examSubject.subject_id,
          grades: fetchSubject.examSubject.Grades,
          marks: [
            {
              passingMarks,
              totalMarks,
              examTime,
              examDate: examDate ? format(new Date(examDate), "yyyy-MM-dd") : "",
              examEndTime,
            },
          ], // Use subDetails for marks
        });
      } else {
        const mappedSubjects = fetchSubject.examSubject.ExamType.map(
          (subject) => ({
            examTypeId: subject.examtype_id._id, // Map examTypeId correctly
            examTypeName: subject.examtype_id.examType, // Map examTypeName
            examDate: format(new Date(subject.examDate), "yyyy-MM-dd"),
            examTime: subject.examTime,
            examEndTime: subject.examEndTime,
            totalMarks: subject.totalMarks,
            passingMarks: subject.passingMarks,
          })
        );

        isGradeValue = fetchSubject.examSubject.isGrade; // Get the isGrade value

        setFormData({
          branchName: fetchSubject.examSubject.branch_id.branchName,
          className: fetchSubject.examSubject.class_id.className,
          academicYear: fetchSubject.examSubject.academicyear_id.year,
          examName: fetchSubject.examSubject.exam_id.examName,
          subjectName: fetchSubject.examSubject.subject_id.subject,
          examId: fetchSubject.examSubject.exam_id,
          branchId: fetchSubject.examSubject.branch_id,
          classId: fetchSubject.examSubject.class_id,
          academicYearId: fetchSubject.examSubject.academicyear_id,
          subjectId: fetchSubject.examSubject.subject_id,
          subjects: mappedSubjects, // Set mapped subjects
          grades: fetchSubject.examSubject.Grades,
          marks: [],
        });

        // Check if fetchSubject.examTypes exists and has length
        if (fetchSubject.examTypes && fetchSubject.examTypes.length > 0) {
          setExamType(
            fetchSubject.examTypes.map((examType) => ({
              value: examType.value,
              label: examType.label,
            }))
          );
        }
      }

      // Set isGrade in state if necessary
      if (isGradeValue) {
        setIsGrade(true); // Set to true if isGrade is true
      }
    } catch (error) {
      // console.error("Error fetching exam subject details:", error);
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchSubject();
  }, [id]);

  // Handle changes for examType selection
  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      examTypeName: selectedOption ? selectedOption.value : "", // Handle clear option
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === "subjectName") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          subjectName: "Subject Name must be 40 words or less.",
        }));
      } else if (words.some((word) => word.length > 50)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          subjectName: "Each word must be 50 characters or less.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          subjectName: "",
        }));
      }
    }
  };

  const addSubject = () => {
    // Find the selected exam type from the examType array
    const selectedExamType = examType.find(
      (exam) => exam.value === formData.examTypeName
    );

    // Check if the examTypeName already exists in the subjects array
    const isDuplicate = formData.subjects.some(
      (subject) => subject.examTypeName === selectedExamType.label
    );

    if (isDuplicate) {
      Swal.fire({
        icon: "warning",
        title: "Duplicate Exam Type",
        text: "This exam type already exists in the subjects list.",
      });
      return;
    }

    // Convert totalMarks and passingMarks to numbers
    const totalMarks = Number(formData.totalMarks || 0);
    const passingMarks = Number(formData.passingMarks || 0);


    // Validation for marks and times
    if (passingMarks > totalMarks) {
      Swal.fire({
        title: "Invalid Marks Entry!",
        text: `Passing marks (${passingMarks}) cannot be greater than total marks (${totalMarks}). Please correct this.`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    if (
      new Date(`1970-01-01T${formData.examTime}`) >=
      new Date(`1970-01-01T${formData.examEndTime}`)
    ) {
      Swal.fire({
        title: "Invalid Time Entry!",
        text: `Exam start time (${formData.examTime}) must be earlier than exam end time (${formData.examEndTime}).`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    // Define the new subject to be added to the formData
    const newSubject = {
      examTypeId: selectedExamType ? selectedExamType.value : "", // Store the examTypeId
      examTypeName: selectedExamType ? selectedExamType.label : "", // Store the label of the exam type
      examDate: formData.examDate || "", // Ensure default empty string if not provided
      examTime: formData.examTime || "", // Ensure default empty string if not provided
      examEndTime: formData.examEndTime || "", // Ensure default empty string if not provided
      totalMarks: formData.totalMarks || "", // Ensure default empty string if not provided
      passingMarks: formData.passingMarks || "", // Ensure default empty string if not provided
    };

    // Add the new subject to the subjects array in formData
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        subjects: [...prevData.subjects, newSubject], // Add the new subject
      };

      return updatedData;
    });

    // Clear the form fields after adding the subject
    setFormData((prevData) => {
      const clearedData = {
        ...prevData,
        examTypeName: "",
        examDate: "",
        examTime: "",
        examEndTime: "",
        totalMarks: "",
        passingMarks: "",
        marks: [],
      };

      return clearedData;
    });
  };

  const editType = (index) => {
    const subjectToEdit = formData.subjects[index];

    setFormData((prevData) => ({
      ...prevData,
      examTypeName: subjectToEdit.examTypeName || "",
      examTypeId: subjectToEdit.examTypeId || "",
      marks: [
        {
          totalMarks: subjectToEdit.totalMarks || "",
          passingMarks: subjectToEdit.passingMarks || "",
          examDate: subjectToEdit.examDate || "",
          examTime: subjectToEdit.examTime || "",
          examEndTime: subjectToEdit.examEndTime || "",
        },
      ],
    }));

    setIsEditing(true);
    setEditingIndex(index);
  };

  

const updateSubject = () => {
  const updatedSubjects = [...formData.subjects];
  
  // Ensure editingIndex is valid
  if (editingIndex === null || editingIndex < 0 || editingIndex >= updatedSubjects.length) {
    Swal.fire({
      title: "Error",
      text: "Invalid subject selected for update.",
      icon: "warning",
      confirmButtonText: "OK",
    });
    return;
  }
  
  const currentSubject = updatedSubjects[editingIndex] || {};
  const marksEntry = formData.marks?.[0] || {};

  const totalMarks = marksEntry.totalMarks || currentSubject.totalMarks || "";
  const passingMarks = marksEntry.passingMarks || currentSubject.passingMarks || "";
  const examDate = marksEntry.examDate || currentSubject.examDate || "";
  const examTime = marksEntry.examTime || currentSubject.examTime || "";
  const examEndTime = marksEntry.examEndTime || currentSubject.examEndTime || "";

  // Validation for totalMarks and passingMarks
  if (Number(passingMarks) > Number(totalMarks)) {
    Swal.fire({
      title: "Invalid Marks Entry!",
      text: `Passing marks (${passingMarks}) cannot be greater than total marks (${totalMarks}). Please correct this.`,
      icon: "warning",
      confirmButtonText: "OK",
    });
    return;
  }

  // Validation for examTime and examEndTime
  if (examTime && examEndTime && new Date(`1970-01-01T${examTime}`) >= new Date(`1970-01-01T${examEndTime}`)) {
    Swal.fire({
      title: "Invalid Time Entry!",
      text: `Exam start time (${examTime}) must be earlier than exam end time (${examEndTime}).`,
      icon: "warning",
      confirmButtonText: "OK",
    });
    return;
  }

  // Update the subject with validated data
  updatedSubjects[editingIndex] = {
    examTypeId: formData.examTypeId || currentSubject.examTypeId,
    examTypeName: formData.examTypeName || currentSubject.examTypeName,
    totalMarks,
    passingMarks,
    examDate,
    examTime,
    examEndTime,
  };

 // Update the form data and reset editing state
 setFormData((prevData) => ({
  ...prevData,
  subjects: updatedSubjects,
  examTypeName: "",
  marks: [], // Clear the marks array
}));

  setIsEditing(false);
  setEditingIndex(null);

  Swal.fire("Success", "Subject updated successfully!", "success");
};

  const deleteType = async (index) => {
    const subjectToDelete = formData.subjects[index]; // Get the subject to delete

    // Ensure examTypeId is available and valid
    const examSubjectId = id; // Use the id from useParams
    const examTypeId = subjectToDelete.examTypeId; // Safely access examTypeId

    if (!examSubjectId || !examTypeId) {
      // If IDs are not available, show an error and return
      Swal.fire("Error", "Exam Subject or Exam Type ID is missing", "error");
      return;
    }

    try {
      // Call API to check if examTypeId is associated with marks
      const response = await axiosInstance.get('exam//studentsBySubject', {
        params: { subjectId: id }, // Pass required parameters
      });

      // Safely extract data from API response
      const allExamSubjects = response.data?.data || [];
      if (!allExamSubjects.length) {
        // console.warn("No exam subjects found for the given subjectId.");
      }

      // Check if the selected examTypeId exists in any marks
      const isExamTypeUsed = allExamSubjects.some((record) =>
        record.marks?.some((mark) => mark.examtype_id === examTypeId)
      );

      if (isExamTypeUsed) {
        // If the examTypeId is found in marks, show an error and prevent deletion
        Swal.fire({
          title: "Cannot Delete",
          text:"Marks are already assigned to this Exam Type and it cannot be deleted.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      // Confirm before deleting the subject
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this subject!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // Remove the subject from the frontend state
          const updatedSubjects = [...formData.subjects];
          updatedSubjects.splice(index, 1); // Remove the subject at the selected index

          setFormData((prevData) => ({
            ...prevData,
            subjects: updatedSubjects,
            marks: [], // Optional: Clear marks if necessary after deletion
            examTypeName: "", // Optional: Clear the exam type name if necessary
          }));

          setIsEditing(false);
          setEditingIndex(null);

          // Optionally, show a success alert
          Swal.fire("Deleted!", "The subject has been deleted.", "success");
        }
      });
    } catch (error) {
      // console.error("Error fetching exam subjects:", error);
      Swal.fire(
        "Error",
        "There was an error checking exam type associations. Please try again.",
        "error"
      );
    }
  };



  const handleEditSubject = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Validation for missing marks entry
      if (
        examType.length > 0 && // Check if exam types exist
        !formData.isGrade && // Ensure grading system is not used
        formData.subjects.length === 0 // No marks entries available
      ) {
        Swal.fire({
          title: "Missing Entry!",
          text: "Please add at least one mark entry for the existing Exam type",
          icon: "warning",
          confirmButtonText: "OK",
        });
        setLoading(false); // Stop loading indicator
        return; // Prevent form submission
      }
  
      const totalMarks =
        formData.totalMarks ||
        (formData.marks.length > 0 && formData.marks[0].totalMarks); // Fallback to formData.marks[0].totalMarks if available
      const passingMarks =
        formData.passingMarks ||
        (formData.marks.length > 0 && formData.marks[0].passingMarks); // Fallback to formData.marks[0].passingMarks if available
  
  
      // Validation for passingMarks > totalMarks (only if marks length > 0)
      if (formData.marks.length > 0 && (Number(passingMarks) > Number(totalMarks))) {
        Swal.fire({
          title: "Invalid Marks Entry!",
          text: `Passing marks (${passingMarks}) cannot be greater than total marks (${totalMarks}). Please correct this.`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        setLoading(false);
        return;
      }
  
      // Validation for examTime and examEndTime
      const examTime =
        formData.examTime ||
        (formData.marks.length > 0 && formData.marks[0].examTime); // Fallback to formData.marks[0].examTime if available
      const examEndTime =
        formData.examEndTime ||
        (formData.marks.length > 0 && formData.marks[0].examEndTime); // Fallback to formData.marks[0].examEndTime if available
  
      if (
        examTime &&
        examEndTime &&
        new Date(`1970-01-01T${examTime}`) >=
          new Date(`1970-01-01T${examEndTime}`)
      ) {
        Swal.fire({
          title: "Invalid Time Entry!",
          text: `Exam start time (${examTime}) must be earlier than exam end time (${examEndTime}).`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        setLoading(false);
        return;
      }
  
      const payload = {
        subjects: formData.subjects, // Array of subjects
        totalMarks: formData.totalMarks, // Total marks
        passingMarks: formData.passingMarks, // Passing marks
        examDate: formData.examDate, // Exam date
        examTime: formData.examTime, // Exam start time
        examEndTime: formData.examEndTime, // Exam end time
        grades: formData.grades,
      };
  
      await axiosInstance.put(`/exam/subject/update/${id}`, payload);
      navigate("/exam/subject");
    } catch (error) {
      // console.error("Error updating exam subject:", error);
    } finally {
      setLoading(false);
    }
  };
  




  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const handleCancel = () => {
    navigate("/exam/subject");
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM dd, yyyy");
  };

  const addGrade = () => {
    const { grade, max_mark, min_mark } = formData;

    // Validation for empty fields
    const isMinMarkExist = formData.grades.some(
      (g) => g.min_mark === parseFloat(min_mark)
    );
    const isMaxMarkExist = formData.grades.some(
      (g) => g.max_mark === parseFloat(max_mark)
    );
    // const isGradeExist = formData.grades.some((g) => g.grade === grade);
    const isGradeExist = formData.grades.some(
      (g) => g.grade.trim().toLowerCase() === grade.trim().toLowerCase()  // Normalize to lowercase and trim spaces before comparison
  );
    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMinMarkExist) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExist) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same Max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with the same Grade already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!grade || !max_mark || !min_mark) {
      Swal.fire({
        title: "Incomplete Information",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid integers
    if (
      isNaN(max_mark) ||
      isNaN(min_mark) ||
      !Number.isInteger(parseFloat(max_mark)) ||
      !Number.isInteger(parseFloat(min_mark))
    ) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max Marks and Min Marks must be valid integers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // NEW VALIDATION: Check if max_mark is equal to min_mark
    if (parseFloat(min_mark) === parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be the same as Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Create the new grade object
    const newGrade = {
      grade,
      max_mark: parseInt(max_mark),
      min_mark: parseInt(min_mark),
    };

    // Update the grades array in formData
    setFormData((prevData) => ({
      ...prevData,
      grades: [...prevData.grades, newGrade],
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

    // Show success message
    Swal.fire({
      title: "Success!",
      text: "Grade successfully added.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const deleteGrade = (index, _id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this grade?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the grade from the grades array
        const updatedGrades = formData.grades.filter((_, i) => i !== index);
        setFormData((prevData) => ({
          ...prevData,
          grades: updatedGrades,
        }));
        Swal.fire("Deleted!", "The grade has been deleted.", "success");
      }
    });

    if(index == editIndex)
      {
    
        setIsEditing(false)
        setFormData((prevState) => ({
          ...prevState,
          grade: "",
          max_mark: "",
          min_mark: "",
        }));
      } 
  };

  const editGrade = (index, _id) => {
    // Get the selected grade object from the grades array
    const selectedGrade = formData.grades[index];

    // Set the form fields with the selected grade values
    setFormData({
      ...formData,
      grade: selectedGrade.grade,
      max_mark: selectedGrade.max_mark,
      min_mark: selectedGrade.min_mark,
      _id: selectedGrade._id,
      indexofList: index,
    });

    setIsEditing(true);
    setEditIndex(index);
  };

  const submitEditedGrade = () => {
    const { grade, max_mark, min_mark, _id, indexofList } = formData;

    const isGradeExist = formData.grades.some(
      (g, index) =>
        g.grade.trim().toLowerCase() === grade.trim().toLowerCase() && // Normalize both to lowercase and trim spaces before comparison
        (index !== indexofList || 
          g.grade.trim().toLowerCase() !== formData.grades[indexofList]?.grade.trim().toLowerCase()) // Check if grade name has changed, with trim
    );

    

    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );

    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with this name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit function if duplicate grade is found
    }

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (formData.grades.indexofList === indexofList) {
      if (isRangeOverlap) {
        Swal.fire({
          title: "Invalid Range!",
          text: "The new grade range overlaps with an existing range.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }

    // Validation for empty fields
    if (grade === "" || max_mark === "" || min_mark === "" || grade == null || max_mark == null || min_mark == null) {
      Swal.fire({
        title:"Incomplete Information",
        text: "All fields must be filled out.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid numbers
    if (isNaN(max_mark) || isNaN(min_mark)) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max percentage and Min percentage must be valid numbers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is an integer
    if (!Number.isInteger(parseFloat(min_mark))) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage must be a valid integer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum Marks.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }


    const updatedGrades = [...formData.grades];

    // Find the grade to update by indexofList or _id
    let gradeIndex;
    if (indexofList !== null && indexofList !== undefined) {
        gradeIndex = indexofList;
    } else if (_id) {
        gradeIndex = updatedGrades.findIndex((g) => g._id === _id);
    }
  
    if (gradeIndex !== -1) {
        updatedGrades[gradeIndex] = { grade, max_mark, min_mark, _id };
    } else {
        Swal.fire({
            title: "Grade Not Found",
            text: "Grade not found for editing.",
            icon: "warning",
            confirmButtonText: "OK",
        });
        return;
    }

    // Update state with the new grades array
    setFormData((prevData) => ({
      ...prevData,
      grades: updatedGrades,
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

    // Exit edit mode
    setIsEditing(false);
    setEditIndex(null);

    // Show success message
    Swal.fire({
      title: "Success!",
      text: "Grade successfully updated.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const renderTable = () => (
    <div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>% From</th>
              <th>% To</th>
              <th>Grade</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(formData.grades) && formData.grades.length > 0 ? (
              formData.grades.map((grade, index) => (
                <tr key={index}>
                  <td>{grade.min_mark}</td>
                  <td>{grade.max_mark}</td>
                  <td>{grade.grade}</td>
                  <td>
                    <FaRegEdit
                      title="Edit"
                      onClick={() => editGrade(index, grade._id)}
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                    <AiOutlineDelete
                      title="Delete"
                      onClick={() => deleteGrade(index, grade._id)}
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className=" text-center">
                <td colSpan="4" className=" h5 py-3">No grades available</td>
              </tr>
            )}
          </tbody>

          <style jsx="true">{`
              table td {
                white-space: nowrap;
              }
              table th {
                white-space: nowrap;
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }
            `}</style>
        </Table>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Edit Exam Subject
          </CardTitle>
          <Form className="mt-5 " onSubmit={handleEditSubject}>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Input
                    type="text"
                    name="branch"
                    id="branch"
                    value={formData.branchName} // Use branchName from formData
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class*</span>
                  </Label>
                  <Input
                    type="text"
                    name="class"
                    id="class"
                    value={formData.className} // Use className from formData
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label for="academicYear">
                    <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                  </Label>
                  <Input
                    type="text"
                    name="academicYear"
                    id="academicYear"
                    value={formData.academicYear} // Use academicYear from formData
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="examName">
                    <span style={{ fontWeight: "bold" }}>Exam Name*</span>
                  </Label>
                  <Input
                    type="text"
                    name="examName"
                    id="examName"
                    value={formData.examName} // Use academicYear from formData
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="subjectName">
                    <span style={{ fontWeight: "bold" }}>Subject Name*</span>
                  </Label>
                  <Input
                    type="text"
                    name="subjectName"
                    id="subjectName"
                    value={formData.subjectName}
                    onChange={handleChange}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className=" mt-xl-4 pt-xl-3 mt-2 ms-2"
              >
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="isGrade"
                      id="isGrade"
                      checked={isGrade} // Checkbox will be checked if isGrade is true
                      disabled={true} // Always disabled to prevent changes
                      style={{
                        transform: "scale(1.5)",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    Is Grade
                  </Label>
                  {/* <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Enable this option if the subject is graded and should not be included in the total exam marks"
                      className="ms-1"
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        color: "#fb0f01",
                      }}
                    >
                      <i className="bi bi-info-circle"></i>
                    </span> */}
                </FormGroup>
              </Col>
            </Row>

            <Row
              className="mt-4  pt-4 pb-3"
              style={{
                border: "1px solid #ccc", // Border around the row
                padding: "0", // Padding inside the row
                margin: "0", // Space between rows
              }}
            >
              {/* Conditionally render the Exam Type field based on examType existence */}

              {/* Always show marks fields, even if marks array is empty */}
              {(formData.marks.length > 0
                ? formData.marks
                : [
                  {
                    totalMarks: "",
                    passingMarks: "",
                    examDate: "",
                    examTime: "",
                  },
                ]
              ).map((mark, index) => (
                <Row key={index} className="m-0 p-0">
                  {examType.length !== 0 && isGrade === false && (
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="examType">
                          <span
                            style={{ fontWeight: "bold", fontSize: "0.85rem" }}
                          >
                            Exam Type*
                          </span>
                        </Label>
                        <Select
                          className="custom-select-input"
                          name="examTypeName"
                          options={examType} // Use the fetched exam types
                          value={
                            isEditing
                              ? examType.find(
                                (exam) => exam.label === formData.examTypeName
                              ) || {
                                label: formData.examTypeName,
                                value: formData.examTypeName,
                              }
                              : examType.find(
                                (exam) => exam.value === formData.examTypeName
                              ) || null
                          }
                          onChange={handleSelectChange}
                          placeholder="Select Exam Type"
                          styles={customStyles}
                          isDisabled={isEditing}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>Total Marks</Label>
                      {isGrade && (
                       <span
                       data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       title="Enter the total marks if you wish to record obtained marks for this graded subject. Otherwise, leave it as zero."
                       className="ms-1"
                       style={{
                         cursor: "pointer",
                         fontSize: "1rem",
                         color: "#fb0f01",
                       }}
                     >
                       <i className="bi bi-info-circle"></i>
                     </span>
                  )}
                      <Input
                        type="text"
                        name="totalMarks"
                        value={mark.totalMarks ?? ""} // Default to empty if not available
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Allow only numbers (empty string or digits)
                            const updatedMarks = [...formData.marks];
                            // Ensure the updatedMarks array is initialized correctly
                            if (!updatedMarks[index]) {
                              updatedMarks[index] = {}; // Initialize empty object if undefined
                            }
                            updatedMarks[index].totalMarks = value;
                            setFormData({
                              ...formData,
                              marks: updatedMarks,
                              totalMarks: value,
                            });
                          }
                        }}
                      />
                       
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>Passing Marks</Label>
                      <Input
                        type="text" // Use type="text" for stricter control, or "number" for browser enforcement
                        name="passingMarks"
                        value={mark.passingMarks ?? ""} // Default to empty if not available
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) { // Allow only numbers
                            const updatedMarks = [...formData.marks];
                            if (!updatedMarks[index]) {
                              updatedMarks[index] = {}; // Initialize the object if undefined
                            }
                            updatedMarks[index].passingMarks = value;

                            // Update both `marks` array and `passingMarks` in `formData`
                            setFormData({
                              ...formData,
                              marks: updatedMarks,
                              passingMarks: value, // Update formData.passingMarks
                            });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>Exam Date</Label>
                      <Input
                        type="date"
                        name="examDate"
                        value={mark.examDate || ""} // Default to empty if not available
                        onChange={(e) => {
                          const updatedMarks = [...formData.marks];
                          updatedMarks[index].examDate = e.target.value;
                          setFormData({
                            ...formData,
                            marks: updatedMarks,
                            examDate: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>Exam Start Time</Label>
                      <Input
                        type="time"
                        name="examTime"
                        value={mark.examTime || ""} // Default to empty if not available
                        onChange={(e) => {
                          const updatedMarks = [...formData.marks];
                          updatedMarks[index].examTime = e.target.value;
                          setFormData({
                            ...formData,
                            marks: updatedMarks,
                            examTime: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3}>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "0.95rem" }}>Exam End Time</Label>
                      <Input
                        type="time"
                        name="examEndTime"
                        value={mark.examEndTime || ""} // Default to empty if not available
                        onChange={(e) => {
                          const updatedMarks = [...formData.marks];
                          updatedMarks[index].examEndTime = e.target.value;
                          setFormData({
                            ...formData,
                            marks: updatedMarks,
                            examEndTime: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {examType.length !== 0 && isGrade === false && (
                    <Col
                      xs={12}
                      sm={6}
                      xl={3}
                      className="mt-sm-2  px-3"
                      style={{ padding: "2px" }}
                    >
                      <Button
                        className="mt-xl-4 btn-no-radius"
                        color="primary"
                        onClick={isEditing ? updateSubject : addSubject}
                      >
                        {isEditing ? "Update" : "Add"} Subject
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}

              {examType.length !== 0 && isGrade === false && (
                <Row className="m-0 p-0">
                  <Col md={12} className="mt-3">
                    {formData.subjects.length > 0 && (
                      <Table bordered hover responsive>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Exam Type</th>
                            <th>Exam Date</th>
                            <th>Exam Start Time</th>
                            <th>Exam End Time</th>
                            <th>Total Marks</th>
                            <th>Passing Marks</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.subjects.map((type, index) => (
                            <tr key={type._id || index}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {type.examTypeName ||
                                  type?.examtype_id?.examType}
                              </td>
                              <td>
                                {type?.examDate
                                  ? formatDate(type.examDate)
                                  : ""}
                              </td>
                              <td>
                                {type?.examTime
                                  ? new Date(`1970-01-01T${type.examTime}:00`).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
                                  : '-'}
                              </td>
                              <td>
                                {type?.examEndTime
                                  ? new Date(`1970-01-01T${type.examEndTime}:00`).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
                                  : '-'}
                              </td>
                              <td>{type?.totalMarks}</td>
                              <td>{type?.passingMarks}</td>
                              <td>
                                <FaRegEdit
                                  className="mx-2"
                                  style={{ color: "black", cursor: "pointer" }}
                                  onClick={() => editType(index)}
                                />
                                <AiOutlineDelete
                                  className="mx-2"
                                  style={{ color: "black", cursor: "pointer" }}
                                  onClick={() => deleteType(index)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <style jsx="true">{`
                          table td {
                            white-space: nowrap;
                          }
                          table th {
                            white-space: nowrap;
                          }
                          .responsive-button {
                            padding: 8px 16px;
                            font-size: 16px;
                          }

                          @media (max-width: 768px) {
                            table td,
                            table th {
                              padding: 5px;
                              white-space: nowrap;
                            }
                            .responsive-button {
                              padding: 5px 11px;
                              font-size: 13px;
                            }
                          }

                          @media (max-width: 576px) {
                            table td,
                            table th {
                              white-space: nowrap;
                            }
                            .responsive-button {
                              padding: 5px 11px;
                              font-size: 10px;
                            }
                          }
                        `}</style>
                      </Table>
                    )}
                  </Col>
                </Row>
              )}

              {/* Button to add or update subjects */}



            </Row>
            {!isGrade && ( 
            <div className=" mt-4 ">
              <Card
                className=" shadow-none  rounded-0  "
                style={{
                  border: "1px solid #ccc",
                }}
              >
                <CardBody className="p-0 pb-2">
                  <div className="d-flex justify-content-between align-items-center px-3 pt-1">
                    <CardTitle className="mt-2" tag="h4">
                      Add Grade
                    </CardTitle>
                  </div>
                  <hr className="p-0 m-0"></hr>
                  <Form className="mt-3 px-3">
                    <Row className="mt-0 pt-2">
                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="min_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Minimum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="min_mark"
                            id="min_mark"
                            placeholder="Min Percentage"
                            value={formData.min_mark}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="max_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Maximum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="max_mark"
                            id="max_mark"
                            placeholder="Max Percentage"
                            value={formData.max_mark}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="grade">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Grade
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="grade"
                            id="grade"
                            placeholder="Grade"
                            value={formData.grade}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={3} xl={2} className="mt-2">
                        {!isEditing && (
                          <Button
                            color="primary"
                            className="mt-xl-4 mt-sm-4 mt-md-0 btn-no-radius mb-2"
                            onClick={addGrade} // Assuming you have this handler
                          >
                            Add Grade
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            color="primary"
                            className="mt-xl-4 btn-no-radius mb-2"
                            onClick={submitEditedGrade} // Assuming you have this handler
                          >
                            Edit Grade
                          </Button>
                        )}
                      </Col>

                      {renderTable()}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
            )}
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditExamSubject;
